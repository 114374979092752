import { createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'teams',
  initialState: {
    teamsList: [],
    isLoadingTeams: false,
    isSuccessTeams: false,
    isFailTeams: false,
    isLoadingNewTeam: false,
    isSuccessNewTeam: false,
    isFailLNewTeam: false,
    isLoadingUpdateTeam: false,
    isSuccessUpdateTeam: false,
    isFailLUpdateTeam: false,
    teamsTags: [],
    isLoadingTeamsTags: false,
    isSuccessTeamsTags: false,
    isFailTeamsTags: false,
    isLoadingSearch: false,
    isSuccessSearch: false,
    isFailSearch: false
  },
  reducers: {
    teamsRequest: (state) => {
      return {
        ...state,
        teamsList: [],
        isLoadingTeams: true,
        isSuccessTeams: false,
        isFailTeams: false,
      }
    },
    teamsSuccess: (state, action) => {
      return {
        ...state,
        teamsList: action.payload,
        isLoadingTeams: false,
        isSuccessTeams: true,
        isFailTeams: false,
      }
    },
    teamsFail: (state) => {
      return {
        ...state,
        teamsList: [],
        isLoadingTeams: false,
        isSuccessTeams: false,
        isFailTeams: true,
      }
    },
    newTeamRequest: (state) => {
      return {
        ...state,
        isLoadingNewTeam: true,
        isSuccessNewTeam: false,
        isFailNewTeam: false,
      }
    },
    newTeamSuccess: (state) => {
      return {
        ...state,
        isLoadingNewTeam: false,
        isSuccessNewTeam: true,
        isFailNewTeam: false,
      }
    },
    newTeamFail: (state) => {
      return {
        ...state,
        isLoadingNewTeam: false,
        isSuccessNewTeam: false,
        isFailNewTeam: true,
      }
    },
    updateTeamRequest: (state) => {
      return {
        ...state,
        isLoadingUpdateTeam: true,
        isSuccessUpdateTeam: false,
        isFailUpdateTeam: false,
      }
    },
    updateTeamSuccess: (state) => {
      return {
        ...state,
        isLoadingUpdateTeam: false,
        isSuccessUpdateTeam: true,
        isFailUpdateTeam: false,
      }
    },
    updateTeamFail: (state) => {
      return {
        ...state,
        isLoadingUpdateTeam: false,
        isSuccessUpdateTeam: false,
        isFailUpdateTeam: true,
      }
    },
    teamsTagsRequest: (state) => {
      return {
        ...state,
        teamsTags: [],
        isLoadingTeamsTags: true,
        isSuccessTeamsTags: false,
        isFailTeamsTags: false,
      }
    },
    teamsTagsSuccess: (state, action) => {
      return {
        ...state,
        teamsTags: action.payload,
        isLoadingTeamsTags: false,
        isSuccessTeamsTags: true,
        isFailTeamsTags: false,
      }
    },
    teamsTagsFail: (state) => {
      return {
        ...state,
        teamsTags: [],
        isLoadingTeamsTags: false,
        isSuccessTeamsTags: false,
        isFailTeamsTags: true,
      }
    },
    resetTeams: (state) => {
      return {
        ...state,
        isSuccessTeams: false,
        isFailTeams: false,
        teamsList: [],
      }
    },
    resetTeamsTags: (state) => {
      return {
        ...state,
        isSuccessTeamsTags: false,
        isFailTeamsTags: false,
        teamsTags: [],
      }
    },
    searchTagRequest: (state) => {
      return {
        ...state,
        teamsTags: [],
        isLoadingSearch: true,
        isSuccessSearch: false,
        isFailSearch: false,
      }
    },
    searchTagSuccess: (state, action) => {
      return {
        ...state,
        teamsTags: action.payload,
        isLoadingSearch: false,
        isSuccessSearch: true,
        isFailSearch: false,
      }
    },
    searchTagFail: (state) => {
      return {
        ...state,
        teamsTags: [],
        isLoadingSearch: false,
        isSuccessSearch: false,
        isFailSearch: true,
      }
    }
  },
})

export default slice.reducer

export const {
  teamsRequest,
  teamsSuccess,
  teamsFail,
  newTeamRequest,
  newTeamSuccess,
  newTeamFail,
  updateTeamRequest,
  updateTeamSuccess,
  updateTeamFail,
  teamsTagsRequest,
  teamsTagsSuccess,
  teamsTagsFail,
  resetTeams,
  resetTeamsTags,
  searchTagRequest,
  searchTagSuccess,
  searchTagFail
} = slice.actions

export const createTeam = (companyId, teamName, isPrivateTeam) => async (dispatch) => {
  const toastLoading = toast.loading('Loading..')
  dispatch(newTeamRequest())
  try {
    await axiosClient.post(`/Team/create/${companyId}`, { Name: teamName, IsPrivateTeam: isPrivateTeam })
    dispatch(newTeamSuccess())
    toast.dismiss(toastLoading)
    toast.success('Team created successfully')
  } catch {
    dispatch(newTeamFail())
    toast.dismiss(toastLoading)
    toast.error('Team creation failed')
  }
}

export const updateTeam = (companyId, teamInfo) => async (dispatch) => {
  const toastLoading = toast.loading('Loading..')
  dispatch(updateTeamRequest())
  try {
    await axiosClient.post(`/Team/Update?companyId=${companyId}`, {
      ...teamInfo,
    })
    dispatch(updateTeamSuccess())
    toast.dismiss(toastLoading)
    toast.success('Team updated successfully')
  } catch {
    dispatch(updateTeamFail())
    toast.dismiss(toastLoading)
    toast.error('Team update failed')
  }
}

export const getTeams = (companyId) => async (dispatch) => {
  dispatch(teamsRequest())
  try {
    const res = await axiosClient.get(`/Team/GetTeamsCards/${companyId}`)
    dispatch(teamsSuccess(res.data))
  } catch {
    dispatch(teamsFail())
  }
}

export const getTeamsTags = (companyId) => async (dispatch) => {
  dispatch(teamsTagsRequest())
  try {
    const res = await axiosClient.get(`/Team/GetTeamsTags/${companyId}`)
    dispatch(teamsTagsSuccess(res.data))
  } catch {
    dispatch(teamsTagsFail())
  }
}

export const searchTag = (companyId, filter) => async (dispatch) => {
  dispatch(searchTagRequest())
  try {
    const res = await axiosClient.get(`/Tag/searchTags/${companyId}?filter=${filter}`)
    dispatch(searchTagSuccess(res.data))
  } catch {
    dispatch(searchTagFail())
  }
}