import { createSlice } from '@reduxjs/toolkit'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'password',
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
    error: {},
  },
  reducers: {
    setPasswordRequest: (state) => {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isFail: false,
      }
    },
    setPasswordSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isFail: false,
      }
    },
    setPasswordFail: (state, action) => {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
        isFail: true,
      }
    },
    setForgotPasswordRequest: (state) => {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isFail: false,
      }
    },
    setForgotPasswordSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isFail: false,
      }
    },
    setForgotPasswordFail: (state, action) => {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
        isFail: true,
      }
    },
    reset: (state) => {
      return {
        ...state,
        password: null,
        isLoading: false,
        isSuccess: false,
        isFail: false,
        error: {},
      }
    },
  },
})

export default slice.reducer

export const {
  setPasswordRequest,
  setPasswordSuccess,
  setPasswordFail,
  setForgotPasswordRequest,
  setForgotPasswordSuccess,
  setForgotPasswordFail,
  reset,
} = slice.actions

export const setPassword = (passwordInfo) => async (dispatch) => {
  const { Password, hash } = passwordInfo
  dispatch(setPasswordRequest())
  try {
    await axiosClient.put(`/User/setPassword/${hash}`, { Password })
    dispatch(setPasswordSuccess())
  } catch (error) {
    dispatch(setPasswordFail(error.data))
  }
}

export const forgotPassword = (email) => async (dispatch) => {
  dispatch(setForgotPasswordRequest())
  try {
    await axiosClient.post(`/User/forgotPassword`, { userEmail: email })
    dispatch(setForgotPasswordSuccess())
  } catch (error) {
    dispatch(setForgotPasswordFail(error.data))
  }
}

export const resetPassword = (passwordInfo) => async (dispatch) => {
  const { Password, hash } = passwordInfo
  dispatch(setPasswordRequest())
  try {
    await axiosClient.put(`/User/resetPassword/${hash}`, { Password })
    dispatch(setPasswordSuccess())
  } catch (error) {
    dispatch(setPasswordFail(error.data))
  }
}
