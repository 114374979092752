import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import auth from './login/auth.js'
import companies from './companies/companies.js'
import password from './password/password.js'
import users from './users/users.js'
import common from './common/common.js'
import teams from './teams/teams.js'
import role from './role/role.js'
import userTeam from './userTeam/userTeam.js'
import tags from './tags/tags.js'
import activities from './activities/activities.js'
import personalAnalytics from './personalAnalytics/personalAnalytics'
import teamAnalytics from './teamAnalytics/teamAnalytics'

const reducer = combineReducers({
  auth,
  companies,
  password,
  users,
  common,
  teams,
  role,
  userTeam,
  tags,
  activities,
  personalAnalytics,
  teamAnalytics,
})

export const store = configureStore({
  reducer,
})
