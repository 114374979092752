import { createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'users',
  initialState: {
    usersList: {},
    isLoadingUsers: false,
    isSuccessUsers: false,
    isFailUsers: false,
    isLoadingNewUser: false,
    isSuccessNewUser: false,
    isFailLNewUser: false,
    isLoadingUpdateUser: false,
    isSuccessUpdateUser: false,
    isFailLUpdateUser: false,
    isLoadingUsersInBulk: false,
    isSuccessUsersInBulk: false,
    isFailLUsersInBulk: false,
    isLoadingUser: false,
    isSuccessUser: false,
    isFailUser: false,
    usersResult: [],
    usersByTeamList: {},
    isLoadingUsersByTeam: false,
    isSuccessUsersByTeam: false,
    isFailUsersByTeam: false,
    isLoadingSendEmail: false,
    isSuccessSendEmail: false,
    isFailSendEmail: false,
    error: null,
  },
  reducers: {
    usersRequest: (state) => {
      return {
        ...state,
        usersList: {},
        isLoadingUsers: true,
        isSuccessUsers: false,
        isFailUsers: false,
      }
    },
    usersSuccess: (state, action) => {
      return {
        ...state,
        usersList: action.payload,
        isLoadingUsers: false,
        isSuccessUsers: true,
        isFailUsers: false,
      }
    },
    usersFail: (state) => {
      return {
        ...state,
        usersList: {},
        isLoadingUsers: false,
        isSuccessUsers: false,
        isFailUsers: true,
      }
    },
    newUserRequest: (state) => {
      return {
        ...state,
        isLoadingNewUser: true,
        isSuccessNewUser: false,
        isFailNewUser: false,
      }
    },
    newUserSuccess: (state) => {
      return {
        ...state,
        isLoadingNewUser: false,
        isSuccessNewUser: true,
        isFailNewUser: false,
      }
    },
    newUserFail: (state) => {
      return {
        ...state,
        isLoadingNewUser: false,
        isSuccessNewUser: false,
        isFailNewUser: true,
      }
    },
    updateUserRequest: (state) => {
      return {
        ...state,
        isLoadingUpdateUser: true,
        isSuccessUpdateUser: false,
        isFailUpdateUser: false,
      }
    },
    updateUserSuccess: (state) => {
      return {
        ...state,
        isLoadingUpdateUser: false,
        isSuccessUpdateUser: true,
        isFailUpdateUser: false,
      }
    },
    updateUserFail: (state) => {
      return {
        ...state,
        isLoadingUpdateUser: false,
        isSuccessUpdateUser: false,
        isFailUpdateUser: true,
      }
    },
    createUsersInBulkRequest: (state) => {
      return {
        ...state,
        isLoadingUsersInBulk: true,
        isSuccessUsersInBulk: false,
        isFailUsersInBulk: false,
      }
    },
    createUsersInBulkSuccess: (state) => {
      return {
        ...state,
        isLoadingUsersInBulk: false,
        isSuccessUsersInBulk: true,
        isFailUsersInBulk: false,
      }
    },
    createUsersInBulkFail: (state, action) => {
      return {
        ...state,
        isLoadingUsersInBulk: false,
        isSuccessUsersInBulk: false,
        isFailUsersInBulk: true,
        error: action.payload,
      }
    },
    resetUsers: (state) => {
      return {
        ...state,
        isSuccessUsers: false,
        isFailUsers: false,
        usersList: {},
        isSuccessUsersByTeam: false,
        isFailUsersByTeam: false,
        usersByTeamList: {},
      }
    },
    reset: (state) => {
      return {
        ...state,
        isFailUsersInBulk: false,
        error: null,
      }
    },
    userRequest: (state) => {
      return {
        ...state,
        usersResult: [],
        isLoadingUser: true,
        isSuccessUser: false,
        isFailUser: false,
      }
    },
    userSuccess: (state, action) => {
      return {
        ...state,
        usersResult: action.payload,
        isLoadingUser: false,
        isSuccessUser: true,
        isFailUser: false,
      }
    },
    userFail: (state) => {
      return {
        ...state,
        usersResult: [],
        isLoadingUser: false,
        isSuccessUser: false,
        isFailUser: true,
      }
    },
    usersByTeamRequest: (state) => {
      return {
        ...state,
        usersByTeamList: {},
        isLoadingUsersByTeam: true,
        isSuccessUsersByTeam: false,
        isFailUsersByTeam: false,
      }
    },
    usersByTeamSuccess: (state, action) => {
      return {
        ...state,
        usersByTeamList: action.payload,
        isLoadingUsersByTeam: false,
        isSuccessUsersByTeam: true,
        isFailUsersByTeam: false,
      }
    },
    usersByTeamFail: (state) => {
      return {
        ...state,
        usersByTeamList: {},
        isLoadingUsersByTeam: false,
        isSuccessUsersByTeam: false,
        isFailUsersByTeam: true,
      }
    },
    sendEmailRequest: (state) => {
      return {
        ...state,
        isLoadingSendEmail: true,
        isSuccessSendEmail: false,
        isFailSendEmail: false,
      }
    },
    sendEmailSuccess: (state, action) => {
      return {
        ...state,
        isLoadingSendEmail: false,
        isSuccessSendEmail: true,
        isFailSendEmail: false,
      }
    },
    sendEmailFail: (state) => {
      return {
        ...state,
        isLoadingSendEmail: false,
        isSuccessSendEmail: false,
        isFailSendEmail: true,
      }
    },
  },
})

export default slice.reducer

export const {
  usersRequest,
  usersSuccess,
  usersFail,
  newUserRequest,
  newUserSuccess,
  newUserFail,
  updateUserRequest,
  updateUserSuccess,
  updateUserFail,
  createUsersInBulkRequest,
  createUsersInBulkSuccess,
  createUsersInBulkFail,
  reset,
  resetUsers,
  userRequest,
  userSuccess,
  userFail,
  usersByTeamRequest,
  usersByTeamSuccess,
  usersByTeamFail,
  sendEmailRequest,
  sendEmailSuccess,
  sendEmailFail,
} = slice.actions

export const createUsersInBulk = (companyId, File) => async (dispatch) => {
  dispatch(createUsersInBulkRequest())
  const toastLoading = toast.loading('Loading file')

  try {
    await axiosClient.post(`/User/UploadMassiveUsers/${companyId}`, File)
    dispatch(createUsersInBulkSuccess())
    toast.success('File uploaded successfully')
    toast.dismiss(toastLoading)
  } catch (error) {
    dispatch(createUsersInBulkFail(error.response.data))
    toast.error(
      error?.response?.data[0]?.Message
        ? error?.response?.data[0]?.Message
        : 'Failed user creation'
    )
    toast.dismiss(toastLoading)
  }
}

export const createUser = (companyId, userInfo) => async (dispatch) => {
  const { FirstName, LastName, Email } = userInfo
  dispatch(newUserRequest())
  try {
    await axiosClient.post(`/User/create/${companyId}`, {
      FirstName,
      LastName,
      Email,
    })
    dispatch(newUserSuccess())
    toast.success('Successful user creation')
  } catch (e) {
    dispatch(newUserFail())
    toast.error(
      e?.response?.data[0]?.Message
        ? e?.response?.data[0]?.Message
        : 'Failed user creation'
    )
  }
}

export const updateUser = (userInfo) => async (dispatch) => {
  const { Id, FirstName, LastName, IsActive, RoleId } = userInfo
  dispatch(updateUserRequest())
  try {
    await axiosClient.put(`/User/update/${Id}`, {
      FirstName,
      LastName,
      IsActive,
      RoleId,
    })
    dispatch(updateUserSuccess())
    toast.success('Successful user update')
  } catch {
    dispatch(updateUserFail())
    toast.error('Failed user update')
  }
}

export const getUsers = (data) => async (dispatch) => {
  const { companyId, text, sortedField, sortDirection, page, pageSize } = data
  dispatch(usersRequest())
  try {
    const res = await axiosClient.get(
      `/User/usersbycompany/${companyId}?text=${text}&sortedField=${sortedField}&sortDirection=${sortDirection}&page=${page}&pageSize=${pageSize}`
    )
    dispatch(usersSuccess(res.data))
  } catch {
    dispatch(usersFail())
  }
}

export const getUser = (data) => async (dispatch) => {
  const { company, filter } = data
  dispatch(userRequest())
  try {
    const res = await axiosClient.get(
      `/User/userautocomplete/${company}?filter=${filter}`
    )
    dispatch(userSuccess(res.data))
  } catch {
    dispatch(userFail())
  }
}

export const getUsersByTeam = (data) => async (dispatch) => {
  const { teamId, text, sortedField, sortDirection, page, pageSize } = data
  dispatch(usersByTeamRequest())
  try {
    const res = await axiosClient.get(
      `/User/usersbyteam/${teamId}?text=${text}&sortedField=${sortedField}&sortDirection=${sortDirection}&page=${page}&pageSize=${pageSize}`
    )
    dispatch(usersByTeamSuccess(res.data))
  } catch {
    dispatch(usersByTeamFail())
  }
}

export const sendEmail = (companyId, emailsList) => async (dispatch) => {
  dispatch(sendEmailRequest());
  try {
    await axiosClient.post(`/User/SendEmailUsers/${companyId}`, { 'UserEmails': emailsList });
    dispatch(sendEmailSuccess());
    toast.success('Email sent successfully');
  } catch (e) {
    dispatch(sendEmailFail());
    toast.error(
      e?.response?.data[0]?.Message
        ? e?.response?.data[0]?.Message
        : 'Email sent failed'
    );
  }
};