import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { IconButton, Switch } from '@mui/material';
import globals from '../../constants/global.constant';
import styles from './Table.module.scss';

export const TableApp = ({
  dataTable,
  handleUpdate,
  handleDelete,
  handleView,
  handleActivate,
  columns,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  page,
  total,
  checkbox,
  selectedUsers,
  handleSelectAllClick,
  handleClick,
  isSelected,
  handleSend
}) => {
  return (
    <>
      <TableContainer component={Paper} className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              {checkbox && (
                <TableCell padding='checkbox'>
                  <Checkbox
                    indeterminate={selectedUsers.length > 0 && selectedUsers.length < dataTable.length}
                    checked={dataTable.length > 0 && selectedUsers.length === dataTable.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all users",
                    }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#1A3127",
                      },
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTable.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow padding="checkbox">
                  {checkbox && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row.email)}
                        role="checkbox"
                        aria-checked={isSelected(row.email)}
                        checked={isSelected(row.email)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "#1A3127",
                          },
                        }}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ height: '44px', paddingY: '5px' }}
                      >
                        {column.id === 'name' ? (
                          <div className={styles.name}>
                            {`${row.name} ${row.lastName ? row.lastName : ''}`}
                            <div className={styles.email}>{row.email}</div>
                          </div>
                        ) : column.id === 'status' ? (
                          <div>
                            <Switch
                              id="status"
                              checked={row.status}
                              onChange={(event) => handleActivate(event, row.id)}
                              color="primary"
                            />
                          </div>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    align={'center'}
                    sx={{
                      height: '44px',
                      borderBottom: 'none',
                      paddingY: '5px',
                    }}
                  >
                    <IconButton onClick={() => handleUpdate(row.id)}>
                      <img
                        src={`${globals.logosBasePath}edit.svg`}
                        alt="edit"
                      />
                    </IconButton>
                    {handleDelete && (
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <img
                          src={`${globals.logosBasePath}delete.svg`}
                          alt="delete"
                        />
                      </IconButton>
                    )}
                    {handleView && (
                      <IconButton
                        onClick={() => handleView(row.id)}
                        disabled={row.teamsRole.length === 0}
                        style={{ opacity: row.teamsRole.length === 0 ? '0.5' : '1' }}
                      >
                        <img
                          src={`${globals.logosBasePath}eye.svg`}
                          alt="view"
                        />
                      </IconButton>
                    )}
                    {handleSend && (
                      <IconButton
                        onClick={() => handleSend(row.email)}
                      >
                        <img
                          src={`${globals.logosBasePath}email_send.svg`}
                          alt="email"
                        />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ color: 'primary' }}
        backIconButtonProps={{ color: 'primary' }}
      />
    </>
  );
};

TableApp.propTypes = {
  dataTable: PropTypes.array,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
  handleView: PropTypes.func,
  handleActivate: PropTypes.func,
  columns: PropTypes.array,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  total: PropTypes.number,
  checkbox: PropTypes.bool,
  selectedUsers: PropTypes.array,
  handleSelectAllClick: PropTypes.func,
  handleClick: PropTypes.func,
  isSelected: PropTypes.func,
};
