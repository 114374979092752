import Joi from 'joi'
import tlds from '../../../../node_modules/@sideway/address/lib/tlds'

const validatePassword = {
  Password: Joi.string()
    .required()
    .pattern(
      new RegExp(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      )
    ),
}

const validateEmailRequired = {
  Email: Joi.string()
    .email({ tlds: { allow: tlds } })
    .required(),
}

export const validateName = {
  Name: Joi.string().min(1).max(100).required(),
}

export const validateLoginForm = Joi.object({
  UserName: validateEmailRequired.Email,
  ...validatePassword,
})

export const validateSetPassword = Joi.object({
  ...validatePassword,
  ConfirmPassword: validatePassword.Password,
})

export const validationUserForm = Joi.object({
  FirstName: validateName.Name,
  LastName: validateName.Name,
  ...validateEmailRequired,
  RoleId: Joi.string().allow(null, ''),
})

export const validateForgotPassword = Joi.object({
  ...validateEmailRequired,
})

export const validateAddMember = Joi.object({
  ...validateEmailRequired,
  RoleId: Joi.string().required(),
})

export const validateUpdateProfile = Joi.object({
  FirstName: validateName.Name,
  LastName: validateName.Name,
  Id: Joi.string(),
  Email: Joi.string().email({ tlds: { allow: tlds } }),
  Calendar: Joi.string().allow(null, ''),
  TimeZone: Joi.object().allow(null),
  IsActiveNotification: Joi.boolean(),
})

export const validateAddActivity = Joi.object({
  Name: Joi.string().required(),
  Hour: Joi.string().required(),
  TagId: Joi.string().allow(null, ''),
  Feeling: Joi.string().allow(null, ''),
  FeelingType: Joi.string().allow(null, ''),
})
