import React from 'react'

import styles from './FeelingsPerDay.module.scss'
import moment from 'moment/moment'
import globals from '../../constants/global.constant'
import Calendar from '../Calendar'
import { MenuItem, Select } from '@mui/material'
import { VictoryChart, VictoryAxis, VictoryContainer } from 'victory'
import { VictoryBar } from 'victory-bar'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const FeelingsPerDay = ({
  selectedDate,
  handleSelectDate,
  handleMove,
  recurringFeelingsData,
  teamsInfo,
  handleSelectTeam,
  teamSelected,
  module,
  sampleData,
  muaMobile,
}) => {
  const feelings = [{ name: 'love' }, { name: 'burn' }, { name: 'neutral' }]
  const notFeelings = !sampleData.map((ele) => ele.y === 0).includes(false)

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrapperTop}>
          <div className={styles.wrapperTitle}>
            <div className={styles.title}>
              {module === 'team' ? 'Team Live Insights' : 'My Live Insights'}
            </div>
            <img
              src={`${globals.logosBasePath}live.svg`}
              alt="live"
              width={'20px'}
              height={'20px'}
              className={styles.iconLive}
            />
          </div>
          <Calendar
            handleMove={handleMove}
            recurringFeelingsData={recurringFeelingsData}
            selectedDate={selectedDate}
            handleSelectDate={handleSelectDate}
          />
        </div>
        <div className={styles.wrapperDate}>
          <div className={styles.date}>
            {moment(selectedDate).format('dddd, MMM D')}
          </div>
          {module === 'team' && (
            <div>
              <Select
                className={styles.select}
                displayEmpty
                value={teamSelected}
                onChange={handleSelectTeam}
                renderValue={(selected) => {
                  if (selected === '') {
                    return <p style={{ color: '#F7F7F7' }}>Select team</p>
                  } else {
                    return (
                      <p style={{ color: '#F7F7F7' }}>
                        {teamsInfo.find((ele) => ele.id === selected)
                          ? teamsInfo.find((ele) => ele.id === selected).name
                          : null}
                      </p>
                    )
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: '#1A3127',
                      borderRadius: '12px',
                    },
                  },
                }}
                IconComponent={(props) => (
                  <ArrowDropDownIcon {...props} style={{ color: '#F7F7F7' }} />
                )}
              >
                <MenuItem disabled value="" style={{ color: '#F7F7F7' }}>
                  <p style={{ color: '#F7F7F7' }}>Select team</p>
                </MenuItem>
                {teamsInfo.map((ele, index) => (
                  <MenuItem
                    key={index}
                    value={ele.id}
                    style={{ color: '#F7F7F7' }}
                  >
                    <div
                      style={{
                        border: '1px solid #F7F7F7',
                        padding: '0px 6px',
                      }}
                    >
                      {ele.name}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
        <div
          className={styles.wrapperBottom}
          style={{ height: module === 'team' && '42%' }}
        >
          <VictoryChart
            domainPadding={2}
            padding={{
              bottom: module === 'team' ? 20 : 0,
              top: 30,
              right: 40,
              left: 40,
            }}
            containerComponent={
              <VictoryContainer
                style={{
                  pointerEvents: 'auto',
                  userSelect: 'auto',
                  touchAction: 'auto',
                }}
              />
            }
          >
            <VictoryAxis
              dependentAxis
              style={{
                axis: { display: 'none' },
                grid: { stroke: '#1A3127', strokeWidth: 0.2 },
                tickLabels: {
                  display: notFeelings && 'none',
                  fontSize: 18,
                  fill: '#1A3127',
                  fontFamily: 'inherit',
                  fontWeight: 'lighter',
                },
              }}
            />
            <VictoryAxis
              style={{
                axis: { stroke: '#1A3127', strokeWidth: 0.2 },
                grid: { stroke: 'none' },
                tickLabels: { display: 'none' },
              }}
            />
            <VictoryBar
              cornerRadius={15}
              domain={{ x: [0, 6], y: notFeelings && [0, 4] }}
              barWidth={30}
              style={{
                data: {
                  fill: ({ datum }) => datum.fill,
                  opacity: ({ datum }) => datum.opacity,
                },
              }}
              data={sampleData}
            />
          </VictoryChart>
          <div className={styles.wrapperIcons}>
            {feelings.map((elem, index) => (
              <img
                key={index}
                src={`${globals.logosBasePath}${elem.name}.svg`}
                alt={`${elem.name}`}
                height={muaMobile ? '25px' : '19px'}
                width={muaMobile ? '25px' : '19px'}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default FeelingsPerDay
