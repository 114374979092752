import { createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'companies',
  initialState: {
    companiesList: {},
    companiesSelect: [],
    isLoading: false,
    isSuccess: false,
    isFail: false,
    isLoadingCompanies: false,
    isSuccessCompanies: false,
    isFailCompanies: false,
    isLoadingNewCompany: false,
    isSuccessNewCompany: false,
    isFailLNewCompany: false,
    isLoadingUpdateCompany: false,
    isSuccessUpdateCompany: false,
    isFailLUpdateCompany: false,
  },
  reducers: {
    getCompaniesRequest: (state) => {
      return {
        ...state,
        companiesSelect: [],
        isLoading: true,
        isSuccess: false,
        isFail: false,
      }
    },
    getCompaniesSuccess: (state, action) => {
      return {
        ...state,
        companiesSelect: action.payload,
        isLoading: false,
        isSuccess: true,
        isFail: false,
      }
    },
    getCompaniesFail: (state) => {
      return {
        ...state,
        companiesSelect: [],
        isLoading: false,
        isSuccess: false,
        isFail: true,
      }
    },
    companiesRequest: (state) => {
      return {
        ...state,
        companiesList: {},
        isLoadingCompanies: true,
        isSuccessCompanies: false,
        isFailCompanies: false,
      }
    },
    companiesSuccess: (state, action) => {
      return {
        ...state,
        companiesList: action.payload,
        isLoadingCompanies: false,
        isSuccessCompanies: true,
        isFailCompanies: false,
      }
    },
    companiesFail: (state) => {
      return {
        ...state,
        companiesList: {},
        isLoadingCompanies: false,
        isSuccessCompanies: false,
        isFailCompanies: true,
      }
    },
    newCompanyRequest: (state) => {
      return {
        ...state,
        isLoadingNewCompany: true,
        isSuccessNewCompany: false,
        isFailNewCompany: false,
      }
    },
    newCompanySuccess: (state) => {
      return {
        ...state,
        isLoadingNewCompany: false,
        isSuccessNewCompany: true,
        isFailNewCompany: false,
        userData: null,
      }
    },
    newCompanyFail: (state) => {
      return {
        ...state,
        isLoadingNewCompany: false,
        isSuccessNewCompany: false,
        isFailNewCompany: true,
      }
    },
    updateCompanyRequest: (state) => {
      return {
        ...state,
        isLoadingUpdateCompany: true,
        isSuccessUpdateCompany: false,
        isFailUpdateCompany: false,
      }
    },
    updateCompanySuccess: (state) => {
      return {
        ...state,
        isLoadingUpdateCompany: false,
        isSuccessUpdateCompany: true,
        isFailUpdateCompany: false,
        userData: null,
      }
    },
    updateCompanyFail: (state) => {
      return {
        ...state,
        isLoadingUpdateCompany: false,
        isSuccessUpdateCompany: false,
        isFailUpdateCompany: true,
      }
    },
  },
})

export default slice.reducer

export const {
  getCompaniesRequest,
  getCompaniesSuccess,
  getCompaniesFail,
  companiesRequest,
  companiesSuccess,
  companiesFail,
  newCompanyRequest,
  newCompanySuccess,
  newCompanyFail,
  updateCompanyRequest,
  updateCompanySuccess,
  updateCompanyFail,
} = slice.actions

export const createCompany = (companyInfo) => async (dispatch) => {
  dispatch(newCompanyRequest())
  const toastLoading = toast.loading('Loading..')
  try {
    await axiosClient.post('/Company/create', { ...companyInfo })
    dispatch(newCompanySuccess())
    toast.dismiss(toastLoading)
    toast.success('Successful company creation')
  } catch {
    dispatch(newCompanyFail())
    toast.dismiss(toastLoading)
    toast.error('Failed company creation')
  }
}

export const updateCompany = (companyInfo) => async (dispatch) => {
  dispatch(updateCompanyRequest())
  const toastLoading = toast.loading('Loading..')
  try {
    await axiosClient.post('/Company/Update', { ...companyInfo })
    dispatch(updateCompanySuccess())
    toast.dismiss(toastLoading)
    toast.success('Successful company update')
  } catch {
    dispatch(updateCompanyFail())
    toast.dismiss(toastLoading)
    toast.error('Failed company update')
  }
}

export const getCompaniesTable = (data) => async (dispatch) => {
  const { text, sortedField, sortDirection, page, pageSize } = data
  dispatch(companiesRequest())
  try {
    const res = await axiosClient.get(
      `/Company/GetCompaniesPaginated?text=${text}&sortedField=${sortedField}&sortDirection=${sortDirection}&page=${page}&pageSize=${pageSize}`
    )
    dispatch(companiesSuccess(res.data))
  } catch {
    dispatch(companiesFail())
  }
}

export const getCompanies = () => async (dispatch) => {
  dispatch(getCompaniesRequest())
  try {
    const res = await axiosClient.get(`/Company/GetCompanies`)
    dispatch(getCompaniesSuccess(res.data))
  } catch {
    dispatch(getCompaniesFail())
  }
}
