/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { Divider, IconButton, Button } from '@mui/material'
import styles from './Calendar.module.scss'
import globals from '../../constants/global.constant'

import { IconApp } from '../IconApp/IconApp'
import { useMediaQuery } from 'react-responsive'

const Calendar = ({
  isSelectedByCalendar,
  selectedDate,
  handleMove,
  handleSelectDate,
  recurringFeelingsData,
  handleSelectFeeling,
  action,
  handleShowFeeling,
  showFeelings,
  show,
  setShowFeelings,
  setShow,
  DateEnd = new Date(),
}) => {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })
  const totalItems =
    recurringFeelingsData && recurringFeelingsData.length > 0
      ? recurringFeelingsData.map((feeling, index) => ({
          id: index + 1,
          date: new Date(moment(feeling.Date)),
          feeling: feeling.IdFeeling,
        }))
      : []

  const [items, setItems] = useState(totalItems)
  const [backStep, setBackStep] = useState(0)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [selectId, setSelectId] = useState(null)

  useEffect(() => {
    if (recurringFeelingsData && recurringFeelingsData.length > 0) {
      setItems(
        recurringFeelingsData.map((feeling, index) => ({
          id: index + 1,
          date: new Date(moment(feeling.Date)),
          feeling: feeling.IdFeeling,
          activityId: feeling.ActivityId,
        }))
      )
      setSelectId(
        totalItems &&
          totalItems.find(
            (ele) =>
              moment(selectedDate).format('YYYYMMDD') ===
              moment(ele.date).format('YYYYMMDD')
          )
          ? totalItems.find(
              (ele) =>
                moment(selectedDate).format('YYYYMMDD') ===
                moment(ele.date).format('YYYYMMDD')
            ).id
          : totalItems[totalItems.length - 1].id
      )
    }
  }, [recurringFeelingsData])

  useEffect(() => {
    if (isSelectedByCalendar) {
      const steps = moment(new Date()).diff(selectedDate, 'days') / 5
      setBackStep(Math.floor(steps))
    }
  }, [isSelectedByCalendar])

  const handleBack = () => {
    setBackStep(backStep + 1)

    const newItems = []
    items.forEach((ele, index) => {
      newItems.push({
        id: index + 1,
        date: new Date(moment(ele.date).subtract(5, 'days')),
        feeling: ele.feeling,
      })
    })

    setItems(newItems)
    setSelectId(newItems.find((ele, index) => index === 2).id)
    handleMove(newItems.find((ele, index) => index === 2).date)
    handleSelectDate(newItems.find((ele, index) => index === 2).date)
    setShowFeelings(null)
    setShow(false)
    setForceUpdate(!forceUpdate)
  }

  const handleNext = () => {
    setBackStep(backStep - 1)

    const newItems = []
    items.forEach((ele, index) => {
      newItems.push({
        id: index + 1,
        date: new Date(moment(ele.date).add(5, 'days')),
        feeling: ele.feeling,
      })
    })

    setItems(newItems)
    setSelectId(newItems.find((ele, index) => index === 2).id)
    handleSelectDate(newItems.find((ele, index) => index === 2).date)
    handleMove(newItems.find((ele, index) => index === 2).date)
    setShowFeelings(null)
    setShow(false)
    setForceUpdate(!forceUpdate)
  }

  const handleChangeSelect = (idSel) => {
    if (show) handleShowFeeling(idSel, false)
    setSelectId(idSel)
    handleSelectDate(totalItems.find((ele) => ele.id === idSel).date)
    setShowFeelings(idSel)
    setForceUpdate(!forceUpdate)
  }

  const ItemCalendar = () => {
    const selectFeeling = (feel) => {
      switch (feel) {
        case 1:
          return 'love'
        case 2:
          return 'neutral'
        case 3:
          return 'burn'
        default:
          return 'unfeeling'
      }
    }

    const feelings = [{ name: 'love' }, { name: 'burn' }, { name: 'neutral' }]

    return (
      <div className={styles.wrapperCarousel}>
        <IconButton className={styles.calendarIconButton} onClick={handleBack}>
          <IconApp iconName={'arrow-left'} alt="arrow-left" />
        </IconButton>
        {items.map((item, i) => (
          <>
            <div key={i} className={styles.wrapperItemCalendar}>
              <Button
                className={styles.buttonItemCalendar}
                style={{
                  backgroundColor: selectId !== item.id && 'transparent',
                }}
                onClick={() => handleChangeSelect(item.id)}
              >
                <div className={styles.buttonContainer}>
                  {action === 'Activities' ? (
                    <div className={styles.feelingWrapper}>
                      <div
                        className={styles.backgroundIcon}
                        style={{
                          borderWidth:
                            selectFeeling(item.feeling) === 'unfeeling' &&
                            '0px',
                        }}
                      >
                        {muaMobile ? (
                          <IconButton
                            sx={{ padding: '0px' }}
                            onClick={() => handleShowFeeling(item.id, !show)}
                          >
                            <IconApp
                              iconName={selectFeeling(item.feeling)}
                              alt={selectFeeling(item.feeling)}
                              height="23px"
                            />
                          </IconButton>
                        ) : (
                          <IconApp
                            iconName={selectFeeling(item.feeling)}
                            alt={selectFeeling(item.feeling)}
                            height="20px"
                          />
                        )}
                        <div
                          className={styles.feelingOptions}
                          style={{
                            display: show && showFeelings === item.id && 'flex',
                          }}
                        >
                          {feelings.map((elem, index) => (
                            <IconButton
                              key={index}
                              sx={{ padding: '0px' }}
                              onClick={() =>
                                handleSelectFeeling(
                                  elem.name,
                                  item.date,
                                  item.activityId
                                )
                              }
                            >
                              <img
                                src={`${globals.logosBasePath}${elem.name}.svg`}
                                alt={`${elem.name}`}
                                height={muaMobile ? '23px' : '20px'}
                              />
                            </IconButton>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={styles.backgroundIcon}
                      style={{
                        borderWidth:
                          selectFeeling(item.feeling) === 'unfeeling' && '0px',
                      }}
                    >
                      <IconApp
                        iconName={selectFeeling(item.feeling)}
                        alt={selectFeeling(item.feeling)}
                        height={muaMobile ? '23px' : '20px'}
                      />
                    </div>
                  )}
                  <div className={styles.numberDay}>
                    {moment(item.date).format('D')}
                  </div>
                  <div className={styles.day}>
                    {moment(item.date).format('ddd')}
                  </div>
                </div>
              </Button>
            </div>

            {items[i].id !== totalItems[totalItems.length - 1].id && (
              <Divider
                orientation="vertical"
                flexItem
                style={{ borderColor: '#1A3127' }}
              />
            )}
          </>
        ))}

        <IconButton
          className={styles.calendarIconButton}
          onClick={handleNext}
          disabled={backStep === 0}
        >
          <IconApp
            iconName={backStep === 0 ? 'arrow-right-disabled' : 'arrow-right'}
            alt="arrow-right"
          />
        </IconButton>
      </div>
    )
  }

  return (
    <div className={styles.wrapperCalendar}>
      <ItemCalendar />
    </div>
  )
}

export default Calendar
