import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { loginSuccess } from '../store/login/auth'

export const useAuth = () => {
  const dispatch = useDispatch()
  const localStorageUser = localStorage.getItem('user')
  const { auth } = useSelector((state) => state)
  const { userData } = auth
  const [isAuth, setIsAuth] = useState(!!userData)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    const publicRoutes = ['/set-password', '/forgot-password']
    if (localStorageUser && !userData) {
      const parsedUser = JSON.parse(localStorageUser)
      setIsAuth(true)
      dispatch(loginSuccess(parsedUser))
    }

    if (!localStorageUser) {
      setIsAuth(false)

      !publicRoutes.includes(pathname) && navigate('/')
    }

    if (userData && localStorageUser) {
      setIsAuth(true)
    }
  }, [dispatch, navigate, userData, localStorageUser, pathname])

  return { isAuth }
}
