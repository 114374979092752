/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TableApp } from '../../shared/components/Table/Table'
import { Input, IconButton, Button, Box, Modal } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import styles from './Companies.module.scss'
import globals from '../../shared/constants/global.constant'
import { ModalInput } from '../../shared/components/ModalInput/ModalInput'
import {
  createCompany,
  getCompaniesTable,
  updateCompany,
} from '../../core/store/companies/companies'
import { validateName } from '../../shared/constants/patterns'
import { getUserInfo } from '../../shared/constants/utilities'

export const Companies = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { companies } = useSelector((state) => state)
  const {
    companiesList,
    isSuccessCompanies,
    isLoadingCompanies,
    isSuccessUpdateCompany,
    isSuccessNewCompany,
    isLoadingNewCompany,
    isLoadingUpdateCompany,
  } = companies
  const [forceUpdate, setForceUpdate] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [title, setTitle] = useState('')
  const [clientName, setClientName] = useState('')
  const [clientId, setClientId] = useState('')
  const [clientNameError, setClientNameError] = useState(false)
  const [companiesData, setCompaniesData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortedField] = useState('CreatedOn')
  const [sortDirection] = useState('Descend')
  const [searchBy, setSearchBy] = useState('')
  const [totalItems, setTotalItems] = useState(10)

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: true })
    window.Intercom && window.Intercom('hide')
  }, [])

  useEffect(() => {
    if (getUserInfo().role !== 'Administrator') {
      navigate('/')
    }

    dispatch(
      getCompaniesTable({
        text: searchBy,
        sortedField,
        sortDirection,
        page: page + 1,
        pageSize: rowsPerPage,
      })
    )
  }, [])

  useEffect(() => {
    if (isSuccessCompanies) {
      setCompaniesData(
        companiesList &&
          companiesList.Companies.map((ele) => ({
            id: ele.Id,
            name: ele.Name,
            members: ele.NumberOfEmployees,
            teams: ele.NumberOfTeams,
            status: ele.IsActive,
          }))
      )
      setTotalItems(companiesList.Total)
    }
  }, [isSuccessCompanies, companiesList])

  const columns = [
    {
      id: 'name',
      label: 'Name',
      minWidth: 150,
      align: 'left',
    },
    {
      id: 'members',
      label: 'Members',
      minWidth: 130,
      align: 'center',
    },
    {
      id: 'teams',
      label: 'Teams',
      minWidth: 130,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 130,
      align: 'center',
    },
  ]

  useEffect(() => {
    if (isSuccessUpdateCompany || isSuccessNewCompany) {
      dispatch(
        getCompaniesTable({
          text: searchBy,
          sortedField,
          sortDirection,
          page: page + 1,
          pageSize: rowsPerPage,
        })
      )
      setShowUpdate(false)
      setShowCreate(false)
      setClientName('')
    }
  }, [isSuccessUpdateCompany, isSuccessNewCompany])

  const handleChangeSearch = (event) => {
    const { value } = event.target
    setSearchBy(value)
  }

  const handleSearch = () => {
    setPage(0)
    dispatch(
      getCompaniesTable({
        text: searchBy,
        sortedField,
        sortDirection,
        page: 1,
        pageSize: rowsPerPage,
      })
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    dispatch(
      getCompaniesTable({
        text: searchBy,
        sortedField,
        sortDirection,
        page: newPage + 1,
        pageSize: rowsPerPage,
      })
    )
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    dispatch(
      getCompaniesTable({
        text: searchBy,
        sortedField,
        sortDirection,
        page: page + 1,
        pageSize: +event.target.value,
      })
    )
  }

  const handleOnChange = (event) => {
    const { value } = event.target
    setClientNameError(false)
    setClientName(value)
  }

  const handleOnBlur = (event) => {
    const { value } = event.target
    if (value === '') {
      setClientNameError(true)
    } else {
      setClientNameError(false)
    }
  }

  const handleClose = () => {
    setShowCreate(false)
    setShowUpdate(false)
    setClientNameError(false)
    setClientName('')
  }

  const handleConfirm = () => {
    const validation = validateName['Name'].validate(clientName).error
    const status =
      clientId && companiesData.find((ele) => ele.id === clientId).status

    if (showCreate) {
      if (validation) {
        setClientNameError(true)
      } else {
        dispatch(createCompany({ Name: clientName, description: '' }))
      }
    } else {
      if (validation) {
        setClientNameError(true)
      } else {
        dispatch(
          updateCompany({ Id: clientId, Name: clientName, IsActive: status })
        )
      }
    }
  }

  const handleCreateClient = () => {
    setShowCreate(true)
    setTitle('New company')
  }

  const handleUpdateClient = (id) => {
    const name = companiesData.find((ele) => ele.id === id).name
    setShowUpdate(true)
    setTitle('Update company')
    setClientName(name)
    setClientId(id)
  }

  const handleActivate = (event, companyId) => {
    const { checked, id } = event.target
    const newCompaniesData = companiesData
    const item = companiesData.find((ele) => ele.id === companyId)
    const itemIndex = companiesData.findIndex((ele) => ele.id === companyId)

    item[id] = checked
    newCompaniesData[itemIndex] = item
    setCompaniesData(newCompaniesData)
    dispatch(updateCompany({ Id: item.id, Name: item.name, IsActive: checked }))
    setForceUpdate(!forceUpdate)
  }

  return (
    <section className={styles.companies}>
      <div>
        <Modal open={showCreate || showUpdate} onClose={handleClose}>
          <ModalInput
            id={'Name'}
            title={title}
            handleOnChange={handleOnChange}
            handleOnBlur={handleOnBlur}
            placeholder={'Company name'}
            value={clientName}
            error={clientNameError}
            handleCancel={handleClose}
            handleConfirm={handleConfirm}
            isLoadingCreate={isLoadingNewCompany}
            isLoadingUpdate={isLoadingUpdateCompany}
          />
        </Modal>
      </div>
      <div className={styles.cardList}>
        <div className={styles.header}>
          <Box>
            <Input
              disableUnderline
              className={styles.input}
              placeholder="Search"
              onChange={handleChangeSearch}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch()
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <img
                      src={`${globals.logosBasePath}search.svg`}
                      alt="search"
                      width={'12px'}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Box>
            <Button
              onClick={handleCreateClient}
              className={styles.newClientButton}
            >
              <p>Create new company</p>
            </Button>
          </Box>
        </div>
        <div className={styles.tableWrapper}>
          <TableApp
            handleUpdate={handleUpdateClient}
            handleActivate={handleActivate}
            dataTable={companiesData}
            columns={columns}
            isLoading={isLoadingCompanies}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            className={styles.table}
          />
        </div>
      </div>
    </section>
  )
}
