import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { App } from './App'
import { Login } from './features/Login/Login'
import { Companies } from './features/Companies/Companies'
import { SetPassword } from './features/SetPassword/SetPassword'
import { Users } from './features/Users/Users'
import { Teams } from './features/Teams/Teams'
import { Activities } from './features/Activities/Activities'
import { PersonalAnalytics } from './features/PersonalAnalytics/PersonalAnalytics'
import { TeamAnalytics } from './features/TeamAnalytics/TeamAnalytics'
import { ForgotPassword } from './features/ForgotPassword/ForgotPassword'
import { UsersByTeam } from './features/UsersByTeam/UsersByTeam'
import { Tags } from './features/Tags/Tags'
import { ProfileResponsive } from './features/Profile/Profile'
import { PrivateRoute } from './shared/components/PrivateRoute/PrivateRoute'

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Login />} />
          <Route path="set-password" element={<SetPassword />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="companies" element={<Companies />} />
          <Route path="members" element={<Users />} />
          <Route path="teams">
            <Route index element={<Teams />} />
            <Route path=":teamId" element={<UsersByTeam />} />
          </Route>
          <Route path="tags" element={<Tags />} />
          <Route path="activities" element={<Activities />} />
          <Route path="personal-analytics" element={<PersonalAnalytics />} />
          <Route path="team-analytics" element={<TeamAnalytics />} />
          <Route
            path="profile"
            element={
              <PrivateRoute>
                <ProfileResponsive />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
