import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, CircularProgress } from '@mui/material'
import styles from './ActivitiesList.module.scss'

export const ConfirmationModal = ({
  modalInfo,
  handleClose,
  handleDelete,
  isLoadingDeleteActivity,
}) => {
  return (
    <div>
      <Box className={styles.modalBox}>
        <div className={styles.modalBody}>
          <div className={styles.modalTitle}>{modalInfo.title}</div>
          <div className={styles.modalButtons}>
            {isLoadingDeleteActivity ? (
              <CircularProgress size="20px" color="light" />
            ) : (
              <>
                <Button onClick={handleClose}>
                  <p>{modalInfo.cancelText}</p>
                </Button>
                <Button onClick={handleDelete}>
                  <p>{modalInfo.okText}</p>
                </Button>
              </>
            )}
          </div>
        </div>
      </Box>
    </div>
  )
}

ConfirmationModal.propTypes = {
  modalInfo: PropTypes.object,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
}
