import { createSlice } from '@reduxjs/toolkit'
import { axiosClient } from '../../services/axiosInstance'
import toast from 'react-hot-toast'
import { convertToObjTagsByID } from '../../../shared/constants/utilities'

export const slice = createSlice({
  name: 'userTeam',
  initialState: {
    isLoadingCreate: false,
    isSuccessCreate: false,
    isFailCreate: false,
    isLoadingUpdate: false,
    isSuccessUpdate: false,
    isFailUpdate: false,
    isLoadingDelete: false,
    isSuccessDelete: false,
    isFailDelete: false,
    userTags: [],
    isLoadingTags: false,
    isSuccessTags: false,
    isFailTags: false,
    error: null,
  },
  reducers: {
    createRequest: (state) => {
      return {
        ...state,
        isLoadingCreate: true,
        isSuccessCreate: false,
        isFailCreate: false,
      }
    },
    createSuccess: (state) => {
      return {
        ...state,
        isLoadingCreate: false,
        isSuccessCreate: true,
        isFailCreate: false,
      }
    },
    createFail: (state, action) => {
      return {
        ...state,
        isLoadingCreate: false,
        isSuccessCreate: false,
        isFailCreate: true,
        error: action.payload,
      }
    },
    updateRequest: (state) => {
      return {
        ...state,
        isLoadingUpdate: true,
        isSuccessUpdate: false,
        isFailUpdate: false,
      }
    },
    updateSuccess: (state) => {
      return {
        ...state,
        isLoadingUpdate: false,
        isSuccessUpdate: true,
        isFailUpdate: false,
      }
    },
    updateFail: (state) => {
      return {
        ...state,
        isLoadingUpdate: false,
        isSuccessUpdate: false,
        isFailUpdate: true,
      }
    },
    deleteRequest: (state) => {
      return {
        ...state,
        isLoadingDelete: true,
        isSuccessDelete: false,
        isFailDelete: false,
      }
    },
    deleteSuccess: (state) => {
      return {
        ...state,
        isLoadingDelete: false,
        isSuccessDelete: true,
        isFailDelete: false,
      }
    },
    deleteFail: (state) => {
      return {
        ...state,
        isLoadingDelete: false,
        isSuccessDelete: false,
        isFailDelete: true,
      }
    },
    reset: (state) => {
      return {
        ...state,
        isFailCreate: false,
        error: null,
      }
    },
    getTagsRequest: (state) => {
      return {
        ...state,
        isLoadingTags: true,
        isSuccessTags: false,
        isFailTags: false,
      }
    },
    getTagsSuccess: (state, action) => {
      return {
        ...state,
        userTags: action.payload,
        allUserTags: convertToObjTagsByID(action.payload),
        isLoadingTags: false,
        isSuccessTags: true,
        isFailTags: false,
      }
    },
    getTagsFail: (state, action) => {
      return {
        ...state,
        isLoadingTags: false,
        isSuccessTags: false,
        isFailTags: true,
      }
    },
  },
})

export default slice.reducer

export const {
  createRequest,
  createSuccess,
  createFail,
  updateRequest,
  updateSuccess,
  updateFail,
  deleteRequest,
  deleteSuccess,
  deleteFail,
  reset,
  getTagsRequest,
  getTagsSuccess,
  getTagsFail,
} = slice.actions

export const addUser = (teamId, userData) => async (dispatch) => {
  const { UserId, RoleId } = userData
  dispatch(createRequest())
  const toastLoading = toast.loading('Loading..')
  try {
    await axiosClient.post(`/UserTeam/create/${teamId}`, { UserId, RoleId })
    dispatch(createSuccess())
    toast.dismiss(toastLoading)
    toast.success('Member added successfully')
  } catch (error) {
    dispatch(createFail(error.response.data))
    toast.dismiss(toastLoading)
    toast.fail('Failed to add member')
  }
}

export const updateUserRole = (teamId, userData) => async (dispatch) => {
  const { UserId, RoleId } = userData
  dispatch(updateRequest())
  const toastLoading = toast.loading('Loading..')
  try {
    await axiosClient.put(`/UserTeam/updateRole/${teamId}`, { UserId, RoleId })
    dispatch(updateSuccess())
    toast.dismiss(toastLoading)
    toast.success('Member updated successfully')
  } catch {
    dispatch(updateFail())
    toast.dismiss(toastLoading)
    toast.error('Failed to update member')
  }
}

export const deleteUser = (teamId, userId) => async (dispatch) => {
  dispatch(deleteRequest())
  const toastLoading = toast.loading('Loading..')
  try {
    await axiosClient.delete(`/UserTeam/removeUserTeam/${teamId}/${userId}`)
    dispatch(deleteSuccess())
    toast.dismiss(toastLoading)
    toast.success('Member removed of the team successfully')
  } catch {
    dispatch(deleteFail())
    toast.dismiss(toastLoading)
    toast.error('Failed to delete member')
  }
}

export const getUserTags = (searchBy,userId) => async (dispatch) => {
  dispatch(getTagsRequest())
  try {
    const res = await axiosClient.get(`/UserTeam/GetUserTeamTags?TagName=${searchBy}&UserId=${userId}`)
    dispatch(getTagsSuccess(res.data))
  } catch {
    dispatch(getTagsFail())
  }
}
