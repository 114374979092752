import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'
import styles from './ModalMessage.module.scss'

export const ModalMessage = ({ modalInfo, handleClose, module, handleCancel }) => {
  return (
    <div>
      <Box
        className={styles.modalBox}
        style={{ backgroundColor: module === 'teams' && '#F7F7F7' }}
      >
        <div className={styles.modalBody}>
          <div
            className={styles.modalTitle}
            style={{ color: module === 'teams' && '#1a3127' }}
          >
            {modalInfo.title}
          </div>
          <div className={styles.subtitle}>
            <p style={{ color: module === 'teams' && '#1a3127' }}>
              {modalInfo.message}
            </p>
          </div>
          <div className={styles.modalButtons}>
            {modalInfo.cancelButton && (
              <div>
                <Button
                  onClick={handleCancel}
                  style={{ backgroundColor: module === 'teams' && '#4ea7ac' }}
                >
                  <p style={{ color: module === 'teams' && '#F7F7F7' }}>
                    {modalInfo.cancelButton}
                  </p>
                </Button>
              </div>
            )}
            <div>
              <Button
                onClick={handleClose}
                style={{ backgroundColor: module === 'teams' && '#4ea7ac' }}
              >
                <p style={{ color: module === 'teams' && '#F7F7F7' }}>
                  {modalInfo.button}
                </p>
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </div>
  )
}

ModalMessage.propTypes = {
  modalInfo: PropTypes.object,
  handleClose: PropTypes.func,
  module: PropTypes.string,
}
