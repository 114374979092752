import { useMediaQuery } from 'react-responsive'
import { Navigate } from 'react-router-dom'

export { PrivateRoute }

function PrivateRoute({ children }) {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })

  if (!muaMobile) {
    return <Navigate to="/" />
  }

  return children
}
