import { createSlice } from '@reduxjs/toolkit'
import {
  axiosClient,
  setLocalUser,
  deleteLocalUser,
} from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'auth',
  initialState: {
    userData: null,
    isLoading: false,
    isSuccess: false,
    isFail: false,
    isLoadingLogOut: false,
    isSuccessLogOut: false,
    isFailLogOut: false,
    error: {},
  },
  reducers: {
    loginRequest: (state) => {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isFail: false,
      }
    },
    loginSuccess: (state, action) => {
      return {
        ...state,
        userData: action.payload,
        isLoading: false,
        isSuccess: true,
        isFail: false,
      }
    },
    loginFail: (state, action) => {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSuccess: false,
        isFail: true,
      }
    },
    logOutRequest: (state) => {
      return {
        ...state,
        isLoadingLogOut: true,
        isSuccessLogOut: false,
        isFailLogOut: false,
      }
    },
    logOutSuccess: (state) => {
      return {
        ...state,
        isLoadingLogOut: false,
        isSuccessLogOut: true,
        isFailLogOut: false,
        userData: null,
      }
    },
    logOutFail: (state, action) => {
      return {
        ...state,
        isLoadingLogOut: false,
        isSuccessLogOut: false,
        isFailLogOut: true,
      }
    },
    reset: (state) => {
      return {
        ...state,
        userData: null,
        isLoading: false,
        isSuccess: false,
        isFail: false,
        isLoadingLogOut: false,
        isSuccessLogOut: false,
        isFailLogOut: false,
        error: {},
      }
    },
  },
})

export default slice.reducer

export const {
  loginRequest,
  loginSuccess,
  loginFail,
  logOutRequest,
  logOutSuccess,
  logOutFail,
  reset,
} = slice.actions

export const login = (loginForm) => async (dispatch) => {
  dispatch(loginRequest())
  try {
    const res = await axiosClient.post('/Auth/login', { ...loginForm })
    setLocalUser(res.data)
    dispatch(loginSuccess(res.data))
  } catch (error) {
    dispatch(loginFail(error.response.data))
  }
}

export const logOut = () => async (dispatch) => {
  dispatch(logOutRequest())
  try {
    await axiosClient.get('/Auth/logout')
    dispatch(logOutSuccess())
    dispatch(reset())
    deleteLocalUser()
  } catch {
    dispatch(logOutFail())
  }
}
