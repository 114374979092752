import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, IconButton, Input, InputAdornment } from '@mui/material'
import globals from '../../constants/global.constant'
import styles from './TagsList.module.scss'
import _ from 'lodash'

export const TagsList = ({
  handleChangeSearch,
  handleSearch,
  tagsInfo,
  handleTag,
}) => {
  const orderTags = _.orderBy(tagsInfo, 'Name', 'asc')

  return (
    <div className={styles.wrapper}>
      <div>
        <Input
          disableUnderline
          className={styles.input}
          placeholder="Search"
          onChange={handleChangeSearch}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch()
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleSearch} sx={{ padding: '0px' }}>
                <img
                  src={`${globals.logosBasePath}search-green.svg`}
                  alt="search"
                  width={'10px'}
                />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div className={styles.listWrapper}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {orderTags.map((ele, indx) => (
            <Grid item xs={2} sm={4} md={4} key={indx}>
              <div className={styles.wrapperCol}>
                <div style={{ display: "flex", flexDirection: "row" , gap: "5px", alignItems: "center", marginBottom: "6px" }}>
                  <div className={styles.teamName}>
                    {ele.Name}
                  </div>
                  {ele.IsPrivateTeam && (
                    <img
                      src={`${globals.logosBasePath}private-icon-mua-white.svg`}
                      alt="private"
                      width={"18px"}
                    />
                  )}
                </div>
                {ele.TagList.map((elem, index) => (
                  <Button
                    key={index}
                    onClick={() => {
                      handleTag(elem.Id)
                    }}
                    className={styles.tagName}
                  >
                    {elem.Name}
                  </Button>
                ))}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

TagsList.propTypes = {
  handleChangeSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  tagsInfo: PropTypes.array,
  handleTag: PropTypes.func,
}
