import globals from '../../constants/global.constant'

export const IconApp = (props) => {
  const { iconName, alt, ...rest } = props
  const iconUrl = `${globals.logosBasePath}${iconName}.svg`

  return (
    <>
      <img src={iconUrl} alt={alt} {...rest} />
    </>
  )
}
