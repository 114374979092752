import React from 'react'
import { Button, Drawer, IconButton } from '@mui/material'
import { IconApp } from '../IconApp/IconApp'
import styles from './Layout.module.scss'

export const MenuResponsive = ({
  menu,
  showMenu,
  handleClose,
  handleLogout,
  currentModule,
  handleSelectModule,
  handleGoProfile,
  profileSelected,
}) => {
  return (
    <Drawer
      open={showMenu}
      anchor="left"
      sx={{
        width: '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          boxSizing: 'border-box',
          background: "#F7F7F7",
        },
      }}
    >
      <div className={styles.headerMenu}>
        <IconApp
          iconName="MUA-Logo-RGB"
          width="66"
          height="66"
          alt="MUA-Green"
        />
        <IconButton onClick={handleClose}>
          <IconApp iconName="close-icon" alt="close-icon" />
        </IconButton>
      </div>
      <div className={styles.titleMenu}>Menu</div>
      <div className={styles.buttonsWrapper}>
        {menu.map(
          (ele) =>
            ele.permission && (
              <Button
                onClick={() => handleSelectModule(ele.id)}
                className={styles.buttonMenu}
                style={{ background: ele.id === currentModule && '#4ea7ac' }}
              >
                {ele.id === currentModule && (
                  <IconApp iconName="point" alt="point" />
                )}
                <p style={{ marginLeft: ele.id === currentModule && '11px', color: ele.id === currentModule ? '#1a3127' : '#F7F7F7' }}>
                  {ele.name}
                </p>
              </Button>
            )
        )}
      </div>
      <div className={styles.footerMenu}>
        <Button
          onClick={handleGoProfile}
          className={styles.profileButton}
          style={{ background: profileSelected && '#4ea7ac' }}
        >
          {profileSelected && <IconApp iconName="point" alt="point" />}
          <p style={{ marginLeft: profileSelected && '11px' }}>My Profile</p>
        </Button>
        <IconButton onClick={handleLogout} className={styles.logoutIcon}>
          <IconApp iconName="logout" alt="logout" />
        </IconButton>
      </div>
    </Drawer>
  )
}
