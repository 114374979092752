/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import styles from './ActivitiesList.module.scss'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Input,
  Popover,
  Tab,
  Tabs,
} from '@mui/material'
import globals from '../../constants/global.constant'
import {
  formatHour,
  formatDateTimeZone,
  formatDateTimeZoneWithoutTime,
} from '../../constants/utilities'
import { TagsList } from '../TagsList/TagsList'
import { IconApp } from '../IconApp/IconApp'

export const ActivitiesList = ({
  handleFeeling,
  handleTag,
  activitiesInfo,
  tab,
  handleChange,
  handleOpenTags,
  showTags,
  setShowTags,
  anchorEl,
  handleChangeSearch,
  handleSearch,
  tagsInfo,
  addActivity,
  newActivity,
  handleChangeNewActivity,
  isLoadingCreateActivity,
  handleSave,
  handleNewActivity,
  selectedDate,
  handleSyncCalendar,
  lastSyncCalendarDate,
  handleEditActivity,
  handleDeleteActivity,
  isLoadingUpdateActivity,
  muaMobile,
  showMore,
  handleShowMore,
  handleShowFeeling,
  showFeelings,
  handleSaveEnter,
  handleCloseTagList,
  inputRef
}) => {
  const activitiesOptions = [
    { label: 'ALL', value: 'All' },
    { label: 'AWAITING', value: 'Awaiting' },
    { label: 'UPCOMING', value: 'Upcoming' },
    { label: 'UNTAGGED', value: 'Untagged' },
  ]

  const feelings = [{ name: 'love' }, { name: 'burn' }, { name: 'neutral' }]


  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.headerTitle}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '26px',
            }}
          >
            <div className={styles.activitiesTitle}>Activities</div>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div className={styles.activitiesTitle}>
                {lastSyncCalendarDate &&
                formatDateTimeZoneWithoutTime(selectedDate) ===
                  formatDateTimeZoneWithoutTime(new Date())
                  ? formatDateTimeZone(lastSyncCalendarDate)
                  : ''}
              </div>
              {formatDateTimeZoneWithoutTime(selectedDate) ===
                formatDateTimeZoneWithoutTime(new Date()) && (
                <IconButton
                  className={styles.editButton}
                  onClick={handleSyncCalendar}
                >
                  <IconApp
                    height="17px"
                    iconName="sync-icon"
                    alt="update-icon"
                  />
                </IconButton>
              )}
            </Box>
          </Box>
          <div className={styles.dateTitle}>
            {moment(selectedDate).format('dddd, MMMM D')}
          </div>
        </div>
        <div className={styles.activitiesWrapper}>
          <Box>
            <Tabs
              value={tab}
              onChange={handleChange}
              textColor="inherit"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#4EA7AC',
                },
              }}
            >
              {activitiesOptions.map((ele, indx) => (
                <Tab
                  className={styles.tabs}
                  key={indx}
                  label={ele.label}
                  value={ele.value}
                />
              ))}
            </Tabs>
          </Box>
          <Divider sx={{ borderColor: '#1A3127', marginTop: '12px' }} />
          <div className={styles.listWrapper}>
            {activitiesInfo.map((ele, indx) => (
              <>
                <div key={indx} className={styles.activities}>
                  <div className={styles.wrapperEditActivity}>
                    {!muaMobile && (
                      <div className={styles.wrapperIconButtons}>
                        <IconButton
                          className={styles.editButton}
                          onClick={() =>
                            handleEditActivity(ele.Name, ele.Hour, ele.Id)
                          }
                        >
                          <IconApp
                            iconName="edit-icon-MUA"
                            alt="edit-icon-MUA"
                          />
                        </IconButton>
                        <IconButton
                          className={styles.editButton}
                          onClick={() => handleDeleteActivity(ele.Id)}
                        >
                          <IconApp
                            width="10px"
                            iconName="MUA-icon-trash"
                            alt="MUA-icon-trash"
                          />
                        </IconButton>
                      </div>
                    )}
                    {muaMobile && (
                      <div id={indx} key={indx} className={styles.wrapperMore}>
                        <IconButton onClick={() => handleShowMore(indx)}>
                          <IconApp iconName="more" alt="more" />
                        </IconButton>
                        {showMore[indx] && (
                          <div className={styles.wrapperIconButtonsResponsive}>
                            <IconButton
                              className={styles.actionButton}
                              onClick={() =>
                                handleEditActivity(ele.Name, ele.Hour, ele.Id)
                              }
                            >
                              <IconApp
                                width="12px"
                                iconName="edit-icon-MUA"
                                alt="edit-icon-MUA"
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteActivity(ele.Id)}
                            >
                              <IconApp
                                width="12px"
                                iconName="MUA-icon-trash"
                                alt="MUA-icon-trash"
                              />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={styles.wrapperNameTime}>
                      <div className={styles.activityName}>
                        {muaMobile &&
                          (ele.Name.length > 15
                            ? `${ele.Name.slice(0, 15)}...`
                            : ele.Name)}
                        {!muaMobile &&
                          (ele.Name.length > 32
                            ? `${ele.Name.slice(0, 32)}...`
                            : ele.Name)}
                      </div>
                      <div className={styles.activityTime}>
                        {formatHour(ele.Hour)}
                      </div>
                    </div>
                  </div>
                  <div className={styles.actions}>
                    <Button
                      onClick={(event) => handleOpenTags(event, ele.Id)}
                      className={styles.buttonTag}
                      endIcon={
                        <img
                          src={`${globals.logosBasePath}arrow-down.svg`}
                          alt="arrow-down"
                        />
                      }
                    >
                      {ele.TagName ? ele.TagName : '+Add Tag'}
                    </Button>
                    <Popover
                      open={showTags}
                      onClose={handleCloseTagList}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <TagsList
                        handleChangeSearch={handleChangeSearch}
                        handleSearch={handleSearch}
                        tagsInfo={tagsInfo}
                        handleTag={handleTag}
                      />
                    </Popover>
                    {muaMobile ? (
                      <div>
                        <div
                          className={styles.feelingWrapperResponsive}
                          style={{ display: showFeelings[indx] && 'flex' }}
                        >
                          {feelings.map((elem, index) => (
                            <IconButton
                              key={index}
                              sx={{ padding: '0px' }}
                              onClick={() => handleFeeling(elem.name, ele.Id)}
                            >
                              <img
                                src={`${globals.logosBasePath}${elem.name}.svg`}
                                alt={`${elem.name}`}
                                height="23px"
                              />
                            </IconButton>
                          ))}
                        </div>
                        <IconButton
                          onClick={() => handleShowFeeling(indx)}
                          sx={{ padding: '0px' }}
                        >
                          <div
                            className={styles.feeling}
                            style={{ border: ele.Feeling && 'none' }}
                          >
                            {ele.Feeling && (
                              <img
                                src={`${globals.logosBasePath}${ele.Feeling}.svg`}
                                alt={`${ele.Feeling}`}
                                height={muaMobile ? '23px' : '20px'}
                              />
                            )}
                          </div>
                        </IconButton>
                      </div>
                    ) : (
                      <div className={styles.feelingWrapper}>
                        <div
                          className={styles.feeling}
                          style={{ border: ele.Feeling && 'none' }}
                        >
                          {ele.Feeling && (
                            <img
                              src={`${globals.logosBasePath}${ele.Feeling}.svg`}
                              alt={`${ele.Feeling}`}
                              height="20px"
                            />
                          )}
                        </div>
                        <div className={styles.feelingOptions}>
                          {feelings.map((elem, index) => (
                            <IconButton
                              key={index}
                              sx={{ padding: '0px' }}
                              onClick={() => handleFeeling(elem.name, ele.Id)}
                            >
                              <img
                                src={`${globals.logosBasePath}${elem.name}.svg`}
                                alt={`${elem.name}`}
                                height="20px"
                              />
                            </IconButton>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Divider sx={{ borderColor: '#1A3127', marginBottom: '2px' }} />
              </>
            ))}
          </div>
        </div>
      </div>

      {addActivity ? (
        <div className={styles.addActivityWrapperAqua}>
          <div className={styles.addActivity}>
            <Input
              autoComplete="new-password"
              id="Name"
              autoFocus={true}
              disableUnderline
              value={newActivity.Name}
              onChange={handleChangeNewActivity}
              className={styles.addActivityName}
              onKeyDown={handleSaveEnter}
              inputRef={inputRef}
            />

            <div className={styles.timeCheckWrapper}>
              <Input
                id="Hour"
                type="time"
                defaultValue={moment(new Date()).format('HH:mm')}
                value={newActivity.Hour}
                disableUnderline
                onChange={handleChangeNewActivity}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  '& input[type="time"]::-webkit-calendar-picker-indicator': {
                    display: 'none',
                  },
                }}
                className={styles.time}
              />
              {isLoadingCreateActivity || isLoadingUpdateActivity ? (
                <CircularProgress size="20px" color="primary" />
              ) : (
                <IconButton onClick={handleSave} sx={{ padding: '0px' }}>
                  <img src={`${globals.logosBasePath}check.svg`} alt="check" />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.addActivityWrapper}>
          <Button
            variant="text"
            onClick={handleNewActivity}
            className={styles.newActivityButton}
          >
            <p>+ Add Activity</p>
          </Button>
        </div>
      )}
    </div>
  )
}

ActivitiesList.propTypes = {
  handleFeeling: PropTypes.func,
  handleTag: PropTypes.func,
  activitiesInfo: PropTypes.array,
  tab: PropTypes.string,
  handleChange: PropTypes.func,
  handleOpenTags: PropTypes.func,
  showTags: PropTypes.bool,
  setShowTags: PropTypes.func,
  anchorEl: PropTypes.any,
  handleChangeSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  tagsInfo: PropTypes.array,
  addActivity: PropTypes.bool,
  newActivity: PropTypes.object,
  handleChangeNewActivity: PropTypes.func,
  isLoadingCreateActivity: PropTypes.bool,
  handleSave: PropTypes.func,
  handleNewActivity: PropTypes.func,
  selectedDate: PropTypes.object,
  handleDeleteActivity: PropTypes.func,
  handleEditActivity: PropTypes.func,
  isLoadingUpdateActivity: PropTypes.bool,
  muaMobile: PropTypes.bool,
  handleCloseTagList: PropTypes.func,
  inputRef: PropTypes.any
}
