import React from 'react'
import {
  Divider,
  Box,
  LinearProgress,
  linearProgressClasses,
} from '@mui/material'
import styles from './SummaryFeelings.module.scss'

import { IconApp } from '../IconApp/IconApp'

const SummaryFeelings = ({
  feeling,
  totalFeeling,
  arrayFeelings,
  percentage,
  filter,
  filterTimeName,
  muaMobile,
}) => {
  const feelingsTypes = {
    1: { name: 'Loves', icon: 'love' },
    3: { name: 'Burns', icon: 'burn' },
    2: { name: 'Neutrals', icon: 'neutral' },
  }

  const feelingsPercentage = (numberPercentage, feeling) => {
    switch (feeling) {
      case 1:
        if (numberPercentage > 0) {
          return {
            icon: 'arrow-up-percentage',
            backgroundColor: '#65A3A9',
            color: '#4EA6AB',
          }
        } else if (numberPercentage === 0) {
          return {
            icon: 'equal',
            backgroundColor: '#65A3A9',
            color: '#4EA6AB',
          }
        }
        return {
          icon: 'arrow-down-bad-percentage',
          backgroundColor: '#65A3A9',
          color: '#4EA6AB',
        }
      case 2:
        if (numberPercentage < 0) {
          return {
            icon: 'arrow-down-percentage',
            backgroundColor: '#65A3A9',
            color: '#4EA6AB',
          }
        } else if (numberPercentage === 0) {
          return {
            icon: 'equal',
            backgroundColor: '#65A3A9',
            color: '#4EA6AB',
          }
        }
        return {
          icon: 'arrow-up-bad-percentage',
          backgroundColor: '#65A3A9',
          color: '#4EA6AB',
        }
      case 3:
        if (numberPercentage < 0) {
          return {
            icon: 'arrow-down-percentage',
            backgroundColor: '#65A3A9',
            color: '#4EA6AB',
          }
        } else if (numberPercentage === 0) {
          return {
            icon: 'equal',
            backgroundColor: '#65A3A9',
            color: '#4EA6AB',
          }
        }
        return {
          icon: 'arrow-up-bad-percentage',
          backgroundColor: '#65A3A9',
          color: '#4EA6AB',
        }

      default:
        return {
          icon: 'equal',
          backgroundColor: '#65A3A9',
          color: '#4EA6AB',
        }
    }
  }

  const arrayQty = arrayFeelings.map((ele) => ele.Quantity)
  const maxQty = Math.max(...arrayQty)

  const sortArrayFeelings = arrayFeelings.sort(
    (a, b) => b.Quantity - a.Quantity
  )

  const LinearProgressNumber = ({ name, number, color }) => {
    return (
      <Box sx={{ mb: '2px', mr: '5px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <div className={styles.numberFeel}>{name}</div>
          </Box>
          <Box>
            <div className={styles.numberFeel} style={{ color: color }}>
              {number}
            </div>
          </Box>
        </Box>

        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            value={Math.round((number * 100) / maxQty)}
            sx={{
              height: 2.5,
              borderRadius: 5,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: '#1A3127',
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                backgroundColor: color,
              },
            }}
          />
        </Box>
      </Box>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.wrapperIcon}>
          <IconApp
            iconName={feelingsTypes[feeling].icon}
            alt={feelingsTypes[feeling].icon}
            height={muaMobile && '22'}
            width={muaMobile && '20'}
          />
          <div className={styles.feeling}>{feelingsTypes[feeling].name}</div>
        </div>
        <div
          className={styles.wrapperPercentage}
          style={{
            backgroundColor: feelingsPercentage(percentage, feeling)
              .backgroundColor,
          }}
        >
          <div className={styles.percentage}>
            <IconApp
              iconName={feelingsPercentage(percentage, feeling).icon}
              alt={feelingsPercentage(percentage, feeling).icon}
              height={muaMobile && '6'}
              width={muaMobile && '12'}
            />
          </div>
          <div className={styles.percentage}>{`${Math.abs(percentage)}%`}</div>
        </div>
      </div>
      <div className={styles.middle}>
        <div className={styles.totalFeeling}>{totalFeeling}</div>
        <div className={styles.filter}>{filterTimeName[filter]}</div>
      </div>
      <Divider flexItem sx={{ borderColor: "#1A3127", borderBottomWidth: '0.5px' }} />
      <div className={styles.bottom}>
        {sortArrayFeelings.map((ele, indx) => (
          <LinearProgressNumber
            key={indx}
            name={ele.Name}
            number={ele.Quantity}
            color={ele.Color}
          />
        ))}
      </div>
    </div>
  )
}

export default SummaryFeelings
