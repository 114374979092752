import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Login.module.scss'
import { Button, CircularProgress, TextField } from '@mui/material'
import { validateLoginForm } from '../../shared/constants/patterns'
import { login } from '../../core/store/login/auth'
import { getUserInfo } from '../../shared/constants/utilities'
import { useMediaQuery } from 'react-responsive'
import globals from '../../shared/constants/global.constant'

export const Login = () => {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })

  const dispatch = useDispatch()
  const { auth } = useSelector((state) => state)
  const { isSuccess, isFail, isLoading } = auth
  const navigate = useNavigate()
  const [forceUpdate, setForceUpdate] = useState(false)
  const [loginForm, setLoginForm] = useState({ UserName: '', Password: '' })
  const [errorForm, setErrorForm] = useState({
    UserName: {
      error: false,
      message: '',
    },
    Password: {
      error: false,
      message: '',
    },
  })

  const error = Object.values(errorForm).find((ele) => ele.error === true) || {}

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: true })
    window.Intercom && window.Intercom('hide')
  }, [])

  useEffect(() => {
    if (isSuccess) {
      getUserInfo().role === 'Administrator'
        ? navigate('/companies')
        : navigate('/activities')
    }
  }, [isSuccess, navigate])

  const handleChange = (event) => {
    const { value, id } = event.target
    const newErrorForm = errorForm
    newErrorForm[id].error = false
    newErrorForm[id].message = ''
    setErrorForm(newErrorForm)

    const newLoginForm = loginForm
    newLoginForm[id] = value
    setLoginForm(newLoginForm)
    setForceUpdate(!forceUpdate)
  }

  const handleSignIn = () => {
    const validation = validateLoginForm.validate(loginForm, {
      abortEarly: false,
    })

    if (validation.error) {
      const newErrorForm = errorForm
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true
        newErrorForm[ele.context.label].message =
          'The email or password you entered is not valid, please try again.'
        setErrorForm(newErrorForm)
        setForceUpdate(!forceUpdate)
      })
    } else {
      dispatch(login(loginForm))
    }
  }

  const handleForgot = () => {
    navigate('/forgot-password')
  }

  return (
    <section className={styles.Home}>
      <div className={styles.logo}>
        <img
          alt="logo"
          src={`${globals.logosBasePath}new-logo-white.png`}
          width="94px"
          height="43px"
        />
      </div>
      <div className={styles.greeting}>Hey, it's nice to see you again!</div>
      <div className={styles.card}>
        <div className={styles.wrapperLogin}>
          <TextField
            id="UserName"
            onChange={handleChange}
            placeholder="Email"
            className={styles.customTextField}
            InputProps={{
              sx: {
                color: '#1a3127',
              },
            }}
            sx={{ marginBottom: '26px', width: muaMobile ? '265px' : '340px' }}
          />
          <TextField
            id="Password"
            onChange={handleChange}
            placeholder="Password"
            type="password"
            className={styles.customTextField}
            InputProps={{
              sx: {
                color: '#1a3127',
              },
            }}
            sx={{ marginBottom: '26px', width: muaMobile ? '265px' : '340px' }}
          />
          {isLoading ? (
            <div className={styles.progress}>
              <CircularProgress size="20px" color="aqua" />
            </div>
          ) : (
            <>
              <div className={styles.loginButtonWrapper}>
                <Button
                  className={styles.loginButton}
                  color="light"
                  variant="contained"
                  onClick={handleSignIn}
                >
                  <p>Log in</p>
                </Button>
              </div>
            </>
          )}
          {(error.error || isFail) && (
            <div className={styles.errorMessage}>
              <h4>
                The email or password you entered is not valid, please try
                again.
              </h4>
              <Button
                variant="text"
                className={styles.forgotButton}
                onClick={handleForgot}
              >
                <p>Forgot your pasword?</p>
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.message}>Accelerating workplace performance™</div>
    </section>
  )
}
