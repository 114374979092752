import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Toaster } from 'react-hot-toast';
import { AppRouter } from './app/App.router';
import { store } from './app/core/store';
import { theme } from './app/core/material-theme';
import { IdleTimerProvider } from 'react-idle-timer';
import { Modal } from '@mui/material';
import { LogoutModal } from './app/shared/components/ModalLogout/ModalLogout';

import './index.scss';
import { logOut } from './app/core/store/login/auth';
import { setLogout } from './app/core/store/common/common';
import { useAuth } from './app/core/hooks/useAuth';

const App = () => {
  const dispatch = useDispatch();
  const { isLoadingLogOut, isSuccessLogOut, isFailLogOut } = useSelector((state) => state?.auth);
  const idleTimerRef = useRef(null);
  const [showLogout, setShowLogout] = useState(false);
  const isAuth = localStorage.getItem('user');

  useEffect(() => {
    if (isSuccessLogOut || isFailLogOut) {
      setShowLogout(false);
      dispatch(setLogout(false));
      window.location.href = "/";
    }
  }, [isFailLogOut, isSuccessLogOut]);

  const onIdle = () => {
    if (isAuth) {
      setShowLogout(true);
    }
  };

  const handleClose = () => {
    dispatch(logOut());
    dispatch(setLogout(true));
  };

  return (
    <>
      <Modal open={showLogout} onClose={handleClose}>
        <LogoutModal
          handleClose={handleClose}
          isLoading={isLoadingLogOut}
        />
      </Modal>

      <IdleTimerProvider
        ref={idleTimerRef}
        timeout={36000000}
        onIdle={onIdle}
      >
        <CssBaseline />
        <Toaster
          position="top-right"
          reverseOrder={false}
          containerStyle={{ zIndex: 999999 }}
          toastOptions={{
            success: {
              iconTheme: {
                primary: '#688c83',
                secondary: '#fff',
              },
            },
            error: {
              iconTheme: {
                primary: '#ce2828',
                secondary: '#fff',
              },
            },
            style: { minWidth: '400px', zIndex: 999999 },
          }}
        />
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </IdleTimerProvider>
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
