import React from 'react'
import PropTypes from 'prop-types'
import { Box, Input, Button, CircularProgress, Switch, Typography } from '@mui/material'
import styles from '../Styles/Modal.module.scss'

export const ModalInput = ({
  id,
  placeholder,
  title,
  handleOnChange,
  handleOnBlur,
  value,
  error,
  handleCancel,
  handleConfirm,
  isLoadingCreate,
  isLoadingUpdate,
  handleSwitch,
  isPrivateTeam,
  source,
}) => {
  return (
    <div>
      <Box className={styles.modalBox}>
        <div className={styles.modalBody}>
          <div className={styles.modalTitle}>{title}</div>
          <Input
            disableUnderline
            id={id}
            className={styles.modalInput}
            placeholder={placeholder}
            value={value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={error}
            maxRows={100}
          />
          {source === "team" && (
            <div 
              style={{ 
                display: "flex", 
                width: "100%",
                justifyContent:"space-between", 
                alignItems: "center" 
                }}
              >
              <Typography>Make this team private</Typography>
              <Switch onChange={handleSwitch} checked={isPrivateTeam} color='aqua' />
            </div>
          )}
          <div className={styles.modalButtons}>
            {isLoadingCreate || isLoadingUpdate ? (
              <CircularProgress size="20px" color="primary" />
            ) : (
              <>
                <div>
                  <Button onClick={handleCancel}>
                    <p>Cancel</p>
                  </Button>
                </div>
                <div>
                  <Button onClick={handleConfirm}>
                    <p>Confirm</p>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Box>
    </div>
  )
}

ModalInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  error: PropTypes.bool,
  isLoadingCreate: PropTypes.bool,
  isLoadingUpdate: PropTypes.bool,
}
