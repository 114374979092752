/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import styles from './Layout.module.scss'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import {
  IconButton,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material'
import moment from 'moment-timezone'
import { logOut } from '../../../core/store/login/auth'
import { IconApp } from '../IconApp/IconApp'

import globals from '../../constants/global.constant'
import { getCompanies } from '../../../core/store/companies/companies'
import {
  getProfile,
  setCompany,
  updateProfile,
} from '../../../core/store/common/common'
import { getUserInfo } from '../../constants/utilities'
import { resetUsers } from '../../../core/store/users/users'
import { resetTeams, resetTeamsTags } from '../../../core/store/teams/teams'
import { validateUpdateProfile } from '../../constants/patterns'
import { Profile } from './Profile'
import { useMediaQuery } from 'react-responsive'
import { MenuResponsive } from './MenuResponsive'

export const Layout = ({ children }) => {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })

  const dispatch = useDispatch()
  const { auth, companies, common } = useSelector((state) => state)
  const { isSuccessLogOut } = auth
  const { companiesSelect } = companies
  const {
    company,
    user,
    isSuccessSetCalendar,
    isSuccessUpdateUser,
    isLoadingUpdateUser,
    isLoadingUser,
  } = common
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [forceUpdate, setForceUpdate] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [profileAction, setProfileAction] = useState('profile')
  const [currentModule, setCurrentModule] = useState(
    getUserInfo().isAdministrator ? '1' : '5'
  )

  const [profileSelected, setProfileSelected] = useState(false)
  const [companiesList, setCompaniesList] = useState([])
  const [companySelected, setCompanySelected] = useState(company)
  const [userInfo, setUserInfo] = useState({
    IsActiveNotification: true,
    TimeZone: { Name: '', Offset: '' },
    Calendar: '',
    FirstName: '',
    LastName: '',
    Email: '',
    Id: '',
  })
  const [errorUserInfo, setErrorUserInfo] = useState({
    FirstName: {
      error: false,
    },
    LastName: {
      error: false,
    },
    TimeZone: {
      error: false,
    },
    Calendar: {
      error: false,
    },
    IsActiveNotification: {
      error: false,
    },
  })
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const teamName = params.get('teamName')

  const role = getUserInfo() && getUserInfo().role
  const { isAdministrator, isEmployee, isLead, isOwner } = getUserInfo()
  const userId = getUserInfo() && getUserInfo().id

  const handleLogout = () => {
    dispatch(setCompany(null))
    dispatch(resetUsers())
    dispatch(resetTeams())
    dispatch(resetTeamsTags())
    dispatch(logOut())
    setForceUpdate(!forceUpdate)
  }

  useEffect(() => {
    if (isAdministrator) {
      dispatch(getCompanies())
    } else {
      dispatch(getProfile(userId))
    }
  }, [role])

  useEffect(() => {
    if (user) {
      const {
        FirstName,
        LastName,
        Email,
        Id,
        TimeZone,
        Calendar,
        IsActiveNotification,
      } = user
      setUserInfo({
        IsActiveNotification,
        TimeZone,
        Calendar: Calendar,
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Id: Id,
      })
    }
  }, [user])

  useEffect(() => {
    if (companiesSelect.length > 0) {
      setCompaniesList(
        companiesSelect.map((ele) => ({
          id: ele.Id,
          companyName: ele.Name,
        }))
      )
    }
  }, [companiesSelect])

  useEffect(() => {
    if (isSuccessLogOut) {
      navigate('/')
    }
  }, [isSuccessLogOut, navigate])

  useEffect(() => {
    if (isSuccessUpdateUser) {
      dispatch(getProfile(userId))
      setProfileAction('profile')
    }
  }, [isSuccessUpdateUser])

  useEffect(() => {
    if (isSuccessSetCalendar) {
      dispatch(getProfile(userId))
      navigate('/')
      setProfileAction('profile')
    }
  }, [isSuccessSetCalendar])

  const handleSelect = (value) => {
    switch (value) {
      case '1':
        navigate('/companies')
        break
      case '2':
        navigate('/members')
        break
      case '3':
        navigate('/teams')
        break
      case '4':
        navigate('/tags')
        break
      case '5':
        navigate('/activities')
        break
      case '6':
        navigate('/personal-analytics')
        break
      case '7':
        navigate('/team-analytics')
        break
      default:
        break
    }
  }

  const menu = [
    {
      name: 'Companies',
      id: '1',
      permission: isAdministrator,
    },
    {
      name: 'Members',
      id: '2',
      permission: isAdministrator,
    },
    {
      name: 'Teams',
      id: '3',
      permission: isAdministrator,
    },
    {
      name: 'Tags',
      id: '4',
      permission: isAdministrator,
    },
    {
      name: 'My Activities',
      id: '5',
      permission: isEmployee || isLead,
    },
    {
      name: 'My Analytics',
      id: '6',
      permission: isEmployee || isLead,
    },
    {
      name: 'My Team',
      id: '7',
      permission: isLead || isOwner,
    },
  ]

  const [hovers, setHovers] = useState(
    Array.from({ length: menu.length }, () => false)
  )

  const handleSelectModule = (event) => {
    const { value } = event.target
    setCurrentModule(value)
    handleSelect(value)
    setForceUpdate(!forceUpdate)
  }

  const handleSelectModuleResponsive = (value) => {
    setCurrentModule(value)
    handleSelect(value)
    setShowMenu(false)
    setProfileSelected(false)
    setForceUpdate(!forceUpdate)
  }

  const handleGoProfile = () => {
    setProfileSelected(true)
    setCurrentModule('0')
    navigate('/profile')
    setShowMenu(false)
  }

  const handleSelectCompany = (value) => {
    const { id } = value
    setCompanySelected(id)
    dispatch(setCompany(id))
    setForceUpdate(!forceUpdate)
  }

  const handleUpdate = () => {
    setProfileAction('update')
  }

  const handleCloseUpdate = () => {
    setProfileAction('profile')
  }

  const handleChange = (event) => {
    const { value, id } = event.target
    const newUserInfo = userInfo
    newUserInfo[id] = value
    setUserInfo(newUserInfo)

    const newErrorUserInfo = errorUserInfo
    newErrorUserInfo[id].error = false
    setErrorUserInfo(newErrorUserInfo)
    setForceUpdate(!forceUpdate)
  }

  const handleSelectCalendar = async (value) => {
    const newUserInfo = userInfo
    newUserInfo.Calendar = value === '1' ? 'Google' : 'Outlook'
    await setUserInfo(newUserInfo)
    dispatch(updateProfile(userId, userInfo))
    setForceUpdate(!forceUpdate)
  }

  const handleSelectNotification = (value) => {
    const newUserInfo = userInfo
    newUserInfo.IsActiveNotification = value
    setUserInfo(newUserInfo)
    setForceUpdate(!forceUpdate)
  }

  const handleSelectTimeZone = (selTimeZone) => {
    const newUserInfo = userInfo
    newUserInfo.TimeZone = {
      Name: selTimeZone.id,
      Offset: moment.tz(selTimeZone.id).format('Z'),
    }

    setUserInfo(newUserInfo)
    setForceUpdate(!forceUpdate)
  }

  const handleSave = () => {
    const validation = validateUpdateProfile.validate(userInfo, {
      abortEarly: false,
    })

    if (validation.error) {
      const newErrorUserInfo = errorUserInfo
      validation.error.details.forEach((ele) => {
        newErrorUserInfo[ele.context.label].error = true
        setErrorUserInfo(newErrorUserInfo)
        setForceUpdate(!forceUpdate)
      })
    } else {
      dispatch(updateProfile(userId, userInfo))
      setForceUpdate(!forceUpdate)
    }
  }

  const handleMenu = () => {
    setShowMenu(true)
  }

  const handleClose = () => {
    setShowMenu(false)
  }

  const onEnter = (index) => {
    setHovers(hovers.map((hover, i) => (i === index ? true : hover)))
  }

  const onLeave = (index) => {
    setHovers(hovers.map((hover, i) => (i === index ? false : hover)))
  }

  return (
    <div className={styles.layout}>
      {muaMobile ? (
        <div className={styles.navigationResponsive}>
          <div className={styles.menu}>
            <IconButton onClick={handleMenu}>
              <img
                src={`${globals.logosBasePath}menu.svg`}
                alt="menu"
                className={styles.menuButton}
              />
            </IconButton>
          </div>
          <div className={styles.iconMUAResponsive}>
            <IconApp
              iconName="MUA-Logo-RGB"
              width="66"
              height="66"
              alt="MUA-Green"
            />
          </div>
          <MenuResponsive
            menu={menu}
            showMenu={showMenu}
            handleClose={handleClose}
            handleLogout={handleLogout}
            currentModule={currentModule}
            handleSelectModule={handleSelectModuleResponsive}
            handleGoProfile={handleGoProfile}
            profileSelected={profileSelected}
          />
        </div>
      ) : (
        <div className={styles.navigation}>
          <div className={styles.iconMUA}>
            <IconApp
              iconName="MUA-Logo-RGB"
              width="70"
              height="70"
              alt="MUA-Green"
            />
          </div>
          <div className={styles.radioGroup}>
            <RadioGroup
              defaultValue={currentModule}
              onChange={handleSelectModule}
              name="radio-buttons-group"
              sx={{ gap: '30px' }}
            >
              {menu.map(
                (ele, indx) =>
                  ele.permission && (
                    <div key={ele.id} className={styles.radio}>
                      <div
                        id={ele.id}
                        className={styles.tooltip}
                        onMouseEnter={() => onEnter(indx)}
                        onMouseLeave={() => onLeave(indx)}
                      >
                        <Radio
                          className={styles.radioButton}
                          value={ele.id}
                          name="radio-buttons"
                          sx={{
                            color: hovers[indx] ? '#1A3127' : '#4EA7AC',
                            '&.Mui-checked': {
                              color: hovers[indx] ? '#1A3127' : '#4EA7AC',
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: hovers[indx] ? 20 : 24,
                            },
                            '&:hover': {
                              background: 'transparent',
                            },
                          }}
                        />
                        <p key={ele.id} className={styles.tooltipName}>
                          {ele.name}
                        </p>
                      </div>
                    </div>
                  )
              )}
            </RadioGroup>
          </div>
          <div className={styles.logout}>
            <IconButton onClick={handleLogout}>
              <img
                src={`${globals.logosBasePath}logout.svg`}
                alt="logout"
                className={styles.logoutButton}
              />
            </IconButton>
          </div>
        </div>
      )}

      {role === 'Administrator' ? (
        <div className={styles.wrapperChildrenAdmin}>
          <div className={styles.wrapperHeader}>
            {pathname !== '/companies' && (
              <Autocomplete
                disablePortal
                disableClearable
                noOptionsText="No options"
                id="Companies"
                onChange={(event, value) => handleSelectCompany(value)}
                getOptionLabel={(option) => `${option.companyName}`}
                value={companiesList.find((ele) => ele.id === companySelected)}
                disabled={teamName !== null}
                options={companiesList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={companySelected}
                    placeholder={'Select company'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    className={styles.textField}
                  />
                )}
              />
            )}
          </div>

          {children}
        </div>
      ) : (
        <>
          <div className={styles.wrapperChildren}>{children}</div>
          {!muaMobile && (
            <div
              className={styles.wrapperProfile}
              style={{
                justifyContent: profileAction === 'profile' && 'center',
                alignItems: profileAction === 'profile' && 'center',
              }}
            >
              {isLoadingUser ? (
                <CircularProgress size="20px" color="light" />
              ) : (
                <>
                  {profileAction === 'profile' ? (
                    <>
                      <div className={styles.updateIcon}>
                        <IconButton onClick={handleUpdate}>
                          <img
                            src={`${globals.logosBasePath}edit.svg`}
                            alt="edit"
                          />
                        </IconButton>
                      </div>
                      <div className={styles.userName}>
                        {`${userInfo.FirstName} ${userInfo.LastName}`}
                      </div>
                      <div className={styles.companyName}>
                        {getUserInfo().companyName}
                      </div>
                      <div className={styles.userEmail}>{userInfo.Email}</div>
                      {userInfo.Calendar && (
                        <div className={styles.userEmail}>
                          {`Calendar: ${userInfo.Calendar} Calendar`}
                        </div>
                      )}
                      {userInfo.TimeZone?.Name && (
                        <div className={styles.userEmail}>
                          {`Time zone: ${userInfo.TimeZone?.Name}`}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className={styles.updateIcon}>
                        <IconButton onClick={handleCloseUpdate}>
                          <img
                            src={`${globals.logosBasePath}close-icon.svg`}
                            alt="close"
                          />
                        </IconButton>
                      </div>
                      <Profile
                        isLoadingUpdateUser={isLoadingUpdateUser}
                        handleSave={handleSave}
                        handleChange={handleChange}
                        handleSelectCalendar={handleSelectCalendar}
                        handleSelectNotification={handleSelectNotification}
                        handleSelectTimeZone={handleSelectTimeZone}
                        userInfo={userInfo}
                        errorUserInfo={errorUserInfo}
                        muaMobile={muaMobile}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}
