import { createTheme } from '@mui/material'

export const theme = createTheme({
  overrides: {
    MUIRichTextEditor: {
      root: {
        color: '#1A3127',
        fontSize: '13px',
      },
      container: {},
      editor: {
        color: '#1A3127',
        fontSize: '13px',
      },
      toolbar: {
        alignSelf: 'auto',
        color: '#1A3127',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        position: 'sticky',
        top: 0,
        '& .MuiIconButton-root': {
          color: '#1A3127',
        },
      },
      placeHolder: {
        color: '#1A3127',
        fontSize: '13px',
      },
    },
  },

  palette: {
    type: 'dark',
    primary: {
      main: '#1A3127',
    },
    secondary: {
      main: '#14271F',
    },
    light: {
      main: '#F7F7F7',
    },
    aqua: {
      main: '#4ea7ac',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    error: {
      main: '#CE2828',
    },
  },
  typography: {
    fontFamily: 'Matter',
  },
  components: {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        content: {
          background: '#1A3127',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          color: '#F7F7F7',
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: { color: '#F7F7F7' },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: { color: '#F7F7F7' },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: '#1A3127',
          color: '#F7F7F7',
          '&.Mui-selected': {
            backgroundColor: '#4ea7ac !important',
            borderRadius: '10px',
          },
        },
        today: {
          borderRadius: '10px',
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#F7F7F7',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        colorPrimary: {
          color: '#374b42',
        },
        sizeSmall: {},
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '0px',
        },
        option: {
          color: '#1a3127',
          fontSize: '13px',
        },
        popupIndicator: {
          marginTop: '2px',
        },
        noOptions: {
          color: '#1a3127',
          fontSize: '13px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '20px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#F7F7F7',
          background: '#14271F',
          borderRadius: '6px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#1a3127',
          fontSize: '13px',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#F7F1D9',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          color: '#F7F7F7',
          height: '33px',
        },
      },
    },
    MuiInput: {
      underline: {
        display: 'none',
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#F7F7F7',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: '#F7F7F7',
          borderColor: '#F7F7F7',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#1A3127',
          borderBottom: '1px solid #1A3127',
          fontSize: '12px',
          lineHeight: '16px',
        },
        head: {
          fontSize: '21px',
          lineHeight: '23px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: '#F7F7F7',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: '#4ea7ac',
          color: '#F7F7F7',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#F7F1D9',
          fontSize: '8px',
          alignItems: 'flex-start',
          padding: 0,
          minWidth: 'max-content',
          minHeight: 'max-content',
          marginRight: '15px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minWidth: 'max-content',
          minHeight: 'max-content',
        },
        indicator: {
          backgroundColor: '#F7F1D9',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#1a3127',
          fontSize: '13px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: '2px',
          color: "#f7f7f7"
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#1a3127',
        },
      },
    },
  },
})
