import { createSlice } from '@reduxjs/toolkit'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'role',
  initialState: {
    rolesList: [],
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {
    getRolesRequest: (state) => {
      return {
        ...state,
        rolesList: [],
        isLoading: true,
        isSuccess: false,
        isFail: false,
      }
    },
    getRolesSuccess: (state, action) => {
      return {
        ...state,
        rolesList: action.payload,
        isLoading: false,
        isSuccess: true,
        isFail: false,
      }
    },
    getRolesFail: (state) => {
      return {
        ...state,
        rolesList: [],
        isLoading: false,
        isSuccess: false,
        isFail: true,
      }
    },
  },
})

export default slice.reducer

export const {
  getRolesRequest,
  getRolesSuccess,
  getRolesFail,
} = slice.actions

export const getRoles = () => async (dispatch) => {
  dispatch(getRolesRequest())
  try {
    const res = await axiosClient.get(`/Role/GetAll`)
    dispatch(getRolesSuccess(res.data))
  } catch {
    dispatch(getRolesFail())
  }
}
