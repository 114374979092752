import { createSlice } from '@reduxjs/toolkit'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'personalAnalytics',
  initialState: {
    personalFeelings: [],
    comparisonFeelingsData: [],
    progressChartPersonalData: [],
    isLoadingComparisonFeelings: false,
    isSuccessComparisonFeelings: false,
    isFailComparisonFeelings: false,
    isLoadingProgressChart: false,
    isSuccessProgressChart: false,
    isFailProgressChart: false,
  },
  reducers: {
    getPersonalFeelingsRequest: (state) => {
      return {
        ...state,
        personalFeelingsData: [],
        isLoadingPersonalFeelings: true,
        isSuccessPersonalFeelings: false,
        isFailPersonalFeelings: false,
      }
    },
    getPersonalFeelingsSuccess: (state, action) => {
      return {
        ...state,
        personalFeelingsData: action.payload,
        isLoadingPersonalFeelings: false,
        isSuccessPersonalFeelings: true,
        isFailPersonalFeelings: false,
      }
    },
    getPersonalFeelingsFail: (state) => {
      return {
        ...state,
        personalFeelingsData: [],
        isLoadingPersonalFeelings: false,
        isSuccessPersonalFeelings: false,
        isFailPersonalFeelings: true,
      }
    },
    getComparisonFeelingsRequest: (state) => {
      return {
        ...state,
        comparisonFeelingsData: [],
        isLoadingComparisonFeelings: true,
        isSuccessComparisonFeelings: false,
        isFailComparisonFeelings: false,
      }
    },
    getComparisonFeelingsSuccess: (state, action) => {
      return {
        ...state,
        comparisonFeelingsData: action.payload,
        isLoadingComparisonFeelings: false,
        isSuccessComparisonFeelings: true,
        isFailComparisonFeelings: false,
      }
    },
    getComparisonFeelingsFail: (state) => {
      return {
        ...state,
        comparisonFeelingsData: [],
        isLoadingComparisonFeelings: false,
        isSuccessComparisonFeelings: false,
        isFailComparisonFeelings: true,
      }
    },
    getProgressChartRequest: (state) => {
      return {
        ...state,
        progressChartPersonalData: [],
        isLoadingProgressChart: true,
        isSuccessProgressChart: false,
        isFailProgressChart: false,
      }
    },
    getProgressChartSuccess: (state, action) => {
      return {
        ...state,
        progressChartPersonalData: action.payload,
        isLoadingProgressChart: false,
        isSuccessProgressChart: true,
        isFailProgressChart: false,
      }
    },
    getProgressChartFail: (state) => {
      return {
        ...state,
        progressChartPersonalData: [],
        isLoadingProgressChart: false,
        isSuccessProgressChart: false,
        isFailProgressChart: true,
      }
    },
  },
})

export default slice.reducer

export const {
  getPersonalFeelingsRequest,
  getPersonalFeelingsSuccess,
  getPersonalFeelingsFail,
  getComparisonFeelingsRequest,
  getComparisonFeelingsSuccess,
  getComparisonFeelingsFail,
  getProgressChartRequest,
  getProgressChartSuccess,
  getProgressChartFail
} = slice.actions

export const getPersonalFeelings = (userId, tab) => async (dispatch) => {
  dispatch(getPersonalFeelingsRequest())
  try {
    const res = await axiosClient.get(
      `/Activity/GetFeelingsByTag/${userId}?timeFrameEnum=${tab}`
    )
    dispatch(getPersonalFeelingsSuccess(res.data))
  } catch {
    dispatch(getPersonalFeelingsFail())
  }
}

export const getComparisonFeelings = (userId, date) => async (dispatch) => {
  dispatch(getComparisonFeelingsRequest())
  try {
    const res = await axiosClient.get(
      `/Activity/GetComparissionFeelingsByUser/${userId}?date=${date}`
    )
    dispatch(getComparisonFeelingsSuccess(res.data))
  } catch {
    dispatch(getComparisonFeelingsFail())
  }
}

export const getProgressChart = (userId, feeling, time) => async (dispatch) => {
  dispatch(getProgressChartRequest())
  try {
    const res = await axiosClient.get(
      `/Activity/GetPersonalProgressChart/${userId}?feelingsEnum=${feeling}&timeFrameEnum=${time}`,
    )
    dispatch(getProgressChartSuccess(res.data.FeelingJournalList))
  } catch {
    dispatch(getProgressChartFail())
  }
}