import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'
import styles from '../Styles/Modal.module.scss'

export const UserInfo = ({ title, userInfo }) => {
  const { teamsRole } = userInfo
  return (
    <div>
      <Box className={styles.modalBox}>
        <div className={styles.modalBody}>
          <Grid
            className={styles.wrapper}
            container
            justifyContent="space-between"
            rowSpacing={1}
            columnSpacing={{ xs: 2, sm: 4, md: 6 }}
          >
            <Grid container item justifyContent={'center'} xs={6}>
              <div className={styles.subtitle}>Team</div>
            </Grid>
            <Grid container item justifyContent={'center'} xs={6}>
              <div className={styles.subtitle}>Permission</div>
            </Grid>
            {teamsRole.map((ele, indx) => (
              <>
                <Grid
                  container
                  item
                  justifyContent={'center'}
                  key={indx}
                  xs={6}
                >
                  <div
                    className={styles.wrapperName}
                    style={{ background: ele.TeamColor }}
                  >
                    {ele.Name}
                  </div>
                </Grid>
                <Grid container item justifyContent={'center'} xs={6}>
                  <div className={styles.role}>{ele.Role}</div>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      </Box>
    </div>
  )
}

UserInfo.propTypes = {
  title: PropTypes.string,
  userInfo: PropTypes.object,
}
