import React, { useState } from 'react'
import { IconButton, MenuItem, Select, Checkbox } from '@mui/material'
import globals from '../../constants/global.constant'

import styles from './ProgressChart.module.scss'
import moment from 'moment/moment'
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from 'victory'
import _ from 'lodash'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export const ProgressChart = ({
  handleFeeling,
  feelingSelected,
  filterTime,
  action,
  teamsInfo,
  teamIds,
  handleSelectTeams,
  sampleData,
  teamsChartData,
  sampleDataScatter,
  openDailyJournal,
  teamNames,
}) => {
  const [hover, setHover] = useState(false)
  const [newSampleData, setNewSampleData] = useState([{ x: '', y: 0 }])

  const feelings = [
    { name: 'love', nameSelected: 'love-gray' },
    { name: 'burn', nameSelected: 'burn-gray' },
    { name: 'neutral', nameSelected: 'neutral-gray' },
  ]

  const newSampleDataScatter =
    action !== 'Team' && sampleDataScatter.filter((ele) => ele !== null)

  const y = action !== 'Team' && newSampleDataScatter.map((ele) => ele.y)
  const maxNumber = action !== 'Team' && _.max(y)

  const notFeelingsTeams = (data) => {
    let checkFeel = [false]
    if (data) {
      checkFeel = data.map((elem) => {
        return !elem.sampleData.map((ele) => ele.y === 0).includes(false)
      })
    }
    return checkFeel
  }

  const notFeelings =
    (action !== 'Team' &&
      !sampleData.map((ele) => ele.y === 0).includes(false)) ||
    (action === 'Team' &&
      notFeelingsTeams(teamsChartData).every((el) => el === true))

  const handleTime = (time) => {
    switch (time) {
      case 'oneWeekAgo':
        return `${moment()
          .subtract(7, 'days')
          .format('MMM DD YYYY')} - ${moment()
          .subtract(1, 'days')
          .format('MMM DD YYYY')}`
      case 'oneMonthAgo':
        return `${moment()
          .subtract(30, 'days')
          .format('MMM DD YYYY')} - ${moment()
          .subtract(1, 'days')
          .format('MMM DD YYYY')}`
      case 'threeMonthsAgo':
        return `${moment()
          .subtract(90, 'days')
          .format('MMM DD YYYY')} - ${moment()
          .subtract(1, 'days')
          .format('MMM DD YYYY')}`
      case 'sixMonthsAgo':
        return `${moment()
          .subtract(180, 'days')
          .format('MMM DD YYYY')} - ${moment()
          .subtract(1, 'days')
          .format('MMM DD YYYY')}`
      default:
        break
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Progress Chart</div>
      <div className={styles.subtitleWrapper}>
        <div className={styles.date}>{handleTime(filterTime)}</div>
        <div className={styles.wrapperTeamFeelings}>
          {action === 'Team' && (
            <div>
              <Select
                className={styles.select}
                displayEmpty
                multiple
                value={teamIds}
                onChange={handleSelectTeams}
                renderValue={(selected) => (
                  <p style={{ color: '#F7F7F7' }}>Teams</p>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: '#1A3127',
                      borderRadius: '12px',
                    },
                  },
                }}
                IconComponent={(props) => (
                  <ArrowDropDownIcon {...props} style={{ color: '#F7F7F7' }} />
                )}
              >
                <MenuItem disabled value="">
                  <p style={{ color: '#F7F7F7' }}>Select team(s)</p>
                </MenuItem>
                {teamsInfo.map((ele, indx) => (
                  <MenuItem
                    key={indx}
                    value={ele.id}
                    style={{ color: '#F7F7F7' }}
                  >
                    <Checkbox
                      checked={teamIds.indexOf(ele.id) > -1}
                      color="light"
                    />
                    <div
                      style={{
                        border: '1px solid #F7F7F7',
                        padding: '0px 6px',
                      }}
                    >
                      {ele.name}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          <div className={styles.feelingOptions}>
            {feelings.map((elem, index) => (
              <IconButton
                key={index}
                sx={{ padding: '0px' }}
                onClick={() => handleFeeling(elem.name)}
              >
                <img
                  src={`${globals.logosBasePath}${elem.name}.svg`}
                  alt={`${elem.name}`}
                  height="17px"
                  style={{ opacity: feelingSelected !== elem.name && 0.3 }}
                />
              </IconButton>
            ))}
          </div>
        </div>
      </div>
      <div
        className={styles.feelingGraphs}
        style={{ height: action === 'Team' && '70%' }}
      >
        <VictoryChart
          domainPadding={15}
          padding={{
            bottom: 30,
            top: 30,
            right: action === 'Team' ? -100 : -45,
            left: action === 'Team' ? -125 : -70,
          }}
        >
          <VictoryAxis
            dependentAxis
            orientation="right"
            style={{
              axis: { display: 'none' },
              grid: { stroke: '#1A3127', strokeWidth: 0.2 },
              tickLabels: {
                display: notFeelings && 'none',
                fontSize: 16,
                fill: '#1A3127',
                fontFamily: 'inherit',
                fontWeight: 100,
              },
            }}
          />
          <VictoryAxis
            style={{
              axis: { stroke: '#1A3127', strokeWidth: 0.2 },
              grid: { stroke: 'none' },
              tickLabels: {
                fontSize: 16,
                fill: '#1A3127',
                fontFamily: 'Matter',
                fontWeight: 'lighter',
              },
            }}
          />
          {action === 'Team' ? (
            teamsChartData.map((ele, index) => (
              <VictoryLine
                key={index}
                style={{
                  data: { stroke: ele.color },
                }}
                data={ele.sampleData}
              />
            ))
          ) : (
            <VictoryLine
              style={{
                data: { stroke: '#4ea7ac' },
              }}
              data={sampleData}
            />
          )}
          {action !== 'Team' && (
            <VictoryGroup>
              <VictoryScatter
                style={{
                  data: {
                    fill: 'none',
                    stroke: '#1A3127',
                    strokeWidth: hover ? 1 : 0,
                    cursor: 'pointer',
                  },
                }}
                size={10}
                data={newSampleData}
                domain={{
                  y: [0, newSampleDataScatter.length > 0 && maxNumber],
                }}
              />
              <VictoryScatter
                style={{ data: { fill: '#4ea7ac', cursor: 'pointer' } }}
                size={4}
                data={newSampleDataScatter}
                domain={{
                  y: [0, newSampleDataScatter.length > 0 && maxNumber],
                }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            mutation: (props) => {
                              setHover(true)
                              setNewSampleData([
                                { x: props.datum.x, y: props.datum.y },
                              ])
                            },
                          },
                        ]
                      },
                      onMouseOut: () => {
                        return [
                          {
                            mutation: () => {
                              setHover(false)
                            },
                          },
                        ]
                      },
                      onClick: () => {
                        return [
                          {
                            target: 'data',
                            mutation: (props) => {
                              openDailyJournal(props.index)
                            },
                          },
                        ]
                      },
                    },
                  },
                ]}
              />
            </VictoryGroup>
          )}
        </VictoryChart>
      </div>
      {action === 'Team' && (
        <div className={styles.wrapperTeamNames}>
          {teamNames.map((ele, index) => (
            <>
              <div key={index} className={styles.wrapperNames}>
                <div className={styles.teamName}>
                  &nbsp;{`${index !== 0 ? '+' : ''}  ${ele.teamName}`}
                </div>
                <div
                  className={styles.wrapperDivider}
                  style={{ marginLeft: index !== 0 && '8%' }}
                >
                  &nbsp;
                  <hr
                    className={styles.divider}
                    style={{ background: ele.color, borderColor: ele.color }}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  )
}
