import React, { useRef, useState } from 'react'

import { Box, Button, IconButton } from '@mui/material'

import MUIRichTextEditor from 'mui-rte'
import { convertToRaw } from 'draft-js'
import { convertToHTML, convertFromHTML } from 'draft-convert'
import styles from './DailyJournal.module.scss'
import globals from '../../constants/global.constant'

const JournalModal = ({
  isEdition,
  handleClose,
  handleSave,
  handleEdit,
  handleDelete,
  handleChange,
  defaultValue = null,
}) => {
  const [textDefault] = useState(
    defaultValue
      ? JSON.stringify(convertToRaw(convertFromHTML(defaultValue)))
      : null
  )

  const handleChangeText = (value) => {
    handleChange(convertToHTML(value.getCurrentContent()))
  }

  const editorRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.metaKey && event.key === 'a') {
      event.preventDefault();
      if (editorRef.current) {
        const editorInstance = editorRef.current.getEditor();
        const selection = editorInstance.getSelection();
        if (selection) {
          editorInstance.setSelection({
            anchorOffset: 0,
            focusOffset: editorInstance.getLength(),
          });
        }
      }
    }
  };

  return (
    <div className={styles.modalCard}>
      <Box className={styles.modalBox}>
        <MUIRichTextEditor
          defaultValue={defaultValue && textDefault}
          toolbarButtonSize="small"
          controls={['bold', 'italic', 'underline', 'numberList', 'bulletList']}
          label="Start typing..."
          onChange={handleChangeText}
          ref={editorRef}
          onKeyDown={handleKeyDown}
        />
      </Box>
      <Box className={styles.buttonsModalWrapper}>
        <Button
          className={styles.modalButton}
          color="light"
          variant="contained"
          onClick={handleClose}
        >
          <p>Cancel</p>
        </Button>
        <Box className={styles.buttonsModalWrapper}>
          {isEdition && (
            <IconButton
              onClick={handleDelete}
              sx={{
                padding: '0px 0px',
                width: '24px',
                height: '24px',
              }}
            >
              <img
                width="20"
                height="20"
                src={`${globals.logosBasePath}MUA-icon-trash.svg`}
                alt="delete"
              />
            </IconButton>
          )}

          <Button
            className={styles.modalButton}
            color="light"
            variant="contained"
            onClick={isEdition ? handleEdit : handleSave}
          >
            <p>Save</p>
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default JournalModal
