/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TableApp } from '../../shared/components/Table/Table'
import { Input, IconButton, Box, Modal, Button } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import styles from '../Companies/Companies.module.scss'
import ownStyles from './UsersByTeam.module.scss'
import globals from '../../shared/constants/global.constant'
import { getUsersByTeam, reset } from '../../core/store/users/users'
import { getUserInfo } from '../../shared/constants/utilities'
import { UsersTeamsModal } from '../../shared/components/UsersTeamsModal/UsersTeamsModal'
import { validateAddMember } from '../../shared/constants/patterns'
import { deleteUser, updateUserRole } from '../../core/store/userTeam/userTeam'

export const UsersByTeam = () => {
  const dispatch = useDispatch()
  const { users, common, userTeam } = useSelector((state) => state)
  const { usersByTeamList, isSuccessUsersByTeam, isLoadingUsersByTeam } = users
  const { company } = common
  const { isLoadingUpdate, isSuccessDelete, isSuccessUpdate } = userTeam
  const navigate = useNavigate()
  const [forceUpdate, setForceUpdate] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [UserId, setUserId] = useState('')
  const [usersData, setUsersData] = useState([])
  const [memberData, setMemberData] = useState({
    Email: '',
    RoleId: '',
  })
  const [memberDataError, setMemberDataError] = useState({
    Email: {
      error: false,
      message: '',
    },
    RoleId: {
      error: false,
      message: '',
    },
  })
  const [autocompleteValue, setAutocompleteValue] = useState({
    Email: '',
    UserId: '',
  })
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortedField] = useState('CreatedOn')
  const [sortDirection] = useState('Descend')
  const [searchBy, setSearchBy] = useState('')
  const [totalItems, setTotalItems] = useState(10)
  const { teamId } = useParams()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const teamName = params.get('teamName')
  const color = params.get('color')
  const isPrivateTeam = params.get('isPrivateTeam')

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: true })
    window.Intercom && window.Intercom('hide')
  }, [])

  useEffect(() => {
    if (getUserInfo().role !== 'Administrator') {
      navigate('/')
    }
    dispatch(reset())
    setForceUpdate(!forceUpdate)
  }, [])

  useEffect(() => {
    if (company) {
      dispatch(
        getUsersByTeam({
          teamId: teamId,
          text: searchBy,
          sortedField,
          sortDirection,
          page: page + 1,
          pageSize: rowsPerPage,
        })
      )
    }
  }, [company])

  useEffect(() => {
    if (isSuccessDelete || isSuccessUpdate) {
      dispatch(
        getUsersByTeam({
          teamId: teamId,
          text: searchBy,
          sortedField,
          sortDirection,
          page: page + 1,
          pageSize: rowsPerPage,
        })
      )
    }
  }, [isSuccessDelete, isSuccessUpdate])

  useEffect(() => {
    if (isSuccessUsersByTeam) {
      setUsersData(
        usersByTeamList.UserTeamList &&
          usersByTeamList.UserTeamList.map((ele) => ({
            id: ele.UserId,
            name: ele.FirstName,
            lastName: ele.LastName,
            email: ele.Email,
            team: ele.Team,
            role: ele.Role,
          }))
      )
      setTotalItems(usersByTeamList.Total)
    }
  }, [isSuccessUsersByTeam, usersByTeamList])

  const columns = [
    {
      id: 'name',
      label: 'Member',
      minWidth: 170,
      align: 'left',
    },
    {
      id: 'role',
      label: 'Role',
      minWidth: 170,
      align: 'center',
    },
  ]

  const handleChangeSearch = (event) => {
    const { value } = event.target
    setSearchBy(value)
  }

  const handleSearch = () => {
    setPage(0)
    dispatch(
      getUsersByTeam({
        teamId: teamId,
        text: searchBy,
        sortedField,
        sortDirection,
        page: 1,
        pageSize: rowsPerPage,
      })
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    dispatch(
      getUsersByTeam({
        teamId: teamId,
        text: searchBy,
        sortedField,
        sortDirection,
        page: newPage + 1,
        pageSize: rowsPerPage,
      })
    )
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    dispatch(
      getUsersByTeam({
        teamId: teamId,
        text: searchBy,
        sortedField,
        sortDirection,
        page: page + 1,
        pageSize: +event.target.value,
      })
    )
  }

  const handleUpdateUser = (id) => {
    setShowUpdate(true)
    const userInfo = usersData.find((ele) => ele.id === id)
    setAutocompleteValue({
      Email: userInfo.email,
      UserId: userInfo.id,
    })
    setMemberData({
      Email: userInfo.email,
      RoleId:
        userInfo.role === 'Employee'
          ? '4dbae9dd-7d08-4b5e-a3a3-08da7a5bb569'
          : '44abe3fc-8f84-4d2f-a3a2-08da7a5bb569',
    })
    setUserId(id)
  }

  const handleDeleteUser = (id) => {
    dispatch(deleteUser(teamId, id))
    setForceUpdate(!forceUpdate)
  }

  const handleClose = () => {
    setShowUpdate(false)
  }

  const handleSelect = (event) => {
    const { value } = event.target
    const newMemberData = memberData
    newMemberData.RoleId = value
    setMemberData(newMemberData)
    setForceUpdate(!forceUpdate)
  }

  const handleConfirmMember = () => {
    const validation = validateAddMember.validate(memberData, {
      abortEarly: false,
    })

    const newMemberDataError = memberDataError
    if (validation.error) {
      validation.error.details.forEach((ele) => {
        newMemberDataError[ele.context.label].error = true
        newMemberDataError[ele.context.label].message = 'Required fields'
        setMemberDataError(newMemberDataError)
        setForceUpdate(!forceUpdate)
      })
    } else {
      dispatch(updateUserRole(teamId, { ...memberData, UserId }))
      setShowUpdate(false)
    }
  }

  const handleBack = () => {
    navigate('/teams')
  }

  return (
    <section className={styles.companies}>
      <div>
        <Modal open={showUpdate} onClose={handleClose}>
          <UsersTeamsModal
            title={'Update member'}
            value={memberData}
            handleSelect={handleSelect}
            handleCancel={handleClose}
            handleConfirm={handleConfirmMember}
            isLoading={isLoadingUpdate}
            autocompleteValue={autocompleteValue}
            isPrivateTeam={isPrivateTeam === "true" ? true : false}
          />
        </Modal>
      </div>
      <div className={styles.cardList}>
        <div style={{ margin: '20px 0px 10px 0px' }}>
          <Button
            variant="text"
            startIcon={
              <img
                src={`${globals.logosBasePath}arrow-left-2.svg`}
                alt="back"
                width={'15px'}
                color="#1a3127"
              />
            }
            className={ownStyles.backButton}
            onClick={handleBack}
          >
            <p>Back</p>
          </Button>
        </div>
        <div className={styles.header} style={{ marginTop: '0px' }}>
          <Box>
            <Input
              disableUnderline
              className={styles.input}
              placeholder="Search"
              onChange={handleChangeSearch}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch()
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <img
                      src={`${globals.logosBasePath}search.svg`}
                      alt="search"
                      width={'12px'}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <div style={{ display: "flex", flexDirection: "row" , gap: "5px", alignItems: "center" }}>
            <div
              className={ownStyles.teamName}
              style={{ background: `#${color}` }}
            >
              {teamName}
            </div>
            {isPrivateTeam === "true" && (
              <img
                src={`${globals.logosBasePath}private-icon-mua.svg`}
                alt="private"
              />
            )}
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <TableApp
            handleUpdate={handleUpdateUser}
            handleDelete={handleDeleteUser}
            dataTable={usersData}
            columns={columns}
            isLoading={isLoadingUsersByTeam}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            className={styles.table}
          />
        </div>
      </div>
    </section>
  )
}
