/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import {
  getDataDays,
  getDataMonths,
  getUserInfo,
} from '../../shared/constants/utilities'
import FeelingsPerDay from '../../shared/components/FeelingsPerDay'
import SummaryFeelings from '../../shared/components/SummaryFeelings'
import MainTabs from '../../shared/components/MainTabs'
import styles from './TeamAnalytics.module.scss'

import {
  getComparisonFeelings,
  getProgressChart,
  getTeamFeelings,
  getTeamRecurringFeelings,
  getTeams,
} from '../../core/store/teamAnalytics/teamAnalytics'
import moment from 'moment/moment'
import { ProgressChart } from '../../shared/components/ProgressChart/ProgressChart'
import { useMediaQuery } from 'react-responsive'
import { ProgressChartResponsive } from '../../shared/components/ProgressChart/ProgressChartResponsive'

export const TeamAnalytics = () => {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { teamAnalytics, common, auth } = useSelector((state) => state)
  const {
    teamFeelingsData,
    isLoadingTeamFeelings,
    isSuccessTeamFeelings,
    recurringFeelingsData,
    teamsData,
    comparisonFeelingsData,
    progressChartTeamData,
  } = teamAnalytics

  const { isSuccessLogOut } = auth

  const filterTimeName = {
    oneWeekAgo: 'Last week',
    oneMonthAgo: 'Last month',
    threeMonthsAgo: 'Last 3 months',
    sixMonthsAgo: 'Last 6 months',
  }

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [forceUpdate, setForceUpdate] = useState(false)
  const [filterTime, setFilterTime] = useState('oneWeekAgo')
  const [feelingSelected, setFeelingSelected] = useState('love')
  const [informationFeelings, setInformationFeelings] = useState([])
  const [teamsInfo, setTeamsInfo] = useState([])
  const [teamSelected, setTeamSelected] = useState('')
  const [comparisonFeelingsInfo, setComparisonFeelingsInfo] = useState([])
  const [progressChartInfo, setProgressChartInfo] = useState([])
  const [teamIds, setTeamIds] = useState([])
  const [teamNames, setTeamNames] = useState([])
  const [dataDays] = useState(getDataDays())
  const [dataMonths] = useState(getDataMonths())
  const userId = getUserInfo().id

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: false })

    if (getUserInfo().role === 'Administrator') {
      navigate('/')
    } else {
      dispatch(getTeamFeelings(userId, 'oneWeekAgo'))
      dispatch(getTeams(userId))
      setForceUpdate(!forceUpdate)
    }
  }, [])

  useEffect(() => {
    if (isSuccessLogOut) {
      setTeamSelected('')
    }
  }, [isSuccessLogOut])

  useEffect(() => {
    if (progressChartTeamData?.length > 0) {
      switch (filterTime) {
        case 'oneWeekAgo':
          setProgressChartInfo(
            progressChartTeamData.map((ele, indx) => ({
              color: ele.TeamColor,
              sampleData: ele.FeelingCounter.map((elem, index) => ({
                x: dataDays[index].day,
                y: elem,
              })),
            }))
          )
          break
        case 'oneMonthAgo':
          setProgressChartInfo(
            progressChartTeamData.map((ele, indx) => ({
              color: ele.TeamColor,
              sampleData: ele.FeelingCounter.map((elem, index) => ({
                x: `Week ${index + 1}`,
                y: elem,
              })),
            }))
          )
          break
        case 'threeMonthsAgo':
          setProgressChartInfo(
            progressChartTeamData.map((ele, indx) => ({
              color: ele.TeamColor,
              sampleData: ele.FeelingCounter.map((elem, index) => ({
                x: dataMonths[index + 3].month,
                y: elem,
              })),
            }))
          )
          break
        case 'sixMonthsAgo':
          setProgressChartInfo(
            progressChartTeamData.map((ele, indx) => ({
              color: ele.TeamColor,
              sampleData: ele.FeelingCounter.map((elem, index) => ({
                x: muaMobile
                  ? dataMonths[index].month.slice(0, 3)
                  : dataMonths[index].month,
                y: elem,
              })),
            }))
          )
          break
        default:
          break
      }
      setTeamNames(
        progressChartTeamData.map((ele) => ({
          color: ele.TeamColor,
          teamName: ele.TeamName,
        }))
      )
    }
  }, [progressChartTeamData, filterTime, muaMobile])

  useEffect(() => {
    if (comparisonFeelingsData && comparisonFeelingsData.length > 0) {
      const love = comparisonFeelingsData.find((ele) => ele.FeelingId === 1)
      const neutral = comparisonFeelingsData.find((ele) => ele.FeelingId === 2)
      const burn = comparisonFeelingsData.find((ele) => ele.FeelingId === 3)

      setComparisonFeelingsInfo([
        {
          x: 2,
          y: love.Average,
          fill: '#407F7D',
          opacity: 0.8,
        },
        {
          x: 3,
          y: burn.Average,
          fill: '#407F7D',
          opacity: 0.8,
        },
        {
          x: 4,
          y: neutral.Average,
          fill: '#407F7D',
          opacity: 0.8,
        },
        {
          x: 2,
          y: love.Current,
          fill: '#4EA7AC',
          opacity: 0.8,
        },
        {
          x: 3,
          y: burn.Current,
          fill: '#4EA7AC',
          opacity: 0.8,
        },
        {
          x: 4,
          y: neutral.Current,
          fill: '#4EA7AC',
          opacity: 0.8,
        },
      ])
    }
  }, [comparisonFeelingsData])

  useEffect(() => {
    if (teamSelected) {
      dispatch(
        getTeamRecurringFeelings(
          teamSelected,
          moment(new Date()).subtract(4, 'days').format('YYYY-MM-DD'),
          moment(new Date()).format('YYYY-MM-DD')
        )
      )
      dispatch(
        getComparisonFeelings(
          teamSelected,
          moment(new Date()).format('YYYY-MM-DD')
        )
      )
      setForceUpdate(!forceUpdate)
    }
  }, [teamSelected])

  useEffect(() => {
    if (teamsData && teamsData.length > 0) {
      setTeamsInfo(
        teamsData.map((ele) => ({
          id: ele.Id,
          name: ele.Name,
        }))
      )
      setTeamSelected(teamsData.length > 0 && teamsData[0].Id)
      setTeamIds([teamsData[0].Id])
      dispatch(getProgressChart([teamsData[0].Id], feelingSelected, filterTime))
    }
  }, [teamsData])

  useEffect(() => {
    if (isSuccessTeamFeelings) {
      const newTeamFeelingsData = teamFeelingsData.filter((ele) => ele.Id !== 2)
      const neutral = teamFeelingsData.find((ele) => ele.Id === 2)
      newTeamFeelingsData.push(neutral)

      setInformationFeelings(
        newTeamFeelingsData.map((ele) => {
          const { Growth, Id, Total, FeelingTeams } = ele
          return {
            Id,
            Total,
            Growth,
            Feelings: FeelingTeams.map((feel) => ({
              Name: feel.TeamName,
              Quantity: feel.Quantity,
              Color: feel.TeamColor,
            })),
          }
        })
      )
    }
  }, [isSuccessTeamFeelings])

  const handleMove = (selDate) => {
    dispatch(
      getTeamRecurringFeelings(
        userId,
        moment(selDate).subtract(2, 'days').format('YYYY-MM-DD'),
        moment(selDate).add(2, 'days').format('YYYY-MM-DD')
      )
    )
  }

  const handleSelectDate = (selDate) => {
    setSelectedDate(selDate)

    dispatch(
      getComparisonFeelings(teamSelected, moment(selDate).format('YYYY-MM-DD'))
    )
  }

  const handleChangeFilterTime = (event, time) => {
    setFilterTime(time)
    dispatch(getTeamFeelings(userId, time))
    dispatch(getProgressChart(teamIds, feelingSelected, time))
  }

  const handleSelectTeam = (event) => {
    const { value } = event.target
    setTeamSelected(value)
    setForceUpdate(!forceUpdate)
  }

  const handleFeeling = (name) => {
    setFeelingSelected(name)
    dispatch(getProgressChart(teamIds, name, filterTime))
  }

  const handleSelectTeams = (event) => {
    const { value } = event.target
    setTeamIds(value)
    dispatch(getProgressChart(value, feelingSelected, filterTime))
  }

  return (
    <section className={styles.teamAnalytics}>
      <div className={styles.container}>
        {isLoadingTeamFeelings ? (
          <>
            <div className={styles.wrapperTitle}>
              <div className={styles.title}>
                {`${common?.user?.FirstName} ${common?.user?.LastName}`}
              </div>
              {!muaMobile && (
                <MainTabs
                  value={filterTime}
                  handleChange={handleChangeFilterTime}
                  arrayTabs={Object.keys(filterTimeName).map((ele) => ({
                    name: filterTimeName[ele],
                    value: ele,
                  }))}
                />
              )}
            </div>
            <div className={styles.wrapperSubtitle}>
              <p className={styles.subtitle}>My</p>
              &nbsp;
              <p className={styles.subtitle2}>Team</p>
            </div>
            {muaMobile && (
              <div className={styles.tabsResponsive}>
                <MainTabs
                  value={filterTime}
                  handleChange={handleChangeFilterTime}
                  arrayTabs={Object.keys(filterTimeName).map((ele) => ({
                    name: filterTimeName[ele],
                    value: ele,
                  }))}
                />
              </div>
            )}
            <div className={styles.row}>
              <div className={styles.colLeft}>
                <Skeleton variant="rounded" width={'100%'} height={290} />
                <div className={styles.rowBottom}>
                  <Skeleton
                    variant="rounded"
                    width={muaMobile ? '100%' : '33%'}
                    height={200}
                  />
                  <Skeleton
                    variant="rounded"
                    width={muaMobile ? '100%' : '33%'}
                    height={200}
                  />
                  <Skeleton
                    variant="rounded"
                    width={muaMobile ? '100%' : '33%'}
                    height={200}
                  />
                </div>
              </div>
              <div className={styles.colRight}>
                <Skeleton variant="rounded" width={'100%'} height={512} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.wrapperTitle}>
              <div className={styles.title}>
                {`${common?.user?.FirstName} ${common?.user?.LastName}`}
              </div>
              {!muaMobile && (
                <MainTabs
                  value={filterTime}
                  handleChange={handleChangeFilterTime}
                  arrayTabs={Object.keys(filterTimeName).map((ele) => ({
                    name: filterTimeName[ele],
                    value: ele,
                  }))}
                />
              )}
            </div>
            <div className={styles.wrapperSubtitle}>
              <p className={styles.subtitle}>My</p>
              &nbsp;
              <p className={styles.subtitle2}>Team</p>
            </div>
            {muaMobile && (
              <div className={styles.tabsResponsive}>
                <MainTabs
                  value={filterTime}
                  handleChange={handleChangeFilterTime}
                  arrayTabs={Object.keys(filterTimeName).map((ele) => ({
                    name: filterTimeName[ele],
                    value: ele,
                  }))}
                />
              </div>
            )}
            <div className={styles.row}>
              <div className={styles.colLeft}>
                <div className={styles.colLeftTop}>
                  {muaMobile ? (
                    <ProgressChartResponsive
                      handleFeeling={handleFeeling}
                      feelingSelected={feelingSelected}
                      filterTime={filterTime}
                      action={'Team'}
                      teamsInfo={teamsInfo}
                      teamIds={teamIds}
                      handleSelectTeams={handleSelectTeams}
                      teamsChartData={progressChartInfo}
                      teamNames={teamNames}
                    />
                  ) : (
                    <ProgressChart
                      handleFeeling={handleFeeling}
                      feelingSelected={feelingSelected}
                      filterTime={filterTime}
                      action={'Team'}
                      teamsInfo={teamsInfo}
                      teamIds={teamIds}
                      handleSelectTeams={handleSelectTeams}
                      teamsChartData={progressChartInfo}
                      teamNames={teamNames}
                    />
                  )}
                </div>
                <div className={styles.rowBottom}>
                  {informationFeelings?.length > 0 &&
                    informationFeelings.map((ele, indx) => (
                      <div key={indx} className={styles.colLeftBottom}>
                        <SummaryFeelings
                          totalFeeling={ele.Total}
                          feeling={ele.Id}
                          arrayFeelings={ele.Feelings}
                          percentage={ele.Growth}
                          filter={filterTime}
                          filterTimeName={filterTimeName}
                          muaMobile={muaMobile}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <FeelingsPerDay
                sampleData={comparisonFeelingsInfo}
                module={'team'}
                teamSelected={teamSelected}
                handleSelectTeam={handleSelectTeam}
                teamsInfo={teamsInfo}
                handleMove={handleMove}
                recurringFeelingsData={recurringFeelingsData}
                selectedDate={selectedDate}
                handleSelectDate={handleSelectDate}
                muaMobile={muaMobile}
              />
            </div>
          </>
        )}
      </div>
    </section>
  )
}
