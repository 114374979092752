/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Skeleton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  formatHour,
  getDataDays,
  getDataMonths,
  getUserInfo,
} from '../../shared/constants/utilities'
import FeelingsPerDay from '../../shared/components/FeelingsPerDay'
import SummaryFeelings from '../../shared/components/SummaryFeelings'
import MainTabs from '../../shared/components/MainTabs'
import styles from './PersonalAnalytics.module.scss'

import {
  getComparisonFeelings,
  getPersonalFeelings,
  getProgressChart,
} from '../../core/store/personalAnalytics/personalAnalytics'
import { getRecurringFeelings } from '../../core/store/activities/activities'
import moment from 'moment/moment'
import { ProgressChart } from '../../shared/components/ProgressChart/ProgressChart'
import DailyJournalModal from '../../shared/components/ProgressChart/DailyJournalModal'
import { useMediaQuery } from 'react-responsive'
import { ProgressChartResponsive } from '../../shared/components/ProgressChart/ProgressChartResponsive'

export const PersonalAnalytics = () => {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { personalAnalytics, common, activities } = useSelector(
    (state) => state
  )
  const {
    personalFeelingsData,
    isLoadingPersonalFeelings,
    isSuccessPersonalFeelings,
    comparisonFeelingsData,
    progressChartPersonalData,
  } = personalAnalytics

  const { recurringFeelingsData } = activities

  const filterTimeName = {
    oneWeekAgo: 'Last week',
    oneMonthAgo: 'Last month',
    threeMonthsAgo: 'Last 3 months',
    sixMonthsAgo: 'Last 6 months',
  }

  const [selectedDate, setSelectedDate] = useState(new Date())

  const [filterTime, setFilterTime] = useState('oneWeekAgo')
  const [feelingSelected, setFeelingSelected] = useState('love')
  const [informationFeelings, setInformationFeelings] = useState([])
  const [comparisonFeelingsInfo, setComparisonFeelingsInfo] = useState([])
  const [progressChartInfo, setProgressChartInfo] = useState([])
  const [progressChartDaily, setProgressChartDaily] = useState([])
  const [dataDays] = useState(getDataDays())
  const [dataMonths] = useState(getDataMonths())
  const [showDailyJournal, setShowDailyJournal] = useState(false)
  const [dailyJournalInfo, setDailyJournalInfo] = useState([])
  const userId = getUserInfo().id

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: false })

    if (getUserInfo().role === 'Administrator') {
      navigate('/')
    } else {
      dispatch(getPersonalFeelings(userId, 'oneWeekAgo'))
      dispatch(
        getRecurringFeelings(
          userId,
          moment(new Date()).subtract(4, 'days').format('YYYY-MM-DD'),
          moment(new Date()).format('YYYY-MM-DD')
        )
      )
      dispatch(
        getComparisonFeelings(userId, moment(selectedDate).format('YYYY-MM-DD'))
      )
      dispatch(getProgressChart(userId, feelingSelected, filterTime))
    }
  }, [])

  useEffect(() => {
    if (progressChartPersonalData?.length > 0) {
      switch (filterTime) {
        case 'oneWeekAgo':
          setProgressChartInfo(
            progressChartPersonalData.map((ele, index) => ({
              x: dataDays[index].day,
              y: ele.FeelingCounter,
            }))
          )
          setProgressChartDaily(
            progressChartPersonalData.map(
              (ele, index) =>
                ele.JournalList && {
                  x: dataDays[index].day,
                  y: ele.FeelingCounter,
                }
            )
          )
          break
        case 'oneMonthAgo':
          setProgressChartInfo(
            progressChartPersonalData.map((ele, index) => ({
              x: `Week ${index + 1}`,
              y: ele.FeelingCounter,
            }))
          )
          setProgressChartDaily(
            progressChartPersonalData.map(
              (ele, index) =>
                ele.JournalList && {
                  x: `Week ${index + 1}`,
                  y: ele.FeelingCounter,
                }
            )
          )
          break
        case 'threeMonthsAgo':
          setProgressChartInfo(
            progressChartPersonalData.map((ele, index) => ({
              x: dataMonths[index + 3].month,
              y: ele.FeelingCounter,
            }))
          )
          setProgressChartDaily(
            progressChartPersonalData.map(
              (ele, index) =>
                ele.JournalList && {
                  x: dataMonths[index + 3].month,
                  y: ele.FeelingCounter,
                }
            )
          )
          break
        case 'sixMonthsAgo':
          setProgressChartInfo(
            progressChartPersonalData.map((ele, index) => ({
              x: muaMobile
                ? dataMonths[index].month.slice(0, 3)
                : dataMonths[index].month,
              y: ele.FeelingCounter,
            }))
          )
          setProgressChartDaily(
            progressChartPersonalData.map(
              (ele, index) =>
                ele.JournalList && {
                  x: muaMobile
                    ? dataMonths[index].month.slice(0, 3)
                    : dataMonths[index].month,
                  y: ele.FeelingCounter,
                }
            )
          )
          break
        default:
          break
      }
    }
  }, [progressChartPersonalData, filterTime, muaMobile])

  useEffect(() => {
    if (comparisonFeelingsData && comparisonFeelingsData.length > 0) {
      const love = comparisonFeelingsData.find((ele) => ele.FeelingId === 1)
      const neutral = comparisonFeelingsData.find((ele) => ele.FeelingId === 2)
      const burn = comparisonFeelingsData.find((ele) => ele.FeelingId === 3)

      setComparisonFeelingsInfo([
        {
          x: 2,
          y: love.Average,
          fill: '#407F7D',
          opacity: 1,
        },
        {
          x: 3,
          y: burn.Average,
          fill: '#407F7D',
          opacity: 1,
        },
        {
          x: 4,
          y: neutral.Average,
          fill: '#407F7D',
          opacity: 1,
        },
        {
          x: 2,
          y: love.Current,
          fill: '#4EA7AC',
          opacity: 0.8,
        },
        {
          x: 3,
          y: burn.Current,
          fill: '#4EA7AC',
          opacity: 0.8,
        },
        {
          x: 4,
          y: neutral.Current,
          fill: '#4EA7AC',
          opacity: 0.8,
        },
      ])
    }
  }, [comparisonFeelingsData])

  useEffect(() => {
    if (isSuccessPersonalFeelings) {
      const newPersonalFeelingsData = personalFeelingsData.filter(
        (ele) => ele.Id !== 2
      )
      const neutral = personalFeelingsData.find((ele) => ele.Id === 2)
      newPersonalFeelingsData.push(neutral)

      setInformationFeelings(
        newPersonalFeelingsData.map((ele) => {
          const { Growth, Id, Total, FeelingTags } = ele
          return {
            Id,
            Total,
            Growth,
            Feelings: FeelingTags.map((feel) => ({
              Name: feel.TagName,
              Quantity: feel.Quantity,
              Color: feel.TagColor,
            })),
          }
        })
      )
    }
  }, [isSuccessPersonalFeelings])

  const handleMove = (selDate) => {
    dispatch(
      getRecurringFeelings(
        userId,
        moment(selDate).subtract(2, 'days').format('YYYY-MM-DD'),
        moment(selDate).add(2, 'days').format('YYYY-MM-DD')
      )
    )
  }

  const handleSelectDate = (selDate) => {
    setSelectedDate(selDate)
    dispatch(
      getComparisonFeelings(userId, moment(selDate).format('YYYY-MM-DD'))
    )
  }

  const handleChangeFilterTime = (event, time) => {
    setFilterTime(time)
    dispatch(getPersonalFeelings(userId, time))
    dispatch(getProgressChart(userId, feelingSelected, time))
  }

  const handleFeeling = (name) => {
    setFeelingSelected(name)
    dispatch(getProgressChart(userId, name, filterTime))
  }

  const openDailyJournal = (index) => {
    const sampleDataScatter = progressChartPersonalData.filter(
      (ele) => ele.JournalList !== null
    )
    const daily = sampleDataScatter.find(
      (ele, indx) => index === indx
    ).JournalList

    setDailyJournalInfo(
      daily.map((ele) => ({
        Description: ele.Description,
        Time: ele.Time,
        Date: moment(ele.Time).format('YYYY-MM-DD'),
        Hour: formatHour(ele.Time),
        Feeling: ele.Feeling,
      }))
    )
    setShowDailyJournal(true)
  }

  const handleClose = () => {
    setShowDailyJournal(false)
    setDailyJournalInfo([])
  }

  return (
    <section className={styles.personalAnalytics}>
      <div className={styles.container}>
        {isLoadingPersonalFeelings ? (
          <>
            <div className={styles.wrapperTitle}>
              <div className={styles.title}>
                {`${common?.user?.FirstName} ${common?.user?.LastName}`}
              </div>
              {!muaMobile && (
                <MainTabs
                  value={filterTime}
                  handleChange={handleChangeFilterTime}
                  arrayTabs={Object.keys(filterTimeName).map((ele) => ({
                    name: filterTimeName[ele],
                    value: ele,
                  }))}
                />
              )}
            </div>

            <div className={styles.wrapperSubtitle}>
              <p className={styles.subtitle}>My</p>
              &nbsp;
              <p className={styles.subtitle2}>Analytics</p>
            </div>
            {muaMobile && (
              <div className={styles.tabsResponsive}>
                <MainTabs
                  value={filterTime}
                  handleChange={handleChangeFilterTime}
                  arrayTabs={Object.keys(filterTimeName).map((ele) => ({
                    name: filterTimeName[ele],
                    value: ele,
                  }))}
                />
              </div>
            )}
            <div className={styles.row}>
              <div className={styles.colLeft}>
                <div className={styles.rowTop}>
                  <Skeleton
                    variant="rounded"
                    width={muaMobile ? '100%' : '33%'}
                    height={200}
                  />
                  <Skeleton
                    variant="rounded"
                    width={muaMobile ? '100%' : '33%'}
                    height={200}
                  />
                  <Skeleton
                    variant="rounded"
                    width={muaMobile ? '100%' : '33%'}
                    height={200}
                  />
                </div>
                <Skeleton variant="rounded" width={'100%'} height={290} />
              </div>
              <div className={styles.colRight}>
                <Skeleton variant="rounded" width={'100%'} height={512} />
              </div>
            </div>
          </>
        ) : (
          <>
            <Modal open={showDailyJournal} onClose={handleClose}>
              <DailyJournalModal dailyJournalData={dailyJournalInfo} />
            </Modal>
            <div className={styles.wrapperTitle}>
              <div className={styles.title}>
                {`${common?.user?.FirstName} ${common?.user?.LastName}`}
              </div>
              {!muaMobile && (
                <MainTabs
                  value={filterTime}
                  handleChange={handleChangeFilterTime}
                  arrayTabs={Object.keys(filterTimeName).map((ele) => ({
                    name: filterTimeName[ele],
                    value: ele,
                  }))}
                />
              )}
            </div>

            <div className={styles.wrapperSubtitle}>
              <p className={styles.subtitle}>My</p>
              &nbsp;
              <p className={styles.subtitle2}>Analytics</p>
            </div>
            {muaMobile && (
              <div className={styles.tabsResponsive}>
                <MainTabs
                  value={filterTime}
                  handleChange={handleChangeFilterTime}
                  arrayTabs={Object.keys(filterTimeName).map((ele) => ({
                    name: filterTimeName[ele],
                    value: ele,
                  }))}
                />
              </div>
            )}
            <div className={styles.row}>
              <div className={styles.colLeft}>
                <div className={styles.rowTop}>
                  {informationFeelings?.length > 0 &&
                    informationFeelings.map((ele, indx) => (
                      <div key={indx} className={styles.colLeftTop}>
                        <SummaryFeelings
                          totalFeeling={ele.Total}
                          feeling={ele.Id}
                          arrayFeelings={ele.Feelings}
                          percentage={ele.Growth}
                          filter={filterTime}
                          filterTimeName={filterTimeName}
                          muaMobile={muaMobile}
                        />
                      </div>
                    ))}
                </div>
                <div className={styles.colLeftBottom}>
                  {muaMobile ? (
                    <ProgressChartResponsive
                      handleFeeling={handleFeeling}
                      feelingSelected={feelingSelected}
                      filterTime={filterTime}
                      sampleData={progressChartInfo}
                      sampleDataScatter={progressChartDaily}
                      openDailyJournal={openDailyJournal}
                    />
                  ) : (
                    <ProgressChart
                      handleFeeling={handleFeeling}
                      feelingSelected={feelingSelected}
                      filterTime={filterTime}
                      sampleData={progressChartInfo}
                      sampleDataScatter={progressChartDaily}
                      openDailyJournal={openDailyJournal}
                    />
                  )}
                </div>
              </div>
              <FeelingsPerDay
                sampleData={comparisonFeelingsInfo}
                module={'personal'}
                recurringFeelingsData={recurringFeelingsData}
                handleMove={handleMove}
                selectedDate={selectedDate}
                handleSelectDate={handleSelectDate}
                muaMobile={muaMobile}
              />
            </div>
          </>
        )}
      </div>
    </section>
  )
}
