import React from 'react'
import { Box, Divider, Paper } from '@mui/material'
import styles from './ProgressChart.module.scss'
import moment from 'moment/moment'
import ReactHtmlParser from 'react-html-parser'
import _ from 'lodash'
import globals from '../../constants/global.constant'

const DailyJournalModal = ({ dailyJournalData }) => {
  const getLimitText = (txt) => {
    if ([...txt].length > 55) {
      return `${txt.substring(0, 55)}...`
    }
    return txt
  }

  const dailyJournal = _.groupBy(
    _.orderBy(dailyJournalData, ['Time'], ['asc']),
    'Date'
  )

  const handleFeeling = (feeling) => {
    switch (feeling) {
      case 'Burn':
        return 'burn'
      case 'Love':
        return 'love'
      case 'Neutral':
        return 'neutral'
      default:
        break
    }
  }

  return (
    <div className={styles.modalCard}>
      <Box className={styles.modalBox}>
        {Object.entries(dailyJournal).map((ele, index) => (
          <>
            <div key={index} className={styles.date}>
              {moment(ele[0]).format('MMM Do')}
              {ele[1][0].Feeling !== 0 && (
                <img
                  src={`${globals.logosBasePath}${handleFeeling(
                    ele[1][0].Feeling
                  )}.svg`}
                  alt="feeling"
                />
              )}
            </div>
            <Divider style={{ borderColor: '#1A3127' }} />
            {ele[1].map((elem, indx) => (
              <>
                <div key={indx} className={styles.wrapperText}>
                  <div className={styles.text}>{`${elem.Hour}:`}</div>
                  <Paper className={styles.text}>
                    {elem.Description &&
                      ReactHtmlParser(getLimitText(elem.Description))}
                  </Paper>
                </div>
                <Divider style={{ borderColor: '#1A3127' }} />
              </>
            ))}
          </>
        ))}
      </Box>
    </div>
  )
}

export default DailyJournalModal
