/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './SetPassword.module.scss'
import { Button, CircularProgress, TextField, Modal } from '@mui/material'
import { ModalMessage } from '../../shared/components/ModalMessage/ModalMessage'
import { validateSetPassword } from '../../shared/constants/patterns'
import {
  reset,
  setPassword,
  resetPassword,
} from '../../core/store/password/password'
import { useMediaQuery } from 'react-responsive'
import globals from '../../shared/constants/global.constant'

export const SetPassword = () => {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })

  const dispatch = useDispatch()
  const { password } = useSelector((state) => state)
  const { isSuccess, isFail, isLoading } = password
  const navigate = useNavigate()
  const { search } = useLocation()
  const hash = new URLSearchParams(search).get('hash')
  const resetFlag = new URLSearchParams(search).get('reset')
  const firstName = new URLSearchParams(search).get('firstName')
  const lastName = new URLSearchParams(search).get('lastName')
  const companyName = new URLSearchParams(search).get('companyName')
  const [forceUpdate, setForceUpdate] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFail, setShowFail] = useState(false)
  const [passwordForm, setPasswordForm] = useState({
    Password: '',
    ConfirmPassword: '',
  })
  const [noMatch, setNoMatch] = useState(false)
  const [errorForm, setErrorForm] = useState({
    Password: {
      error: false,
    },
    ConfirmPassword: {
      error: false,
    },
  })
  const [modalInfo, setModalInfo] = useState({
    title: '',
    message: '',
    button: '',
  })

  const [error, setError] = useState(false)

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: true })
    window.Intercom && window.Intercom('hide')
  }, [])

  useEffect(() => {
    if (Object.values(errorForm).length > 0) {
      setError(
        Object.values(errorForm)
          .map((ele) => ele.error)
          .some((elem) => elem)
      )
      setForceUpdate(!forceUpdate)
    }
  }, [errorForm])

  useEffect(() => {
    if (isSuccess) {
      setShowSuccess(true)
      setModalInfo({
        title: 'One Last Step',
        message:
          'Your password is set. Use your email address and newly set password to log in.',
        button: 'Log In',
      })
    }

    if (isFail) {
      setShowFail(true)
      setModalInfo({
        title: 'Password set failed!',
        message: 'The user already has a password set',
        button: 'Log In',
      })
    }
  }, [isSuccess, isFail, navigate])

  const handleChange = (event) => {
    const { value, id } = event.target
    const newErrorForm = errorForm
    newErrorForm[id].error = false
    setErrorForm(newErrorForm)

    const newPasswordForm = passwordForm
    newPasswordForm[id] = value
    setPasswordForm(newPasswordForm)
    setForceUpdate(!forceUpdate)
  }

  const handleSetPassword = () => {
    const validation = validateSetPassword.validate(passwordForm, {
      abortEarly: false,
    })

    if (passwordForm.Password !== passwordForm.ConfirmPassword) {
      setNoMatch(true)
    } else if (validation.error) {
      setNoMatch(false)
      const newErrorForm = errorForm
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true
      })
      setError(true)
      setErrorForm(newErrorForm)
      setForceUpdate(!forceUpdate)
    } else {
      setNoMatch(false)
      setError(false)
      if (resetFlag === 'true') {
        dispatch(resetPassword({ Password: passwordForm.Password, hash }))
      } else {
        dispatch(setPassword({ Password: passwordForm.Password, hash }))
      }

      setPasswordForm({ Password: '', ConfirmPassword: '' })
      setForceUpdate(!forceUpdate)
    }
  }

  const handleClose = () => {
    setShowSuccess(false)
    setShowFail(false)
    dispatch(reset())
    navigate('/')
  }

  return (
    <section className={styles.Home}>
      <div>
        <Modal open={showSuccess || showFail} onClose={handleClose}>
          <ModalMessage handleClose={handleClose} modalInfo={modalInfo} />
        </Modal>
      </div>
      <div className={styles.logo}>
        <img
          alt="logo"
          src={`${globals.logosBasePath}new-logo-white.png`}
          width="94px"
          height="43px"
        />
      </div>
      <div className={styles.header}>
        <div className={styles.welcomeTitle}>
          {`Hey ${firstName} ${lastName}, ${companyName} invited you to join them on MUA`}
        </div>
        <div className={styles.helperTitle}>
          Please, create your password and you're all set
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.wrapperSetPassword}>
          <h3 className={styles.title}>
            For your security, we require passwords be at least 8 characters,
            and include at least one lowercase letter, one number, one capital
            letter and one special character
          </h3>
          <TextField
            autoComplete="off"
            id="Password"
            onChange={handleChange}
            placeholder="Please enter your password"
            type="password"
            className={styles.customTextField}
            InputProps={{
              sx: {
                color: '#1a3127',
              },
            }}
            sx={{ marginBottom: '26px', width: muaMobile ? '265px' : '340px' }}
          />
          <TextField
            autoComplete="off"
            id="ConfirmPassword"
            onChange={handleChange}
            placeholder="Please confirm your password"
            type="password"
            className={styles.customTextField}
            InputProps={{
              sx: {
                color: '#1a3127',
              },
            }}
            sx={{ marginBottom: '26px', width: muaMobile ? '265px' : '340px' }}
          />
          {isLoading ? (
            <div className={styles.progress}>
              <CircularProgress size="20px" color="light" />
            </div>
          ) : (
            <>
              <div className={styles.createButtonWrapper}>
                <Button
                  className={styles.createButton}
                  color="light"
                  variant="contained"
                  onClick={handleSetPassword}
                >
                  <p>Set password</p>
                </Button>
              </div>
            </>
          )}
          {noMatch && (
            <div className={styles.errorMessage}>
              <h4>Passwords don't match.</h4>
            </div>
          )}

          {error && !noMatch && (
            <div className={styles.errorMessage}>
              <h4>
                Your password must be at least eight characters long, and
                include at least one lowercase letter, one number, one capital
                letter and one special character.
              </h4>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
