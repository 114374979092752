/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Input,
  IconButton,
  Button,
  Box,
  Modal,
  Grid,
  Tooltip,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import styles from './Teams.module.scss'
import globals from '../../shared/constants/global.constant'
import { ModalInput } from '../../shared/components/ModalInput/ModalInput'
import { createTeam, getTeams, updateTeam } from '../../core/store/teams/teams'
import {
  validateAddMember,
  validateName,
} from '../../shared/constants/patterns'
import { getUserInfo } from '../../shared/constants/utilities'
import { UsersTeamsModal } from '../../shared/components/UsersTeamsModal/UsersTeamsModal'
import { addUser, reset } from '../../core/store/userTeam/userTeam'
import { getUser } from '../../core/store/users/users'
import { ModalMessage } from '../../shared/components/ModalMessage/ModalMessage'
import { getCompanies } from '../../core/store/companies/companies'
import _ from 'lodash'
import { getRoles } from '../../core/store/role/role'

export const Teams = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { teams, common, users, userTeam } = useSelector((state) => state)
  const {
    teamsList,
    isSuccessTeams,
    isSuccessUpdateTeam,
    isSuccessNewTeam,
    isLoadingNewTeam,
    isLoadingUpdateTeam,
  } = teams
  const { company } = common
  const { usersResult } = users
  const { isSuccessCreate, isLoadingCreate, error } = userTeam
  const { role } = useSelector((state) => state)
  const { rolesList } = role
  const [forceUpdate, setForceUpdate] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [showFail, setShowFail] = useState(false)
  const [showAddMember, setShowAddMember] = useState(false)
  const [title, setTitle] = useState('')
  const [teamName, setTeamName] = useState('')
  const [teamNameError, setTeamNameError] = useState(false)
  const [isPrivateTeam, setIsPrivateTeam] = useState(false)
  const [teamsData, setTeamsData] = useState([])
  const [prevTeamsData, setPrevTeamsData] = useState([])
  const [teamId, setTeamId] = useState('')
  const [searchBy, setSearchBy] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [autocompleteValue, setAutocompleteValue] = useState({
    Email: '',
    UserId: '',
  })
  const [UserId, setUserId] = useState('')
  const [memberData, setMemberData] = useState({
    Email: '',
    RoleId: '',
  })
  const [memberDataError, setMemberDataError] = useState({
    Email: {
      error: false,
      message: '',
    },
    RoleId: {
      error: false,
      message: '',
    },
  })
  const [modalInfo, setModalInfo] = useState({
    title: '',
    message: '',
    button: '',
  })
  const [confirmSwitch, setConfirmSwitch] = useState(false)

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: true })
    window.Intercom && window.Intercom('hide')
  }, [])

  useEffect(() => {
    if (getUserInfo().role !== 'Administrator') {
      navigate('/')
    }
    dispatch(getCompanies())
  }, [])

  useEffect(() => {
    dispatch(getRoles())
  }, [dispatch])

  useEffect(() => {
    if (rolesList.length > 0) {
      if (isPrivateTeam) {
        setMemberData({...memberData, RoleId: rolesList.find((ele) => ele.Name === "Employee").Id })
      }      
    }
  }, [rolesList, isPrivateTeam])

  useEffect(() => {
    if (usersResult.length > 0) {
      setSearchResult(
        usersResult.map((ele) => ({
          Email: ele.Email,
          UserId: ele.Id,
        }))
      )
    }
  }, [usersResult])

  useEffect(() => {
    if (company || (company && isSuccessCreate)) {
      dispatch(getTeams(company))
    }
  }, [company, isSuccessCreate])

  useEffect(() => {
    if (isSuccessTeams) {
      setTeamsData(
        teamsList &&
          _.sortBy(
            teamsList.map((ele) => ({
              employees: ele.Employees,
              teamName: ele.Name,
              number: ele.NumberOfEmployees,
              id: ele.Id,
              teamColor: ele.TeamColor,
              isPrivateTeam: ele.IsPrivateTeam
            })),
            [(ele) => ele.teamName !== 'General']
          )
      );
      setPrevTeamsData(
        teamsList &&
          _.sortBy(
            teamsList.map((ele) => ({
              employees: ele.Employees,
              teamName: ele.Name,
              number: ele.NumberOfEmployees,
              id: ele.Id,
              teamColor: ele.TeamColor,
            })),
            [(ele) => ele.teamName !== 'General']
          )
      )
    }
  }, [isSuccessTeams, teamsList])

  useEffect(() => {
    if (isSuccessUpdateTeam || isSuccessNewTeam) {
      dispatch(getTeams(company))
      setShowCreate(false)
      setShowUpdate(false)
      setTeamName('')
      setIsPrivateTeam(false)
    }
  }, [isSuccessUpdateTeam, isSuccessNewTeam])

  useEffect(() => {
    if (isSuccessCreate) {
      setShowAddMember(false)
      setSearchResult([])
      setAutocompleteValue({
        Email: '',
        UserId: '',
      })
      setMemberData({
        Email: '',
        RoleId: '',
      })
    }
  }, [isSuccessCreate])

  useEffect(() => {
    if (error && error.length > 0) {
      if (error[0].Message === 'UserId is required') {
        setShowFail(true)
        setModalInfo({
          title: 'The user entered does not belong to your company',
          message: 'Please invite this user to add him to a team',
          button: 'Confirm',
        })
      } else if (error[0].Message.includes('already exists in team with')) {
        setShowFail(true)
        setModalInfo({
          title: 'The entered user already belongs to the company',
          message: 'Please enter a new user',
          button: 'Confirm',
        })
      }
    }
  }, [error])

  const handleChangeSearch = (event) => {
    const { value } = event.target
    setSearchBy(value)
  }

  const handleSearch = () => {
    if (searchBy) {
      setTeamsData(
        prevTeamsData.filter((ele) =>
          ele.teamName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              searchBy
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            )
        )
      )
    } else {
      setTeamsData(
        teamsList &&
          _.sortBy(
            teamsList.map((ele) => ({
              employees: ele.Employees,
              teamName: ele.Name,
              number: ele.NumberOfEmployees,
              id: ele.Id,
              teamColor: ele.TeamColor,
              isPrivateTeam: ele.IsPrivateTeam
            })),
            [(ele) => ele.teamName !== 'General']
          )
      )
    }
  }

  const handleOnChange = (event) => {
    const { value } = event.target
    setTeamName(value)

    setTeamNameError(false)
    setForceUpdate(!forceUpdate)
  }

  const handleOnBlur = (event) => {
    const { value } = event.target
    if (value === '') {
      setTeamNameError(true)
      setForceUpdate(!forceUpdate)
    } else {
      setTeamNameError(false)
      setForceUpdate(!forceUpdate)
    }
  }

  const handleClose = () => {
    setShowCreate(false)
    setShowUpdate(false)
    setShowAddMember(false)
    setShowFail(false)
    setTeamName('')
    setIsPrivateTeam(false)
    setMemberData({
      Email: '',
      RoleId: '',
    })
    setSearchResult([])
    setAutocompleteValue({
      Email: '',
      UserId: '',
    })
    setTeamNameError(false)
    dispatch(reset())
    setForceUpdate(!forceUpdate)
  }

  const handleConfirm = () => {
    const validation = validateName['Name'].validate(teamName).error

    if (showCreate) {
      if (validation) {
        setTeamNameError(true)
      } else {
        dispatch(createTeam(company, teamName, isPrivateTeam))
      }
    } else {
      if (validation) {
        setTeamNameError(true)
      } else {
        dispatch(updateTeam(company, { Id: teamId, Name: teamName, IsPrivateTeam: isPrivateTeam }))
      }
    }
  }

  const handleCreateTeam = () => {
    setShowCreate(true)
    setTitle('New team')
    setTeamNameError(false)
  }

  const handleUpdateTeam = (id) => {
    const team = teamsData.find((ele) => ele.id === id).teamName
    const isPrivate = teamsData.find((ele) => ele.id === id).isPrivateTeam
    setShowUpdate(true)
    setTitle('Update team')
    setTeamName(team)
    setIsPrivateTeam(isPrivate)
    setTeamId(id)
    setTeamNameError(false)
  }

  const handleAddMember = (id) => {
    const isPrivate = teamsData.find((ele) => ele.id === id).isPrivateTeam
    setShowAddMember(true)
    setTitle('Add member')
    setTeamId(id)
    setIsPrivateTeam(isPrivate)
  }

  const handleChangeAutocomplete = (value) => {
    const { UserId, Email } = value
    setAutocompleteValue(value)
    setUserId(UserId)
    const newMemberData = memberData
    newMemberData.Email = Email
    setMemberData(newMemberData)
    setForceUpdate(!forceUpdate)
    setForceUpdate(!setForceUpdate)
  }

  const handleChange = (event) => {
    const { value } = event.target
    if (value.length >= 3) {
      dispatch(getUser({ company, filter: value }))
    }
    const newMemberData = memberData
    newMemberData.Email = value
    setMemberData(newMemberData)
    setForceUpdate(!forceUpdate)
  }

  const handleSelect = (event) => {
    const { value } = event.target
    const newMemberData = memberData
    newMemberData.RoleId = value
    setMemberData(newMemberData)
    setForceUpdate(!forceUpdate)
  }

  const handleConfirmMember = () => {
    const validation = validateAddMember.validate(memberData, {
      abortEarly: false,
    })

    const newMemberDataError = memberDataError
    if (validation.error) {
      validation.error.details.forEach((ele) => {
        newMemberDataError[ele.context.label].error = true
        newMemberDataError[ele.context.label].message = 'Required fields'
        setMemberDataError(newMemberDataError)
        setForceUpdate(!forceUpdate)
      })
    } else {
      dispatch(addUser(teamId, { ...memberData, UserId }))
    }
  }

  const handleGoUsers = (id) => {
    const name = teamsData.find((ele) => ele.id === id).teamName
    const color = teamsData.find((ele) => ele.id === id).teamColor.slice(1)
    const isPrivate = teamsData.find((ele) => ele.id === id).isPrivateTeam

    navigate(`/teams/${id}?teamName=${name}&color=${color}&isPrivateTeam=${isPrivate}`)
  }

  const handleSwitch = (event) => {
    const { checked } = event.target

    setConfirmSwitch(true)
    setModalInfo({
      title: checked ? 'Are you sure you want to make this team private?' : 'Are you sure you want to make this team public?',
      message: '',
      button: 'Confirm',
      cancelButton: 'Cancel'
    })
  }

  const handleConfirmSwitch = () => {
    setIsPrivateTeam(!isPrivateTeam)
    setConfirmSwitch(false)
  }

  const handleCloseConfirm = () => {
    setConfirmSwitch(false)
  }

  return (
    <section className={styles.teams}>
      <div>
        <Modal open={showFail} onClose={handleClose}>
          <ModalMessage
            handleClose={handleClose}
            modalInfo={modalInfo}
            module={'teams'}
          />
        </Modal>
        <Modal open={confirmSwitch} onClose={handleCloseConfirm}>
          <ModalMessage
            handleClose={handleConfirmSwitch}
            modalInfo={modalInfo}
            module={'teams'}
            handleCancel={handleCloseConfirm}
          />
        </Modal>
      </div>
      <div>
        <Modal open={showCreate || showUpdate} onClose={handleClose}>
          <ModalInput
            id={'Name'}
            title={title}
            handleOnChange={handleOnChange}
            handleOnBlur={handleOnBlur}
            placeholder={'Team name'}
            value={teamName}
            error={teamNameError}
            handleCancel={handleClose}
            handleConfirm={handleConfirm}
            isLoadingCreate={isLoadingNewTeam}
            isLoadingUpdate={isLoadingUpdateTeam}
            handleSwitch={handleSwitch}
            isPrivateTeam={isPrivateTeam}
            source={"team"}
          />
        </Modal>
      </div>
      <div>
        <Modal open={showAddMember} onClose={handleClose}>
          <UsersTeamsModal
            title={title}
            handleOnChange={handleChange}
            value={memberData}
            handleCancel={handleClose}
            handleConfirm={handleConfirmMember}
            isLoading={isLoadingCreate}
            handleSelect={handleSelect}
            options={searchResult}
            handleChangeAutocomplete={handleChangeAutocomplete}
            autocompleteValue={autocompleteValue}
            isPrivateTeam={isPrivateTeam}
          />
        </Modal>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Box>
            <Input
              disableUnderline
              className={styles.input}
              placeholder="Search"
              onChange={handleChangeSearch}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch()
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <img
                      src={`${globals.logosBasePath}search.svg`}
                      alt="search"
                      width={'12px'}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <div className={styles.wrapperButtons}>
            <Button
              onClick={handleCreateTeam}
              className={styles.newTeamButton}
              disabled={company === null}
            >
              <p>Create new team</p>
            </Button>
            <Tooltip title="Please select a company to create a new team">
              <img src={`${globals.logosBasePath}help.svg`} alt="help" />
            </Tooltip>
          </div>
        </div>
        <div className={styles.cardsWrapper}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {teamsData.map((ele, indx) => (
              <Grid item xs={2} sm={4} md={4} key={indx}>
                <div className={styles.card}>
                  <div className={styles.number}>{`${ele.number} Members`}</div>
                  <div className={styles.wrapperName}>
                    <div style={{ display: "flex", flexDirection: "row" , gap: "5px", alignItems: "center" }}>
                      <div
                        className={styles.name}
                        style={{ background: ele.teamColor }}
                      >
                        {ele.teamName}
                      </div>
                      {ele.isPrivateTeam && (
                        <img
                          src={`${globals.logosBasePath}private-icon-mua.svg`}
                          alt="private"
                          width={"20px"}
                        />
                      )}
                    </div>
                    <IconButton onClick={() => handleUpdateTeam(ele.id)}>
                      <img
                        src={`${globals.logosBasePath}edit.svg`}
                        alt="edit"
                      />
                    </IconButton>
                  </div>
                  <div>
                    <Button
                      variant="text"
                      className={styles.newMemberButton}
                      onClick={() => handleAddMember(ele.id)}
                    >
                      <p>+Add New Member</p>
                    </Button>
                  </div>
                  <div className={styles.wrapperEmployees}>
                    {ele.employees.length < 5 &&
                      ele.employees.map((elem, index) => (
                        <div key={index} className={styles.employees}>
                          <img
                            src={`${globals.logosBasePath}user.svg`}
                            alt="user"
                          />
                          <div className={styles.employeesName}>
                            {`${elem.FirstName} ${elem.LastName}`.length > 20
                              ? `${`${elem.FirstName} ${elem.LastName}`.slice(
                                  0,
                                  20
                                )}...`
                              : `${elem.FirstName} ${elem.LastName}`}
                            <div className={styles.email}>{elem.Email}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {ele.number > 0 && (
                    <Button
                      variant="text"
                      className={styles.showMoreButton}
                      onClick={() => handleGoUsers(ele.id)}
                    >
                      <p>Show more...</p>
                    </Button>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </section>
  )
}
