import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Input,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material'
import styles from '../Styles/Modal.module.scss'
import { getRoles } from '../../../core/store/role/role'

export const UsersModal = ({
  title,
  handleOnChange,
  handleOnBlur,
  value,
  error,
  handleCancel,
  handleConfirm,
  handleSelect,
  isLoadingCreate,
  isLoadingUpdate,
}) => {
  const dispatch = useDispatch()
  const { role } = useSelector((state) => state)
  const { rolesList } = role
  const [rolesData, setRolesData] = useState([])
  const { FirstName, LastName, Email, RoleId } = value

  useEffect(() => {
    dispatch(getRoles())
  }, [dispatch])

  useEffect(() => {
    if (rolesList.length > 0) {
      setRolesData(
        rolesList.filter(item => item.Name !== "Lead" && item.Name !== "Administrator")
        .map(item => ({
            RoleId: item.Id,
            RoleName: item.Name
        }))
      )
    }
  }, [rolesList])

  return (
    <div>
      <Box className={styles.modalBox}>
        <div className={styles.modalBody}>
          <div className={styles.modalTitle} style={{ marginBottom: '30px' }}>
            {title}
          </div>
          <div>
            <Input
              disableUnderline
              id={'FirstName'}
              className={styles.modalInput}
              placeholder={'Name'}
              value={FirstName}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              error={error.FirstName.error}
              maxRows={100}
            />
          </div>
          <div>
            <Input
              disableUnderline
              id={'LastName'}
              className={styles.modalInput}
              placeholder={'Last name'}
              value={LastName}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              error={error.LastName.error}
              maxRows={100}
            />
          </div>
          <div>
            <Input
              disableUnderline
              id={'Email'}
              className={styles.modalInput}
              placeholder={'Email'}
              value={Email}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              error={error.Email.error}
              maxRows={100}
              disabled={title === 'Update member'}
            />
          </div>
          {title === 'Update member' && (
            <div>
              <div
                className={styles.modalTitle}
                style={{ margin: '15px 0 10px 0' }}
              >
                Permission
              </div>
              <div className={styles.wrapperSelect}>
                <Select
                  value={RoleId}
                  onChange={handleSelect}
                  displayEmpty
                  className={styles.select}
                  renderValue={(selected) => {
                    if (selected === '') {
                      return (
                        <p className={styles.placeholder}>Select permission</p>
                      )
                    } else {
                      return rolesData.find((ele) => ele.RoleId === selected)
                        ? rolesData.find((ele) => ele.RoleId === selected)
                            .RoleName
                        : null
                    }
                  }}
                >
                  <MenuItem disabled value="">
                    <p className={styles.placeholder}>Select permission</p>
                  </MenuItem>
                  {rolesData.map((ele) => (
                    <MenuItem value={ele.RoleId}>{ele.RoleName}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          )}
          <div className={styles.modalButtons} style={{ marginTop: '30px' }}>
            {isLoadingCreate || isLoadingUpdate ? (
              <CircularProgress size="20px" color="primary" />
            ) : (
              <>
                <div>
                  <Button onClick={handleCancel}>
                    <p>Cancel</p>
                  </Button>
                </div>
                <div>
                  <Button onClick={handleConfirm}>
                    <p>Confirm</p>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Box>
    </div>
  )
}

UsersModal.propTypes = {
  title: PropTypes.string,
  value: PropTypes.object,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  handleSelect: PropTypes.func,
  error: PropTypes.object,
  isLoadingCreate: PropTypes.bool,
  isLoadingUpdate: PropTypes.bool,
}
