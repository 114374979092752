/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from '@mui/material'
import styles from '../Styles/Modal.module.scss'
import { getRoles } from '../../../core/store/role/role'

export const UsersTeamsModal = ({
  title,
  handleOnChange,
  value,
  handleCancel,
  handleConfirm,
  isLoading,
  handleSelect,
  options,
  handleChangeAutocomplete,
  autocompleteValue,
  isPrivateTeam
}) => {
  const dispatch = useDispatch()
  const { role } = useSelector((state) => state)
  const { rolesList } = role
  const [rolesData, setRolesData] = useState([])
  const { Email, RoleId } = value

  useEffect(() => {
    dispatch(getRoles())
  }, [dispatch])

  useEffect(() => {
    if (rolesList.length > 0) {
      setRolesData(
        rolesList.map(
          (ele) =>
            ele.Name !== 'Administrator' &&
            ele.Name !== 'Owner' && {
              RoleName: ele.Name,
              RoleId: ele.Id,
              disabled: isPrivateTeam && ele.Name === 'Lead'
            }
        )
      )
    }
  }, [rolesList])

  return (
    <div>
      <Box className={styles.modalBox}>
        <div className={styles.modalBody}>
          <div className={styles.modalTitle}>{title}</div>
          <div>
            <Autocomplete
              freeSolo
              noOptionsText="No options"
              disabled={title === 'Update member'}
              id={'Email'}
              onChange={(event, value) => handleChangeAutocomplete(value)}
              getOptionLabel={(option) => `${option.Email}`}
              defaultValue={autocompleteValue}
              disableClearable
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={Email}
                  className={styles.textField}
                  onChange={handleOnChange}
                  placeholder={'Email'}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </div>
          <div>
            <div
              className={styles.modalTitle}
              style={{ margin: '20px 0 10px 0' }}
            >
              Select permission
            </div>
            <div className={styles.wrapperSelect}>
              <Select
                value={RoleId}
                onChange={handleSelect}
                displayEmpty
                className={styles.select}
                renderValue={(selected) => {
                  if (selected === '') {
                    return (
                      <p className={styles.placeholder}>Select permission</p>
                    )
                  } else {
                    return rolesData.find((ele) => ele.RoleId === selected)
                      ? rolesData.find((ele) => ele.RoleId === selected)
                          .RoleName
                      : null
                  }
                }}
              >
                <MenuItem disabled value="">
                  <p className={styles.placeholder}>Select permission</p>
                </MenuItem>
                {rolesData.map((ele, index) => (
                  <MenuItem key={index} value={ele.RoleId} disabled={ele.disabled}>
                    {ele.RoleName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className={styles.modalButtons}>
            {isLoading ? (
              <CircularProgress size="20px" color="primary" />
            ) : (
              <>
                <div>
                  <Button onClick={handleCancel}>
                    <p>Cancel</p>
                  </Button>
                </div>
                <div>
                  <Button onClick={handleConfirm}>
                    <p>Confirm</p>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Box>
    </div>
  )
}

UsersTeamsModal.propTypes = {
  title: PropTypes.string,
  value: PropTypes.object,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingUpdate: PropTypes.bool,
  handleSelect: PropTypes.func,
  options: PropTypes.array,
  handleChangeAutocomplete: PropTypes.func,
  autocompleteValue: PropTypes.object,
}
