/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { TableApp } from '../../shared/components/Table/Table'
import { Input, IconButton, Button, Box, Modal, Tooltip } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import styles from '../Companies/Companies.module.scss'
import ownStyles from './Users.module.scss'
import globals from '../../shared/constants/global.constant'
import { UsersModal } from '../../shared/components/UsersModal/UsersModal'
import {
  createUser,
  createUsersInBulk,
  getUsers,
  updateUser,
  reset,
  sendEmail,
} from '../../core/store/users/users'
import { validationUserForm } from '../../shared/constants/patterns'
import { getUserInfo } from '../../shared/constants/utilities'
import { getCompanies } from '../../core/store/companies/companies'
import { isEqual } from 'lodash'
import { UserInfo } from '../../shared/components/UserInfo/UserInfo'

export const Users = () => {
  const dispatch = useDispatch()
  const { users, common } = useSelector((state) => state)
  const {
    usersList,
    isSuccessUsers,
    isLoadingUsers,
    isSuccessUpdateUser,
    isSuccessNewUser,
    isLoadingNewUser,
    isLoadingUpdateUser,
    isSuccessUsersInBulk,
    error,
  } = users
  const { company } = common
  const navigate = useNavigate()
  const [forceUpdate, setForceUpdate] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [showUserInfo, setShowUserInfo] = useState(false)
  const [userSelected, setUserSelected] = useState({})
  const [title, setTitle] = useState('')
  const [userInfo, setUserInfo] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    RoleId: '',
  })
  const [userInfoError, setUserInfoError] = useState({
    FirstName: {
      error: false,
      message: '',
    },
    LastName: {
      error: false,
      message: '',
    },
    Email: {
      error: false,
      message: '',
    },
    RoleId: {
      error: false,
      message: '',
    },
  })
  const [userId, setUserId] = useState('')
  const [usersData, setUsersData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortedField] = useState('CreatedOn')
  const [sortDirection] = useState('Descend')
  const [searchBy, setSearchBy] = useState('')
  const [totalItems, setTotalItems] = useState(10)
  const [selectedUsers, setSelectedUsers] = useState([])

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: true })
    window.Intercom && window.Intercom('hide')
  }, [])

  useEffect(() => {
    if (getUserInfo().role !== 'Administrator') {
      navigate('/')
    }
    dispatch(getCompanies())
    dispatch(reset())
    setForceUpdate(!forceUpdate)
  }, [])

  useEffect(() => {
    if (company) {
      dispatch(
        getUsers({
          companyId: company,
          text: searchBy,
          sortedField,
          sortDirection,
          page: page + 1,
          pageSize: rowsPerPage,
        })
      )
    }
  }, [company])

  useEffect(() => {
    if (isSuccessUsersInBulk) {
      dispatch(
        getUsers({
          companyId: company,
          text: searchBy,
          sortedField,
          sortDirection,
          page: page + 1,
          pageSize: rowsPerPage,
        })
      )
    }
  }, [isSuccessUsersInBulk])

  useEffect(() => {
    if (isSuccessUsers) {
      setUsersData(
        usersList &&
          usersList.UserTeamList.map((ele) => ({
            id: ele.UserId,
            name: ele.FirstName,
            lastName: ele.LastName,
            email: ele.Email,
            role: ele.RoleGeneral,
            roleId: ele.RoleGeneralId,
            status: ele.IsActive,
            teamsRole: ele.TeamsRole,
            team: ele.Team,
          }))
      )
      setTotalItems(usersList.Total)
    }
  }, [isSuccessUsers, usersList])

  const columns = [
    {
      id: 'name',
      label: 'Member',
      minWidth: 150,
      align: 'left',
    },
    {
      id: 'role',
      label: 'Permission',
      minWidth: 150,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 150,
      align: 'center',
    },
  ]

  useEffect(() => {
    if (isSuccessUpdateUser || isSuccessNewUser) {
      dispatch(
        getUsers({
          companyId: company,
          text: searchBy,
          sortedField,
          sortDirection,
          page: page + 1,
          pageSize: rowsPerPage,
        })
      )
      setShowCreate(false)
      setShowUpdate(false)
      setUserInfo({
        FirstName: '',
        LastName: '',
        Email: '',
        RoleId: '',
      })
    }
  }, [isSuccessUpdateUser, isSuccessNewUser])

  const handleChangeSearch = (event) => {
    const { value } = event.target
    setSearchBy(value)
  }

  const handleSearch = () => {
    setPage(0)
    dispatch(
      getUsers({
        companyId: company,
        text: searchBy,
        sortedField,
        sortDirection,
        page: 1,
        pageSize: rowsPerPage,
      })
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    dispatch(
      getUsers({
        companyId: company,
        text: searchBy,
        sortedField,
        sortDirection,
        page: newPage + 1,
        pageSize: rowsPerPage,
      })
    )
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    dispatch(
      getUsers({
        companyId: company,
        text: searchBy,
        sortedField,
        sortDirection,
        page: page + 1,
        pageSize: +event.target.value,
      })
    )
  }

  const handleOnChange = (event) => {
    const { value, id } = event.target
    const newUserInfo = userInfo
    newUserInfo[id] = value
    setUserInfo(newUserInfo)

    const newUserInfoError = userInfoError
    newUserInfoError[id].error = false
    newUserInfoError[id].message = ''
    setUserInfoError(newUserInfoError)
    setForceUpdate(!forceUpdate)
  }

  const handleOnBlur = (event) => {
    const { value, id } = event.target
    const newUserInfoError = userInfoError
    if (value === '') {
      newUserInfoError[id].error = true
      newUserInfoError[id].message = 'Required field'
      setUserInfoError(newUserInfoError)
      setForceUpdate(!forceUpdate)
    } else {
      newUserInfoError[id].error = false
      newUserInfoError[id].message = ''
      setUserInfoError(newUserInfoError)
      setForceUpdate(!forceUpdate)
    }
  }

  const handleClose = () => {
    setShowCreate(false)
    setShowUpdate(false)
    setShowUserInfo(false)
    setUserInfo({
      FirstName: '',
      LastName: '',
      Email: '',
      RoleId: '',
    })
    setUserInfoError({
      FirstName: {
        error: false,
        message: '',
      },
      LastName: {
        error: false,
        message: '',
      },
      Email: {
        error: false,
        message: '',
      },
      RoleId: {
        error: false,
        message: '',
      },
    })
    setForceUpdate(!forceUpdate)
  }

  const handleConfirm = () => {
    const validation = validationUserForm.validate(userInfo, {
      abortEarly: false,
    })

    const status = userId && usersData.find((ele) => ele.id === userId).status

    if (showCreate) {
      const newUserInfoError = userInfoError
      if (validation.error) {
        validation.error.details.forEach((ele) => {
          newUserInfoError[ele.context.label].error = true
          newUserInfoError[ele.context.label].message = 'Required fields'
          setUserInfoError(newUserInfoError)
          setForceUpdate(!forceUpdate)
        })
      } else {
        dispatch(createUser(company, userInfo))
      }
    } else {
      const newUserInfoError = userInfoError
      if (validation.error) {
        validation.error.details.forEach((ele) => {
          newUserInfoError[ele.context.label].error = true
          newUserInfoError[ele.context.label].message = 'Required fields'
          setUserInfoError(newUserInfoError)
          setForceUpdate(!forceUpdate)
        })
      } else {
        dispatch(updateUser({ Id: userId, ...userInfo, IsActive: status }))
      }
    }
  }

  const handleCreateClient = () => {
    setShowCreate(true)
    setTitle('Invite new member')
    setUserInfoError({
      FirstName: {
        error: false,
        message: '',
      },
      LastName: {
        error: false,
        message: '',
      },
      Email: {
        error: false,
        message: '',
      },
    })
  }

  const handleUpdateClient = (id) => {
    const user = usersData.find((ele) => ele.id === id)
    setShowUpdate(true)
    setTitle('Update member')
    setUserInfo({
      FirstName: user.name,
      LastName: user.lastName,
      Email: user.email,
      RoleId: user.roleId,
    })
    setUserId(id)
    setUserInfoError({
      FirstName: {
        error: false,
        message: '',
      },
      LastName: {
        error: false,
        message: '',
      },
      Email: {
        error: false,
        message: '',
      },
      RoleId: {
        error: false,
        message: '',
      },
    })
  }

  const uploadFiles = async (file) => {
    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append('File', file)
    return dispatch(createUsersInBulk(company, formData))
  }

  const handleUploadFile = async (event) => {
    const { files } = event.target
    if (files[0].type !== 'text/csv') {
      toast.error('Invalid file format')
    } else {
      dispatch(reset())
      await uploadFiles(files[0])

      setForceUpdate(!forceUpdate)
    }
  }

  const handleActivate = (event, companyId) => {
    const { checked, id } = event.target
    const newUsersData = usersData
    const item = usersData.find((ele) => ele.id === companyId)
    const itemIndex = usersData.findIndex((ele) => ele.id === companyId)

    item[id] = checked
    newUsersData[itemIndex] = item
    setUsersData(newUsersData)
    dispatch(
      updateUser({
        Id: item.id,
        FirstName: item.name,
        LastName: item.lastName,
        RoleId: item.roleId,
        IsActive: checked,
      })
    )
    setForceUpdate(!forceUpdate)
  }

  const handleView = (id) => {
    setShowUserInfo(true)
    for (let i = 0; i < usersData.length; i++) {
      if (isEqual(usersData[i].id, id)) setUserSelected(usersData[i])
    }
    setForceUpdate(!forceUpdate)
  }

  const handleSelect = (event) => {
    const { value } = event.target
    const newUserInfo = userInfo
    newUserInfo.RoleId = value
    setUserInfo(newUserInfo)
    setForceUpdate(!forceUpdate)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedUsers = usersData.map((n) => n.email);
      setSelectedUsers(newSelectedUsers);
      return;
    }
    setSelectedUsers([]);
  };

  const handleClick = (event, email) => {
    const selectedIndex = selectedUsers.indexOf(email);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, email);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const isSelected = (email) => selectedUsers.indexOf(email) !== -1;

  const handleSend = () => {
    dispatch(sendEmail(company, selectedUsers))
  };

  const handleSendUser = (email) => {
    dispatch(sendEmail(company, [email]))
  };

  return (
    <section className={styles.companies}>
      <div>
        <Modal open={showCreate || showUpdate} onClose={handleClose}>
          <UsersModal
            title={title}
            handleOnChange={handleOnChange}
            handleOnBlur={handleOnBlur}
            value={userInfo}
            error={userInfoError}
            handleCancel={handleClose}
            handleConfirm={handleConfirm}
            handleSelect={handleSelect}
            isLoadingCreate={isLoadingNewUser}
            isLoadingUpdate={isLoadingUpdateUser}
          />
        </Modal>
      </div>
      <div>
        <Modal open={showUserInfo} onClose={handleClose}>
          <UserInfo userInfo={userSelected} />
        </Modal>
      </div>
      <div className={styles.cardList}>
        <div className={styles.header}>
          <Box>
            <Input
              disableUnderline
              className={styles.input}
              placeholder="Search"
              onChange={handleChangeSearch}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch()
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <img
                      src={`${globals.logosBasePath}search.svg`}
                      alt="search"
                      width={'12px'}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <div className={ownStyles.wrapperButtons}>
            <Box className={ownStyles.buttons}>
              <Button
                variant="text"
                component="label"
                className={ownStyles.bulkButton}
                disabled={company === null}
              >
                <p>Invite members in bulk</p>
                <input
                  hidden
                  accept="csv/*"
                  type="file"
                  multiple="multiple"
                  onChange={handleUploadFile}
                />
              </Button>
              <Button
                onClick={handleCreateClient}
                className={styles.newClientButton}
                disabled={company === null}
              >
                <p>Invite member</p>
              </Button>
              <Tooltip title="Please select a company to invite a new member">
                <img src={`${globals.logosBasePath}help.svg`} alt="help" />
              </Tooltip>
            </Box>
            <div className={ownStyles.errorMessage}>
              <h4>{error && error[0].Message}</h4>
            </div>
          </div>
        </div>
        <div className={ownStyles.sendEmail}>
          <Button
            onClick={handleSend}
            disabled={selectedUsers.length === 0}
            className={ownStyles.sendEmailButton}
          >
            <p>Send invite now</p>
          </Button>
        </div>
        <div className={styles.tableWrapper}>
          <TableApp
            handleUpdate={handleUpdateClient}
            handleActivate={handleActivate}
            handleView={handleView}
            dataTable={usersData}
            columns={columns}
            isLoading={isLoadingUsers}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            className={styles.table}
            checkbox={true}
            selectedUsers={selectedUsers}
            handleSelectAllClick={handleSelectAllClick}
            handleClick={handleClick}
            isSelected={isSelected}
            handleSend={handleSendUser}
          />
        </div>
      </div>
    </section>
  )
}
