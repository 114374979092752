import { createSlice } from '@reduxjs/toolkit'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'activities',
  initialState: {
    activitiesData: [],
    journalData: [],
    recurringFeelingsData: [],
    isLoadingActivities: false,
    isSuccessActivities: false,
    isFailActivities: false,
    isLoadingCreateActivity: false,
    isSuccessCreateActivity: false,
    isFailCreateActivity: false,
    isLoadingUpdateActivity: false,
    isSuccessUpdateActivity: false,
    isFailUpdateActivity: false,
    isLoadingGetJournal: false,
    isSuccessGetJournal: false,
    isFailGetJournal: false,
    isLoadingCreateJournal: false,
    isSuccessCreateJournal: false,
    isFailCreateJournal: false,
    isLoadingRecurringFeelings: false,
    isSuccessRecurringFeelings: false,
    isFailRecurringFeelings: false,
    isLoadingDeleteActivity: false,
    isSuccessDeleteActivity: false,
    isFailDeleteActivity: false,
  },
  reducers: {
    getActivitiesRequest: (state) => {
      return {
        ...state,

        isLoadingActivities: true,
        isSuccessActivities: false,
        isFailActivities: false,
      }
    },
    getActivitiesRequestNew: (state, action) => {
      return {
        ...state,
        activitiesData: action.payload,
        isLoadingActivities: true,
        isSuccessActivities: false,
        isFailActivities: false,
      }
    },
    getActivitiesSuccess: (state, action) => {
      return {
        ...state,
        activitiesData: action.payload,
        isLoadingActivities: false,
        isSuccessActivities: true,
        isFailActivities: false,
      }
    },
    getActivitiesFail: (state) => {
      return {
        ...state,
        activitiesData: [],
        isLoadingActivities: false,
        isSuccessActivities: false,
        isFailActivities: true,
      }
    },
    createActivityRequest: (state) => {
      return {
        ...state,
        isLoadingCreateActivity: true,
        isSuccessCreateActivity: false,
        isFailCreateActivity: false,
      }
    },
    createActivitySuccess: (state) => {
      return {
        ...state,
        isLoadingCreateActivity: false,
        isSuccessCreateActivity: true,
        isFailCreateActivity: false,
      }
    },
    createActivityFail: (state) => {
      return {
        ...state,
        isLoadingCreateActivity: false,
        isSuccessCreateActivity: false,
        isFailCreateActivity: true,
      }
    },
    updateActivityRequest: (state) => {
      return {
        ...state,
        isLoadingUpdateActivity: true,
        isSuccessUpdateActivity: false,
        isFailUpdateActivity: false,
      }
    },
    updateActivitySuccess: (state) => {
      return {
        ...state,
        isLoadingUpdateActivity: false,
        isSuccessUpdateActivity: true,
        isFailUpdateActivity: false,
      }
    },
    updateActivityFail: (state) => {
      return {
        ...state,
        isLoadingUpdateActivity: false,
        isSuccessUpdateActivity: false,
        isFailUpdateActivity: true,
      }
    },
    getJournalRequest: (state) => {
      return {
        ...state,
        isLoadingGetJournal: true,
        isSuccessGetJournal: false,
        isFailGetJournal: false,
      }
    },
    getJournalSuccess: (state, action) => {
      return {
        ...state,
        isLoadingGetJournal: false,
        isSuccessGetJournal: true,
        isFailGetJournal: false,
        journalData: action.payload,
      }
    },
    getJournalFail: (state) => {
      return {
        ...state,
        isLoadingGetJournal: false,
        isSuccessGetJournal: false,
        isFailGetJournal: true,
      }
    },
    createJournalRequest: (state) => {
      return {
        ...state,
        isLoadingCreateJournal: true,
        isSuccessCreateJournal: false,
        isFailCreateJournal: false,
      }
    },
    createJournalSuccess: (state) => {
      return {
        ...state,
        isLoadingCreateJournal: false,
        isSuccessCreateJournal: true,
        isFailCreateJournal: false,
      }
    },
    createJournalFail: (state) => {
      return {
        ...state,
        isLoadingCreateJournal: false,
        isSuccessCreateJournal: false,
        isFailCreateJournal: true,
      }
    },
    updateJournalRequest: (state) => {
      return {
        ...state,
        isLoadingUpdateJournal: true,
        isSuccessUpdateJournal: false,
        isFailUpdateJournal: false,
      }
    },
    updateJournalSuccess: (state) => {
      return {
        ...state,
        isLoadingUpdateJournal: false,
        isSuccessUpdateJournal: true,
        isFailUpdateJournal: false,
      }
    },
    updateJournalFail: (state) => {
      return {
        ...state,
        isLoadingUpdateJournal: false,
        isSuccessUpdateJournal: false,
        isFailUpdateJournal: true,
      }
    },
    deleteJournalRequest: (state) => {
      return {
        ...state,
        isLoadingDeleteJournal: true,
        isSuccessDeleteJournal: false,
        isFailDeleteJournal: false,
      }
    },
    deleteJournalSuccess: (state) => {
      return {
        ...state,
        isLoadingDeleteJournal: false,
        isSuccessDeleteJournal: true,
        isFailDeleteJournal: false,
      }
    },
    deleteJournalFail: (state) => {
      return {
        ...state,
        isLoadingDeleteJournal: false,
        isSuccessDeleteJournal: false,
        isFailDeleteJournal: true,
      }
    },
    getRecurringFeelingsRequest: (state) => {
      return {
        ...state,
        isLoadingRecurringFeelings: true,
        isSuccessRecurringFeelings: false,
        isFailRecurringFeelings: false,
      }
    },
    getRecurringFeelingsSuccess: (state, action) => {
      return {
        ...state,
        recurringFeelingsData: action.payload,
        isLoadingRecurringFeelings: false,
        isSuccessRecurringFeelings: true,
        isFailRecurringFeelings: false,
      }
    },
    getRecurringFeelingsFail: (state) => {
      return {
        ...state,
        isLoadingRecurringFeelings: false,
        isSuccessRecurringFeelings: false,
        isFailRecurringFeelings: true,
      }
    },
    deleteActivityRequest: (state) => {
      return {
        ...state,
        isLoadingDeleteActivity: true,
        isSuccessDeleteActivity: false,
        isFailDeleteActivity: false,
      }
    },
    deleteActivitySuccess: (state) => {
      return {
        ...state,
        isLoadingDeleteActivity: false,
        isSuccessDeleteActivity: true,
        isFailDeleteActivity: false,
      }
    },
    deleteActivityFail: (state) => {
      return {
        ...state,
        isLoadingDeleteActivity: false,
        isSuccessDeleteActivity: false,
        isFailDeleteActivity: true,
      }
    },
  },
})

export default slice.reducer

export const {
  getActivitiesRequest,
  getActivitiesRequestNew,
  getActivitiesSuccess,
  getActivitiesFail,
  createActivityRequest,
  createActivitySuccess,
  createActivityFail,
  updateActivityRequest,
  updateActivitySuccess,
  updateActivityFail,
  createJournalRequest,
  createJournalSuccess,
  createJournalFail,
  updateJournalRequest,
  updateJournalSuccess,
  updateJournalFail,
  deleteJournalRequest,
  deleteJournalSuccess,
  deleteJournalFail,
  getJournalRequest,
  getJournalSuccess,
  getJournalFail,
  getRecurringFeelingsRequest,
  getRecurringFeelingsSuccess,
  getRecurringFeelingsFail,
  deleteActivityRequest,
  deleteActivitySuccess,
  deleteActivityFail,
} = slice.actions

export const getActivities =
  (userId, tab, date, change) => async (dispatch) => {
    if (change) {
      dispatch(getActivitiesRequestNew([]))
    } else {
      dispatch(getActivitiesRequest())
    }

    try {
      const res = await axiosClient.get(
        `/Activity/GetByUser/${userId}?dailyActivities=${tab}&Date=${date}`
      )
      dispatch(getActivitiesSuccess(res.data))
    } catch {
      dispatch(getActivitiesFail())
    }
  }

export const createActivity = (userId, activityInfo) => async (dispatch) => {
  dispatch(createActivityRequest())
  try {
    await axiosClient.post(`/Activity/create/${userId}`, { ...activityInfo })
    dispatch(createActivitySuccess())
  } catch {
    dispatch(createActivityFail())
  }
}

export const updateActivity =
  (activityId, activityInfo) => async (dispatch) => {
    dispatch(updateActivityRequest())
    try {
      await axiosClient.put(`/Activity/update/${activityId}`, {
        ...activityInfo,
      })
      dispatch(updateActivitySuccess())
    } catch {
      dispatch(updateActivityFail())
    }
  }

export const getDailyJournal = (userId, date) => async (dispatch) => {
  dispatch(getJournalRequest())
  try {
    const res = await axiosClient.get(
      `/Journal/getJournalsByDate/${userId}?Time=${date}`
    )
    dispatch(getJournalSuccess(res.data))
  } catch {
    dispatch(getJournalFail())
  }
}

export const saveDailyJournal = (userId, data) => async (dispatch) => {
  dispatch(createJournalRequest())
  try {
    await axiosClient.post(`/Journal/create/${userId}`, data)
    dispatch(createJournalSuccess())
  } catch {
    dispatch(createJournalFail())
  }
}

export const updateDailyJournal = (data) => async (dispatch) => {
  dispatch(updateJournalRequest())
  try {
    await axiosClient.put(`/Journal/update`, data)
    dispatch(updateJournalSuccess())
  } catch {
    dispatch(updateJournalFail())
  }
}

export const deleteDailyJournal = (journalId) => async (dispatch) => {
  dispatch(deleteJournalRequest())
  try {
    await axiosClient.delete(`/Journal/delete/${journalId}`)
    dispatch(deleteJournalSuccess())
  } catch {
    dispatch(deleteJournalFail())
  }
}

export const getRecurringFeelings =
  (userId, startDate, endDate) => async (dispatch) => {
    dispatch(getRecurringFeelingsRequest())
    try {
      const res = await axiosClient.get(
        `/Activity/getFeelingByDay/${userId}?StartDate=${startDate}&EndDate=${endDate}`
      )
      dispatch(getRecurringFeelingsSuccess(res.data))
    } catch {
      dispatch(getRecurringFeelingsFail())
    }
  }

export const deleteActivity = (activityId) => async (dispatch) => {
  dispatch(deleteActivityRequest())
  try {
    await axiosClient.delete(`/Activity/removeActivity/${activityId}`)
    dispatch(deleteActivitySuccess())
  } catch {
    dispatch(deleteActivityFail())
  }
}
