/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  formatHour,
  formatTime,
  getUserInfo,
} from '../../shared/constants/utilities'
import DailyJournal from '../../shared/components/DailyJournal'
import styles from './Activities.module.scss'
import { ActivitiesList } from '../../shared/components/ActivitiesList/ActivitiesList'
import {
  createActivity,
  getActivities,
  updateActivity,
  getDailyJournal,
  saveDailyJournal,
  updateDailyJournal,
  deleteDailyJournal,
  getRecurringFeelings,
  deleteActivity,
} from '../../core/store/activities/activities'
import { getUserTags } from '../../core/store/userTeam/userTeam'
import { validateAddActivity } from '../../shared/constants/patterns'
import moment from 'moment/moment'
import _ from 'lodash'

import {
  resetSync,
  setOutlookToken,
  setGoogleToken,
  syncCalendar,
} from '../../core/store/common/common'
import { Modal, Skeleton } from '@mui/material'

import { ModalMessage } from '../../shared/components/ModalMessage/ModalMessage'
import { ConfirmationModal } from '../../shared/components/ActivitiesList/ConfirmationModal'
import { useMediaQuery } from 'react-responsive'
import MobileCalendar from '../../shared/components/DailyJournal/Calendar'
import Journal from '../../shared/components/DailyJournal/Journal'

export const Activities = () => {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })
  const dispatch = useDispatch()

  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')
  const stateCal = new URLSearchParams(search).get('state')

  const { activities, userTeam, common } = useSelector((state) => state)
  const {
    activitiesData,
    journalData,
    isSuccessGetJournal,
    isSuccessCreateJournal,
    isLoadingUpdateActivity,
    isSuccessUpdateJournal,
    isSuccessDeleteJournal,
    isLoadingDeleteJournal,
    isLoadingCreateActivity,
    isSuccessCreateActivity,
    isSuccessUpdateActivity,
    recurringFeelingsData,
    isSuccessDeleteActivity,
    isLoadingDeleteActivity,
  } = activities
  const {
    isFailSyncCalendar,
    isLoadingSetCalendar,
    isLoadingSyncCalendar,
    isSuccessSyncCalendar,
    logout,
  } = common
  const { userTags, allUserTags } = userTeam
  const [activitiesInfo, setActivitiesInfo] = useState([])
  const [update, setUpdate] = useState(false)
  const [activityId, setActivityId] = useState('')
  const [tab, setTab] = useState('All')
  const [addActivity, setAddActivity] = useState(false)
  const [showTags, setShowTags] = useState(false)
  const [tagsInfo, setTagsInfo] = useState([])
  const [forceUpdate, setForceUpdate] = useState(false)
  const [searchBy, setSearchBy] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const [showMore, setShowMore] = useState([])
  const [showFeelings, setShowFeelings] = useState([])
  const [showFeelingsCalendar, setShowFeelingsCalendar] = useState(null)
  const [show, setShow] = useState(false)
  const [newActivity, setNewActivity] = useState({
    Name: '',
    Hour: moment(new Date()).format('HH:mm'),
    TagId: null,
    Feeling: null,
    FeelingType: '',
  })
  const [newActivityError, setNewActivityError] = useState({
    Name: { error: false },
    Hour: { error: false },
    TagId: { error: false },
    Feeling: { error: false },
    FeelingType: { error: false },
  })

  const createDailys = (arrayDaily) => {
    if (arrayDaily && arrayDaily.length > 0) {
      if (arrayDaily.length < 6) {
        return [
          ...arrayDaily.map((ele) => ({
            id: ele.id,
            hour: ele.hour,
            text: ele.text,
          })),
          ...Array.from({ length: 6 - arrayDaily.length }, () => ({})),
        ]
      }
      return arrayDaily.map((ele) => ({
        id: ele.id,
        hour: ele.hour,
        text: ele.text,
      }))
    } else {
      return Array.from({ length: 6 }, () => ({}))
    }
  }

  const navigate = useNavigate()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isSelectedByCalendar, setIsSelectedByCalendar] = useState(false)
  const [dailys, setDailys] = useState(createDailys([]))
  const [showFailSync, setShowFailSync] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    title: '',
    message: '',
    button: '',
  })
  const [modalDeleteInfo, setModalDeleteInfo] = useState({
    title: '',
    okText: '',
    cancelText: '',
  })
  const [isSavedEnter, setIsSavedEnter] = useState(false)

  const userId = getUserInfo().id

  const handleKeyDownEsc = (event) => {
    if (event.key === 'Escape') {
      setAddActivity(false)
      setNewActivity({
        Name: '',
        Hour: moment(new Date()).format('HH:mm'),
        TagId: null,
        Feeling: null,
        FeelingType: '',
      })
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDownEsc);

    return () => {
      document.removeEventListener('keydown', handleKeyDownEsc);
    };
  }, []);

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: false })

    if (getUserInfo().role === 'Administrator') {
      navigate('/')
    }
    if (code && stateCal === 'Outlook') {
      dispatch(setOutlookToken(code))
    }

    if (code && stateCal === 'Google') {
      dispatch(setGoogleToken(code))
    }
    dispatch(
      getActivities(
        userId,
        'All',
        moment(selectedDate).format('YYYY-MM-DD'),
        true
      )
    )
    dispatch(getUserTags(searchBy, userId))
    dispatch(getDailyJournal(userId, moment(selectedDate).format('YYYY-MM-DD')))
    dispatch(
      getRecurringFeelings(
        userId,
        moment(new Date()).subtract(4, 'days').format('YYYY-MM-DD'),
        moment(new Date()).format('YYYY-MM-DD')
      )
    )
    setForceUpdate(!forceUpdate)
  }, [])

  useEffect(() => {
    if (isFailSyncCalendar) {
      setShowFailSync(true)
      setModalInfo({
        title: 'Oops, an error occurred!',
        message:
          'Please go to your profile and select your calendar preference.',
        button: 'Close',
      })
    }
  }, [isFailSyncCalendar])

  useEffect(() => {
    if (isSuccessGetJournal && journalData) {
      setDailys(
        createDailys(
          journalData.map((ele) => ({
            id: ele.Id,
            hour: formatHour(ele.Time),
            text: ele.Description,
          }))
        )
      )
    }
  }, [isSuccessGetJournal])

  useEffect(() => {
    if (
      isSuccessCreateJournal ||
      isSuccessUpdateJournal ||
      isSuccessDeleteJournal
    ) {
      dispatch(
        getDailyJournal(userId, moment(selectedDate).format('YYYY-MM-DD'))
      )
    }
  }, [isSuccessCreateJournal, isSuccessUpdateJournal, isSuccessDeleteJournal])

  useEffect(() => {
    dispatch(
      getActivities(
        userId,
        'All',
        moment(selectedDate).format('YYYY-MM-DD'),
        true
      )
    )
    dispatch(getDailyJournal(userId, moment(selectedDate).format('YYYY-MM-DD')))
  }, [selectedDate])

  useEffect(() => {
    if (isSuccessDeleteActivity) {
      setShowDelete(false)
      dispatch(
        getActivities(userId, tab, moment(selectedDate).format('YYYY-MM-DD'))
      )
    }
  }, [isSuccessDeleteActivity])

  useEffect(() => {
    if (isSuccessCreateActivity || isSuccessSyncCalendar) {
      dispatch(
        getActivities(userId, tab, moment(selectedDate).format('YYYY-MM-DD'))
      )
      dispatch(
        getRecurringFeelings(
          userId,
          startDate ||
            moment(new Date()).subtract(4, 'days').format('YYYY-MM-DD'),
          endDate || moment(new Date()).format('YYYY-MM-DD')
        )
      )
      if (!isSavedEnter) {
        setAddActivity(false)
      }
      setIsSavedEnter(false)
    }
  }, [isSuccessCreateActivity, isSuccessSyncCalendar])

  useEffect(() => {
    if (isSuccessUpdateActivity) {
      dispatch(
        getActivities(userId, tab, moment(selectedDate).format('YYYY-MM-DD'))
      )
      dispatch(
        getRecurringFeelings(
          userId,
          startDate ||
            moment(new Date()).subtract(4, 'days').format('YYYY-MM-DD'),
          endDate || moment(new Date()).format('YYYY-MM-DD')
        )
      )
      setShowTags(false)
      dispatch(getUserTags("", userId))
      if (!isSavedEnter) {
        setAddActivity(false)
      }
      setNewActivity({
        ...newActivity,
        Name: '',
        Hour: moment(new Date()).format('HH:mm'),
      })
      setIsSavedEnter(false)
    }
  }, [isSuccessUpdateActivity])

  useEffect(() => {
    if (userTags && userTags.length > 0) {
      setTagsInfo(
        userTags.map((ele) => ({
          Id: ele.Id,
          Name: ele.Name,
          TagList: ele.TagList,
          IsPrivateTeam: ele.IsPrivateTeam
        }))
      )
    }
  }, [userTags])

  useEffect(() => {
    if (activitiesData && activitiesData.length > 0) {
      setActivitiesInfo(
        activitiesData.map((ele) => ({
          Id: ele.Id,
          Name: ele.Name,
          Hour: ele.Time,
          Feeling: ele.Feeling ? ele.Feeling.toLowerCase() : null,
          TagId: ele.TagId,
          TagName: allUserTags && allUserTags[ele.TagId],
        }))
      )
      setForceUpdate(!forceUpdate)
    } else {
      setActivitiesInfo([])
    }
  }, [activitiesData, allUserTags])

  useEffect(() => {
    let showMoreOptions = []
    let showAllFeeling = []
    for (let i = 0; i < activitiesInfo.length; i++) {
      showMoreOptions.push(false)
      showAllFeeling.push(false)
    }
    setShowMore(showMoreOptions)
    setShowFeelings(showAllFeeling)
    setForceUpdate(!forceUpdate)
  }, [activitiesInfo])

  const handleShowMore = (index) => {
    const showMoreOptions = showMore
    showMoreOptions[index] = !showMoreOptions[index]
    setShowMore(showMoreOptions)
    setForceUpdate(!forceUpdate)
  }

  const handleShowFeeling = (index) => {
    const showAllFeeling = showFeelings
    showAllFeeling[index] = !showAllFeeling[index]
    setShowFeelings(showAllFeeling)
    setForceUpdate(!forceUpdate)
  }

  const handleShowFeelingCalendar = (id, flag) => {
    setShowFeelingsCalendar(id)
    setShow(flag)
    setForceUpdate(!forceUpdate)
  }

  const handleSelectDate = (selDate) => {
    setIsSelectedByCalendar(false)
    setSelectedDate(selDate)
    dispatch(
      getActivities(userId, tab, moment(selDate).format('YYYY-MM-DD'), true)
    )
  }

  const handleSelectDateFromCalendar = (selDate) => {
    const steps = moment(new Date()).diff(selDate, 'days') / 5
    const position = (steps - Math.floor(steps)) * 5 + 1

    setIsSelectedByCalendar(true)
    setSelectedDate(selDate)
    const subs = 5 - position
    const adds = position - 1

    setStartDate(moment(selDate).subtract(subs, 'days').format('YYYY-MM-DD'))
    setEndDate(moment(selDate).add(adds, 'days').format('YYYY-MM-DD'))

    dispatch(
      getRecurringFeelings(
        userId,
        moment(selDate).subtract(subs, 'days').format('YYYY-MM-DD'),
        moment(selDate).add(adds, 'days').format('YYYY-MM-DD')
      )
    )
  }

  const handleMove = (selDate) => {
    setStartDate(moment(selDate).subtract(2, 'days').format('YYYY-MM-DD'))
    setEndDate(moment(selDate).add(2, 'days').format('YYYY-MM-DD'))

    dispatch(
      getRecurringFeelings(
        userId,
        moment(selDate).subtract(2, 'days').format('YYYY-MM-DD'),
        moment(selDate).add(2, 'days').format('YYYY-MM-DD')
      )
    )
  }

  const handleSaveDailyTextHTML = (dailyTextHTML) => {
    dispatch(
      saveDailyJournal(userId, {
        time: `${moment(selectedDate).format('YYYY-MM-DD')}T${moment(
          new Date()
        ).format('HH:mm')}Z`,
        description: dailyTextHTML,
      })
    )
  }

  const handleEditDailys = (id, dailyTextHTML) => {
    dispatch(
      updateDailyJournal({
        UserId: userId,
        Id: id,
        Description: dailyTextHTML,
      })
    )
  }

  const handleDeleteDaily = (id) => {
    dispatch(deleteDailyJournal(id))
  }

  const handleFeeling = (feeling, id) => {
    const activity = activitiesInfo.find((ele) => ele.Id === id)
    dispatch(
      updateActivity(id, {
        ...activity,
        Feeling: feeling,
        FeelingType: 'Event',
      })
    )
  }

  const handleTag = (tagId) => {
    const activity = activitiesInfo.find((ele) => ele.Id === activityId)
    dispatch(
      updateActivity(activityId, {
        ...activity,
        TagId: tagId,
        FeelingType: 'Event',
      })
    )
  }

  const handleChange = (event, newValue) => {
    setTab(newValue)
    dispatch(
      getActivities(userId, newValue, moment(selectedDate).format('YYYY-MM-DD'))
    )
    setForceUpdate(!forceUpdate)
  }

  const handleNewActivity = () => {
    setAddActivity(true)
  }

  const handleChangeNewActivity = (event) => {
    const { value, id } = event.target
    const newNewActivity = newActivity
    newNewActivity[id] = value
    setNewActivity(newNewActivity)
    setForceUpdate(!forceUpdate)
  }

  const handleSave = () => {
    const validation = validateAddActivity.validate(newActivity, {
      abortEarly: false,
    })

    if (validation.error) {
      const newErrorForm = newActivityError
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true
        setNewActivityError(newErrorForm)
        setAddActivity(false)
        setNewActivity({
          ...newActivity,
          Name: '',
          Hour: moment(new Date()).format('HH:mm'),
        })
        setForceUpdate(!forceUpdate)
      })
    } else if (update) {
      setUpdate(false)
      dispatch(
        updateActivity(activityId, {
          ...newActivity,
          FeelingType: 'Event',
          Hour: `${moment(selectedDate).format('YYYY-MM-DD')}T${
            newActivity.Hour
          }Z`,
        })
      )
    } else {
      dispatch(
        createActivity(userId, {
          ...newActivity,
          Hour: `${moment(selectedDate).format('YYYY-MM-DD')}T${
            newActivity.Hour
          }Z`,
          FeelingType: 'Event',
        })
      )
      setNewActivity({
        ...newActivity,
        Name: '',
        Hour: moment(new Date()).format('HH:mm'),
      })
      setForceUpdate(!forceUpdate)
    }
  }

  const inputRef = useRef(null);

  const handleSaveEnter = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
      event.preventDefault(); 
      inputRef.current.select(); 
    }

    if (event.key === 'Enter') {
      setIsSavedEnter(true)
      handleSave()
    }
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target
    setSearchBy(value)
  }

  const handleCloseTagList = () => {
    setShowTags(false)
    dispatch(getUserTags("", userId))
  }

  const handleSearch = () => {
    if (searchBy === "") {
      dispatch(getUserTags("", userId))
    } else {
      dispatch(getUserTags(searchBy, userId))
    }
  }

  const handleOpenTags = (event, id) => {
    setAnchorEl(event.currentTarget)
    setActivityId(id)
    setShowTags(true)
  }

  const handleSelectFeeling = (feeling, date, idActivity) => {
    if (idActivity) {
      dispatch(
        updateActivity(idActivity, {
          ...newActivity,
          Feeling: feeling,
          FeelingType: 'Day',
          Name: 'update feeling',
          Hour: `${moment(date).format('YYYY-MM-DD')}T${newActivity.Hour}Z`,
        })
      )
      setShowFeelingsCalendar(null)
      setShow(false)
      setForceUpdate(!forceUpdate)
    } else {
      dispatch(
        createActivity(userId, {
          ...newActivity,
          Name: 'create feeling',
          Feeling: feeling,
          Hour: `${moment(date).format('YYYY-MM-DD')}T${newActivity.Hour}Z`,
          FeelingType: 'Day',
        })
      )
      setShowFeelingsCalendar(null)
      setShow(false)
      setForceUpdate(!forceUpdate)
    }
  }

  const handleSyncCalendar = () => {
    dispatch(syncCalendar(userId))
  }

  const handleClose = () => {
    setShowDelete(false)
    setShowFailSync(false)
    dispatch(resetSync())
  }

  const handleEditActivity = (name, time, id) => {
    setAddActivity(true)
    setActivityId(id)
    setUpdate(true)
    setNewActivity({
      ...newActivity,
      Name: name,
      Hour: formatTime(time),
    })
  }

  const handleDeleteActivity = (id) => {
    setShowDelete(true)
    setModalDeleteInfo({
      title: 'Are you sure you want to delete this activity?',
      cancelText: 'Cancel',
      okText: 'Delete',
    })
    setActivityId(id)
  }

  const handleDelete = () => {
    dispatch(deleteActivity(activityId))
  }

  const orderActivities = _.orderBy(activitiesInfo, ['Hour'], ['asc'])

  useEffect(() => {
    if (logout) {
      if (update) {
        setUpdate(false)
        dispatch(
          updateActivity(activityId, {
            ...newActivity,
            FeelingType: 'Event',
            Hour: `${moment(selectedDate).format('YYYY-MM-DD')}T${
              newActivity.Hour
            }Z`,
          })
        )
      } else {
        dispatch(
          createActivity(userId, {
            ...newActivity,
            Hour: `${moment(selectedDate).format('YYYY-MM-DD')}T${
              newActivity.Hour
            }Z`,
            FeelingType: 'Event',
          })
        )
        setNewActivity({
          ...newActivity,
          Name: '',
          Hour: moment(new Date()).format('HH:mm'),
        })
        setForceUpdate(!forceUpdate)
      }
    }

  }, [logout, update])

  return (
    <section className={styles.activities}>
      <div className={styles.container}>
        <Modal open={showFailSync} onClose={handleClose}>
          <ModalMessage handleClose={handleClose} modalInfo={modalInfo} />
        </Modal>
        <Modal open={showDelete} onClose={handleClose}>
          <ConfirmationModal
            handleClose={handleClose}
            handleDelete={handleDelete}
            modalInfo={modalDeleteInfo}
            isLoadingDeleteActivity={isLoadingDeleteActivity}
          />
        </Modal>
        {isLoadingSetCalendar || isLoadingSyncCalendar ? (
          <>
            <div className={styles.title}>
              {`${common?.user?.FirstName} ${common?.user?.LastName}`}
            </div>
            <div className={styles.wrapperSubtitle}>
              <p className={styles.subtitle}>My</p>
              &nbsp;
              <p className={styles.subtitle2}>Activities</p>
            </div>
            <div className={styles.row}>
              {muaMobile && (
                <Skeleton
                  variant="rounded"
                  width={'100%'}
                  height={200}
                  sx={{ borderRadius: '12px' }}
                />
              )}
              <Skeleton
                variant="rounded"
                width={'100%'}
                height={512}
                sx={{ borderRadius: '12px' }}
              />
              <Skeleton
                variant="rounded"
                width={'100%'}
                height={512}
                sx={{ borderRadius: '12px' }}
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.title}>
              {`${common?.user?.FirstName} ${common?.user?.LastName}`}
            </div>
            <div className={styles.wrapperSubtitle}>
              <p className={styles.subtitle}>My</p>
              &nbsp;
              <p className={styles.subtitle2}>Activities</p>
            </div>
            <div className={styles.row}>
              {muaMobile && (
                <MobileCalendar
                  selectedDate={selectedDate}
                  recurringFeelingsData={recurringFeelingsData}
                  isSelectedByCalendar={isSelectedByCalendar}
                  handleMove={handleMove}
                  handleSelectDateFromCalendar={handleSelectDateFromCalendar}
                  handleSelectDate={handleSelectDate}
                  handleSelectFeeling={handleSelectFeeling}
                  action={'Activities'}
                  handleShowFeeling={handleShowFeelingCalendar}
                  showFeelings={showFeelingsCalendar}
                  show={show}
                  setShowFeelings={setShowFeelingsCalendar}
                  setShow={setShow}
                />
              )}
              <ActivitiesList
                handleSyncCalendar={handleSyncCalendar}
                lastSyncCalendarDate={common?.user?.LastCalendarSync}
                handleFeeling={handleFeeling}
                handleTag={handleTag}
                activitiesInfo={orderActivities}
                tab={tab}
                handleChange={handleChange}
                handleOpenTags={handleOpenTags}
                showTags={showTags}
                setShowTags={setShowTags}
                anchorEl={anchorEl}
                handleChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
                tagsInfo={tagsInfo}
                addActivity={addActivity}
                newActivity={newActivity}
                handleChangeNewActivity={handleChangeNewActivity}
                isLoadingCreateActivity={isLoadingCreateActivity}
                handleSave={handleSave}
                handleNewActivity={handleNewActivity}
                selectedDate={selectedDate}
                handleEditActivity={handleEditActivity}
                handleDeleteActivity={handleDeleteActivity}
                isLoadingUpdateActivity={isLoadingUpdateActivity}
                muaMobile={muaMobile}
                showMore={showMore}
                handleShowMore={handleShowMore}
                handleShowFeeling={handleShowFeeling}
                showFeelings={showFeelings}
                handleSaveEnter={handleSaveEnter}
                handleCloseTagList={handleCloseTagList}
                logout={logout}
                inputRef={inputRef}
              />
              {!muaMobile && (
                <DailyJournal
                  dailys={dailys}
                  recurringFeelingsData={recurringFeelingsData}
                  selectedDate={selectedDate}
                  isSelectedByCalendar={isSelectedByCalendar}
                  handleMove={handleMove}
                  handleSelectDateFromCalendar={handleSelectDateFromCalendar}
                  handleSelectDate={handleSelectDate}
                  handleSaveDailys={handleSaveDailyTextHTML}
                  handleEditDailys={handleEditDailys}
                  handleDeleteDaily={handleDeleteDaily}
                  handleSelectFeeling={handleSelectFeeling}
                  action={'Activities'}
                  logout={logout}
                  isLoadingDeleteJournal={isLoadingDeleteJournal}
                  isSuccessDeleteJournal={isSuccessDeleteJournal}
                />
              )}
              {muaMobile && (
                <Journal
                  dailys={dailys}
                  handleSaveDailys={handleSaveDailyTextHTML}
                  handleEditDailys={handleEditDailys}
                  handleDeleteDaily={handleDeleteDaily}
                  logout={logout}
                  isLoadingDeleteJournal={isLoadingDeleteJournal}
                  isSuccessDeleteJournal={isSuccessDeleteJournal}
                />
              )}
            </div>
          </>
        )}
      </div>
    </section>
  )
}
