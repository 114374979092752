import React, { useEffect, useState } from 'react'
import { Divider, Grid, Paper, IconButton, Modal } from '@mui/material'
import styles from './DailyJournal.module.scss'
import ReactHtmlParser from 'react-html-parser'

import { IconApp } from '../IconApp/IconApp'
import JournalModal from './JournalModal'
import { ConfirmationModal } from '../ActivitiesList/ConfirmationModal'

const Journal = ({
  dailys,
  handleSaveDailys,
  handleEditDailys,
  handleDeleteDaily,
  logout,
  isLoadingDeleteJournal,
  isSuccessDeleteJournal
}) => {
  const [showAdd, setShowAdd] = useState(false)
  const [dailyTextHTML, setDailyText] = useState(null)
  const [defaultValue, setDefaultValue] = useState(null)
  const [isEdition, setIsEdition] = useState(false)
  const [idToEdit, setIdToEdit] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(false)

  useEffect(() => {
    if (isSuccessDeleteJournal) {
      setConfirmDelete(false)
    }
  }, [isSuccessDeleteJournal])

  const handleAddActivity = () => {
    setIsEdition(false)
    setDefaultValue(null)
    setShowAdd(true)
  }
  const handleClose = () => {
    setShowAdd(false)
  }
  const handleSave = () => {
    handleSaveDailys(dailyTextHTML)
    setShowAdd(false)
  }

  const handleEdit = () => {
    handleEditDailys(idToEdit, dailyTextHTML)
    setShowAdd(false)
  }

  const handleCloseDelete = () => {
    setConfirmDelete(false)
  }

  const handleDelete = () => {
    setConfirmDelete(true)
  }

  const handleConfirmDelete = () => {
    handleDeleteDaily(idToEdit)
    setShowAdd(false)
  }

  const handleChange = (event) => {
    setDailyText(event)
  }

  const handleEditActivity = (daily) => {
    setIsEdition(true)
    setDefaultValue(daily.text)
    setIdToEdit(daily.id)
    setShowAdd(true)
  }

  const getLimitText = (txt) => {
    if ([...txt].length > 30) {
      return `${txt.substring(0, 30)}...`
    }
    return txt
  }

  useEffect(() => {
    if (logout) {
      if (isEdition) {
        handleEdit()
      } else {
        handleSave()
      }
    }

  }, [logout, isEdition])

  const modalDeleteInfo = {
    title: 'Are you sure you want to delete this journal entry?',
    cancelText: 'Cancel',
    okText: 'Delete',
  }

  return (
    <>
      <div>
        <Modal open={showAdd} onClose={handleClose}>
          <JournalModal
            defaultValue={defaultValue}
            isEdition={isEdition}
            handleClose={handleClose}
            handleChange={handleChange}
            handleSave={handleSave}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </Modal>
        <Modal open={confirmDelete} onClose={handleCloseDelete}>
          <ConfirmationModal
            handleClose={handleCloseDelete}
            handleDelete={handleConfirmDelete}
            modalInfo={modalDeleteInfo}
            isLoadingDeleteActivity={isLoadingDeleteJournal}
          />
        </Modal>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.wrapperBottom}>
          <div className={styles.headerResponsive}>
            <div className={styles.dailyTitleResponsive}>Daily Journal</div>
            <IconButton
              className={styles.addIconButtonResponsive}
              onClick={handleAddActivity}
            >
              <IconApp iconName="plus" alt="plus" />
            </IconButton>
          </div>
          <div className={styles.wrapperJournal}>
            {dailys.map((ele, indx) => (
              <div key={indx} className={styles.dailyLine}>
                <Divider
                  flexItem
                  style={{ borderColor: '#1A3127', height: '0.5px' }}
                />
                <Grid className={styles.containerText} container spacing={0.5}>
                  <Grid item sm={3}>
                    <Paper className={styles.hour}>{ele.hour}</Paper>
                    {ele.hour && (
                      <IconButton
                        className={styles.editButton}
                        onClick={() => handleEditActivity(ele)}
                      >
                        <IconApp
                          width="11px"
                          iconName="edit-icon-MUA"
                          alt="edit-icon-MUA"
                        />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item sm={9}>
                    <Paper className={styles.text}>
                      {ele.text && ReactHtmlParser(getLimitText(ele.text))}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Journal
