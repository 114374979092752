import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, CircularProgress } from '@mui/material'
import styles from './ModalLogout.module.scss'

export const LogoutModal = ({
  handleClose,
  isLoading,
}) => {
  return (
    <div>
      <Box className={styles.modalBox}>
        <div className={styles.modalBody}>
          <div className={styles.modalTitle}>
            Your session has expired, but don't worry! We've saved all your work
            and logged you out for security. Please log in again.
          </div>
          <div className={styles.modalButtons}>
            {isLoading ? (
              <CircularProgress size="20px" />
            ) : (
              <>
                <Button onClick={handleClose} disabled={isLoading}>
                  <p>Accept</p>
                </Button>
              </>
            )}
          </div>
        </div>
      </Box>
    </div>
  )
}

LogoutModal.propTypes = {
  handleClose: PropTypes.func,
  isLoading: PropTypes.bool,
}
