import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

const StyledTab = styled((props) => <Tab {...props} />)(() => ({
  marginBottom: '10px',
  textTransform: 'capitalize',
  fontSize: '13px',
  color: "#1A3127",
  '&.Mui-selected': {
    fontWeight: 600,
    color: '#1A3127',
  },
}))

export const MainTabs = ({ value, handleChange, arrayTabs }) => {
  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="main-tabs"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: '#1A3127',
          },
        }}>
        {arrayTabs.map((ele, indx) => (
          <StyledTab key={indx} label={ele.name} value={ele.value} />
        ))}
      </Tabs>
    </Box>
  )
}

export default MainTabs
