import React, { useState } from 'react'
import { IconButton, Modal } from '@mui/material'
import styles from './DailyJournal.module.scss'
import moment from 'moment/moment'

import Calendar from '../Calendar'
import { IconApp } from '../IconApp/IconApp'
import CalendarModal from './CalendarModal'

const MobileCalendar = ({
  isSelectedByCalendar,
  selectedDate,
  handleSelectDate,
  handleSelectDateFromCalendar,
  handleMove,
  recurringFeelingsData,
  handleSelectFeeling,
  action,
  handleShowFeeling,
  showFeelings,
  show,
  setShowFeelings,
  setShow,
}) => {
  const [showCalendar, setShowCalendar] = useState(false)

  const handleOpenCalendar = () => {
    setShowCalendar(true)
  }
  const handleCloseCalendar = () => {
    setShowCalendar(false)
  }

  const handleChangeDate = (value) => {
    setShowCalendar(false)
    handleSelectDateFromCalendar(moment(value)._i.$d)
  }

  return (
    <>
      <div>
        <Modal open={showCalendar} onClose={handleCloseCalendar}>
          <CalendarModal handleChangeDate={handleChangeDate} />
        </Modal>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.wrapperTop}>
          <div className={styles.wrapperTitle}>
            <div className={styles.dailyTitle}>
              {moment(selectedDate).format('dddd, MMMM D, YYYY')}
            </div>
            <IconButton onClick={handleOpenCalendar}>
              <IconApp height="17px" iconName="calendar" alt="calendar" />
            </IconButton>
          </div>
          <Calendar
            isSelectedByCalendar={isSelectedByCalendar}
            handleMove={handleMove}
            recurringFeelingsData={recurringFeelingsData}
            selectedDate={selectedDate}
            handleSelectDate={handleSelectDate}
            handleSelectFeeling={handleSelectFeeling}
            action={action}
            handleShowFeeling={handleShowFeeling}
            showFeelings={showFeelings}
            show={show}
            setShowFeelings={setShowFeelings}
            setShow={setShow}
          />
        </div>
      </div>
    </>
  )
}

export default MobileCalendar
