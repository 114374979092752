import { createSlice } from '@reduxjs/toolkit'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'teamAnalytics',
  initialState: {
    teamFeelings: [],
    recurringFeelingsData: [],
    teamsData: [],
    comparisonFeelingsData: [],
    progressChartTeamData: [],
    isLoadingRecurringFeelings: false,
    isSuccessRecurringFeelings: false,
    isFailRecurringFeelings: false,
    isLoadingTeams: false,
    isSuccessTeams: false,
    isFailTeams: false,
    isLoadingComparisonFeelings: false,
    isSuccessComparisonFeelings: false,
    isFailComparisonFeelings: false,
    isLoadingProgressChart: false,
    isSuccessProgressChart: false,
    isFailProgressChart: false,
  },
  reducers: {
    getTeamFeelingsRequest: (state) => {
      return {
        ...state,
        teamFeelingsData: [],
        isLoadingTeamFeelings: true,
        isSuccessTeamFeelings: false,
        isFailTeamFeelings: false,
      }
    },
    getTeamFeelingsSuccess: (state, action) => {
      return {
        ...state,
        teamFeelingsData: action.payload,
        isLoadingTeamFeelings: false,
        isSuccessTeamFeelings: true,
        isFailTeamFeelings: false,
      }
    },
    getTeamFeelingsFail: (state) => {
      return {
        ...state,
        teamFeelingsData: [],
        isLoadingTeamFeelings: false,
        isSuccessTeamFeelings: false,
        isFailTeamFeelings: true,
      }
    },
    getRecurringFeelingsRequest: (state) => {
      return {
        ...state,
        isLoadingRecurringFeelings: true,
        isSuccessRecurringFeelings: false,
        isFailRecurringFeelings: false,
      }
    },
    getRecurringFeelingsSuccess: (state, action) => {
      return {
        ...state,
        recurringFeelingsData: action.payload,
        isLoadingRecurringFeelings: false,
        isSuccessRecurringFeelings: true,
        isFailRecurringFeelings: false,
      }
    },
    getRecurringFeelingsFail: (state) => {
      return {
        ...state,
        isLoadingRecurringFeelings: false,
        isSuccessRecurringFeelings: false,
        isFailRecurringFeelings: true,
      }
    },
    getTeamsRequest: (state) => {
      return {
        ...state,
        isLoadingTeams: true,
        isSuccessTeams: false,
        isFailTeams: false,
      }
    },
    getTeamsSuccess: (state, action) => {
      return {
        ...state,
        teamsData: action.payload,
        isLoadingTeams: false,
        isSuccessTeams: true,
        isFailTeams: false,
      }
    },
    getTeamsFail: (state) => {
      return {
        ...state,
        isLoadingTeams: false,
        isSuccessTeams: false,
        isFailTeams: true,
      }
    },
    getComparisonFeelingsRequest: (state) => {
      return {
        ...state,
        comparisonFeelingsData: [],
        isLoadingComparisonFeelings: true,
        isSuccessComparisonFeelings: false,
        isFailComparisonFeelings: false,
      }
    },
    getComparisonFeelingsSuccess: (state, action) => {
      return {
        ...state,
        comparisonFeelingsData: action.payload,
        isLoadingComparisonFeelings: false,
        isSuccessComparisonFeelings: true,
        isFailComparisonFeelings: false,
      }
    },
    getComparisonFeelingsFail: (state) => {
      return {
        ...state,
        comparisonFeelingsData: [],
        isLoadingComparisonFeelings: false,
        isSuccessComparisonFeelings: false,
        isFailComparisonFeelings: true,
      }
    },
    getProgressChartRequest: (state) => {
      return {
        ...state,
        progressChartTeamData: [],
        isLoadingProgressChart: true,
        isSuccessProgressChart: false,
        isFailProgressChart: false,
      }
    },
    getProgressChartSuccess: (state, action) => {
      return {
        ...state,
        progressChartTeamData: action.payload,
        isLoadingProgressChart: false,
        isSuccessProgressChart: true,
        isFailProgressChart: false,
      }
    },
    getProgressChartFail: (state) => {
      return {
        ...state,
        progressChartTeamData: [],
        isLoadingProgressChart: false,
        isSuccessProgressChart: false,
        isFailProgressChart: true,
      }
    },
  },
})

export default slice.reducer

export const {
  getTeamFeelingsRequest,
  getTeamFeelingsSuccess,
  getTeamFeelingsFail,
  getRecurringFeelingsRequest,
  getRecurringFeelingsSuccess,
  getRecurringFeelingsFail,
  getTeamsRequest,
  getTeamsSuccess,
  getTeamsFail,
  getComparisonFeelingsRequest,
  getComparisonFeelingsSuccess,
  getComparisonFeelingsFail,
  getProgressChartRequest,
  getProgressChartSuccess,
  getProgressChartFail
} = slice.actions

export const getTeamFeelings = (userId, tab) => async (dispatch) => {
  dispatch(getTeamFeelingsRequest())
  try {
    const res = await axiosClient.get(
      `/Activity/GetFeelingsByTeam/${userId}?timeFrameEnum=${tab}`
    )
    dispatch(getTeamFeelingsSuccess(res.data))
  } catch {
    dispatch(getTeamFeelingsFail())
  }
}

export const getTeamRecurringFeelings =
  (teamId, startDate, endDate) => async (dispatch) => {
    dispatch(getRecurringFeelingsRequest())
    try {
      const res = await axiosClient.get(
        `/Activity/getRecurringFeelingByTeam/${teamId}?StartDate=${startDate}&EndDate=${endDate}`
      )

      dispatch(getRecurringFeelingsSuccess(res.data))
    } catch {
      dispatch(getRecurringFeelingsFail())
    }
  }

  export const getTeams = (userId) => async (dispatch) => {
    dispatch(getTeamsRequest())
    try {
      const res = await axiosClient.get(
        `/UserTeam/GetTeamsByUser/${userId}`
      )
      dispatch(getTeamsSuccess(res.data))
    } catch {
      dispatch(getTeamsFail())
    }
  }

  export const getComparisonFeelings = (teamId, date) => async (dispatch) => {
    dispatch(getComparisonFeelingsRequest())
    try {
      const res = await axiosClient.get(
        `/Activity/GetComparissionFeelingsByTeam/${teamId}?date=${date}`
      )
      dispatch(getComparisonFeelingsSuccess(res.data))
    } catch {
      dispatch(getComparisonFeelingsFail())
    }
  }

  export const getProgressChart = (teamIds, feeling, time) => async (dispatch) => {
    dispatch(getProgressChartRequest())
    try {
      const res = await axiosClient.post(
        `/Activity/GetTeamsProgressChart`,
        {
          TeamIds: teamIds,
          Feeling: feeling,
          TimeFrame: time,
        }
      )
      dispatch(getProgressChartSuccess(res.data))
    } catch {
      dispatch(getProgressChartFail())
    }
  }