import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './ForgotPassword.module.scss'
import { Button, CircularProgress, TextField, Modal } from '@mui/material'
import { ModalMessage } from '../../shared/components/ModalMessage/ModalMessage'
import { validateForgotPassword } from '../../shared/constants/patterns'
import { reset, forgotPassword } from '../../core/store/password/password'
import { useMediaQuery } from 'react-responsive'
import globals from '../../shared/constants/global.constant'

export const ForgotPassword = () => {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })

  const dispatch = useDispatch()
  const { password } = useSelector((state) => state)
  const { isSuccess, isFail, isLoading } = password
  const navigate = useNavigate()

  const [forceUpdate, setForceUpdate] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFail, setShowFail] = useState(false)
  const [email, setEmail] = useState('')

  const [errorEmail, setErrorEmail] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    title: '',
    message: '',
    button: '',
  })

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: true })
    window.Intercom && window.Intercom('hide')
  }, [])

  useEffect(() => {
    if (isSuccess) {
      setShowSuccess(true)
      setModalInfo({
        title: 'Done!',
        message:
          'Please check your inbox to click the link to reset your password',
        button: 'Ok',
      })
    }

    if (isFail) {
      setShowFail(true)
      setModalInfo({
        title: 'Ups an error ocurred!',
        message: "The user doesn't exist in our platform",
        button: 'Confirm',
      })
    }
  }, [isSuccess, isFail, navigate])

  const handleChange = (event) => {
    const { value } = event.target
    setErrorEmail(false)
    setEmail(value)
    setForceUpdate(!forceUpdate)
  }

  const handleRememberPassword = () => {
    const validation = validateForgotPassword.validate(
      { Email: email },
      {
        abortEarly: false,
      }
    )

    if (validation.error) {
      setErrorEmail(true)
    } else {
      dispatch(forgotPassword(email))
      setEmail('')
      setForceUpdate(!forceUpdate)
    }
  }

  const handleClose = () => {
    setShowSuccess(false)
    setShowFail(false)
    dispatch(reset())
    if (!errorEmail) {
      navigate('/')
    }
  }

  return (
    <section className={styles.Home}>
      <div>
        <Modal open={showSuccess || showFail} onClose={handleClose}>
          <ModalMessage handleClose={handleClose} modalInfo={modalInfo} />
        </Modal>
      </div>
      <div className={styles.logo}>
        <img
          alt="logo"
          src={`${globals.logosBasePath}new-logo-white.png`}
          width="94px"
          height="43px"
        />
      </div>
      <div className={styles.header}>Reset your password</div>
      <div className={styles.card}>
        <div className={styles.title}>Please, enter your email.</div>
        <TextField
          autoComplete="off"
          id="email"
          onChange={handleChange}
          placeholder="Email"
          className={styles.customTextField}
          InputProps={{
            sx: {
              color: '#1a3127',
            },
          }}
          sx={{
            marginTop: '76px',
            marginBottom: '50px',
            width: muaMobile ? '265px' : '340px',
          }}
        />

        {isLoading ? (
          <div className={styles.progress}>
            <CircularProgress size="20px" color="light" />
          </div>
        ) : (
          <>
            <div className={styles.rememberButtonWrapper}>
              <Button
                className={styles.rememberButton}
                color="light"
                variant="contained"
                onClick={handleRememberPassword}
              >
                <p>Reset Password</p>
              </Button>
            </div>
          </>
        )}
        {errorEmail && (
          <div className={styles.errorMessage}>
            <h4>The email is not valid</h4>
          </div>
        )}
      </div>
    </section>
  )
}
