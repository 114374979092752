import React, { useState } from 'react'
import { TextField } from '@mui/material'
import moment from 'moment'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'

import styles from './DailyJournal.module.scss'

const CalendarModal = ({ handleChangeDate }) => {
  const [value, setValue] = useState(moment(new Date()))
  return (
    <div className={styles.modalCalendarCard}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          disableFuture
          openTo="day"
          value={value}
          onChange={(newValue) => {
            handleChangeDate(newValue)
            setValue(newValue)
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  )
}

export default CalendarModal
