import { createSlice } from '@reduxjs/toolkit'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'common',
  initialState: {
    company: null,
    user: { FirstName: '', LastName: '' },
    isLoadingUser: false,
    isSuccessUser: false,
    isFailUser: false,
    isLoadingUpdateUser: false,
    isSuccessUpdateUser: false,
    isFailUpdateUser: false,
    logout: false
  },
  reducers: {
    setLogout: (state, action) => {
      return {
        ...state,
        logout: action.payload,
      }
    },
    setCompany: (state, action) => {
      return {
        ...state,
        company: action.payload,
      }
    },
    reset: (state) => {
      return {
        ...state,
        company: null,
      }
    },
    getUserRequest: (state) => {
      return {
        ...state,
        isLoadingUser: true,
        isSuccessUser: false,
        isFailUser: false,
      }
    },
    getUserSuccess: (state, action) => {
      return {
        ...state,
        user: action.payload,
        isLoadingUser: false,
        isSuccessUser: true,
        isFailUser: false,
      }
    },
    getUserFail: (state) => {
      return {
        ...state,
        user: { FirstName: '', LastName: '' },
        isLoadingUser: false,
        isSuccessUser: false,
        isFailUser: true,
      }
    },
    updateUserRequest: (state) => {
      return {
        ...state,
        isLoadingUpdateUser: true,
        isSuccessUpdateUser: false,
        isFailUpdateUser: false,
      }
    },
    updateUserSuccess: (state) => {
      return {
        ...state,
        isLoadingUpdateUser: false,
        isSuccessUpdateUser: true,
        isFailUpdateUser: false,
      }
    },
    updateUserFail: (state) => {
      return {
        ...state,
        isLoadingUpdateUser: false,
        isSuccessUpdateUser: false,
        isFailUpdateUser: true,
      }
    },
    setCalendarRequest: (state) => {
      return {
        ...state,
        isLoadingSetCalendar: true,
        isSuccessSetCalendar: false,
        isFailSetCalendar: false,
      }
    },
    setCalendarSuccess: (state) => {
      return {
        ...state,
        isLoadingSetCalendar: false,
        isSuccessSetCalendar: true,
        isFailSetCalendar: false,
      }
    },
    setCalendarFail: (state) => {
      return {
        ...state,
        isLoadingSetCalendar: false,
        isSuccessSetCalendar: false,
        isFailSetCalendar: true,
      }
    },
    syncCalendarRequest: (state) => {
      return {
        ...state,
        isLoadingSyncCalendar: true,
        isSuccessSyncCalendar: false,
        isFailSyncCalendar: false,
      }
    },
    syncCalendarSuccess: (state) => {
      return {
        ...state,
        isLoadingSyncCalendar: false,
        isSuccessSyncCalendar: true,
        isFailSyncCalendar: false,
      }
    },
    syncCalendarFail: (state) => {
      return {
        ...state,
        isLoadingSyncCalendar: false,
        isSuccessSyncCalendar: false,
        isFailSyncCalendar: true,
      }
    },
    resetSync: (state) => {
      return {
        ...state,
        isFailSyncCalendar: false,
      }
    },
  },
})

export default slice.reducer

export const {
  setLogout,
  setCompany,
  reset,
  getUserRequest,
  getUserSuccess,
  getUserFail,
  updateUserRequest,
  updateUserSuccess,
  updateUserFail,
  setCalendarRequest,
  setCalendarSuccess,
  setCalendarFail,
  syncCalendarRequest,
  syncCalendarSuccess,
  syncCalendarFail,
  resetSync,
} = slice.actions

export const getProfile = (userId) => async (dispatch) => {
  dispatch(getUserRequest())
  try {
    const res = await axiosClient.get(`/User/GetUserById/${userId}`)
    dispatch(getUserSuccess(res.data))
  } catch {
    dispatch(getUserFail())
  }
}

export const updateProfile = (userId, userInfo) => async (dispatch) => {
  const { FirstName, LastName, Calendar, TimeZone, IsActiveNotification } =
    userInfo
  dispatch(updateUserRequest())
  try {
    const res = await axiosClient.put(`/User/UpdateUserProfile/${userId}`, {
      FirstName,
      LastName,
      Calendar,
      TimeZone,
      IsActiveNotification,
    })
    if (res.data.CalendarAuthLink) {
      const a = document.createElement('a')
      a.href = res.data.CalendarAuthLink
      a.target = '_blank'
      a.click()
    }
    dispatch(updateUserSuccess())
  } catch {
    dispatch(updateUserFail())
  }
}

export const setOutlookToken = (token) => async (dispatch) => {
  dispatch(setCalendarRequest())
  try {
    await axiosClient.post(`/Calendar/OutlookToken`, { Code: token })

    dispatch(setCalendarSuccess())
  } catch {
    dispatch(setCalendarFail())
  }
}

export const setGoogleToken = (token) => async (dispatch) => {
  dispatch(setCalendarRequest())
  try {
    await axiosClient.post(`/Calendar/GoogleToken`, { Code: token })

    dispatch(setCalendarSuccess())
  } catch {
    dispatch(setCalendarFail())
  }
}

export const syncCalendar = (userId) => async (dispatch) => {
  dispatch(syncCalendarRequest())
  try {
    await axiosClient.get(`/Calendar/GetUserEvents/`)

    dispatch(syncCalendarSuccess())
    dispatch(getProfile(userId))
  } catch {
    dispatch(getProfile(userId))
    dispatch(syncCalendarFail())
  }
}
