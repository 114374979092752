/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Input,
  IconButton,
  Button,
  Box,
  Modal,
  Grid,
  Tooltip,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import styles from './Tags.module.scss'
import globals from '../../shared/constants/global.constant'
import { ModalInput } from '../../shared/components/ModalInput/ModalInput'
import { validateName } from '../../shared/constants/patterns'
import { getTeamsTags, searchTag } from '../../core/store/teams/teams'
import { getUserInfo } from '../../shared/constants/utilities'
import { getCompanies } from '../../core/store/companies/companies'
import {
  createCompanyTag,
  createTeamTag,
  deleteTag,
  updateTag,
} from '../../core/store/tags/tags'

export const Tags = () => {
  const dispatch = useDispatch()
  const { teams, common, tags } = useSelector((state) => state)
  const { teamsTags, isSuccessTeamsTags } = teams
  const { company } = common
  const {
    isSuccessCreateTeamTag,
    isSuccessCreateCompanyTag,
    isSuccessUpdateTag,
    isLoadingCreateTeamTag,
    isLoadingCreateCompanyTag,
    isLoadingUpdateTag,
    isSuccessDeleteTag,
  } = tags
  const navigate = useNavigate()
  const [forceUpdate, setForceUpdate] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [title, setTitle] = useState('')
  const [tagName, setTagName] = useState('')
  const [tagNameError, setTagNameError] = useState(false)
  const [teamsTagsData, setTeamsTagsData] = useState([])
  const [searchBy, setSearchBy] = useState('')
  const [tagBy, setTagBy] = useState(false)
  const [teamId, setTeamId] = useState('')
  const [tagId, setTagId] = useState('')

  useEffect(() => {
    window.Intercom &&
      window.Intercom('update', { hide_default_launcher: true })
    window.Intercom && window.Intercom('hide')
  }, [])

  useEffect(() => {
    if (getUserInfo().role !== 'Administrator') {
      navigate('/')
    }
    dispatch(getCompanies())
  }, [])

  useEffect(() => {
    if (company) {
      dispatch(getTeamsTags(company))
    }
  }, [company])

  useEffect(() => {
    if (isSuccessTeamsTags) {
      setTeamsTagsData(
        teamsTags &&
          teamsTags.map((ele) => ({
            id: ele.Id,
            teamName: ele.Name,
            tagList: ele.TagList,
            teamColor: ele.TeamColor,
            isPrivateTeam: ele.IsPrivateTeam,
          }))
      )
    }
  }, [isSuccessTeamsTags, teamsTags])

  useEffect(() => {
    if (
      isSuccessCreateCompanyTag ||
      isSuccessCreateTeamTag ||
      isSuccessUpdateTag ||
      isSuccessDeleteTag
    ) {
      setShowCreate(false)
      setShowUpdate(false)
      setTagName('')
      dispatch(getTeamsTags(company))
    }
  }, [
    isSuccessCreateCompanyTag,
    isSuccessCreateTeamTag,
    isSuccessUpdateTag,
    isSuccessDeleteTag,
  ])

  const handleChangeSearch = (event) => {
    const { value } = event.target
    setSearchBy(value)
  }

  const handleSearch = () => {
    if (searchBy) {
      dispatch(searchTag(company, searchBy))
    } else {
      dispatch(getTeamsTags(company))
    }
  }

  const handleOnChange = (event) => {
    const { value } = event.target
    setTagName(value.slice(0, 13))

    setTagNameError(false)
    setForceUpdate(!forceUpdate)
  }

  const handleOnBlur = (event) => {
    const { value } = event.target
    if (value === '') {
      setTagNameError(true)
      setForceUpdate(!forceUpdate)
    } else {
      setTagNameError(false)
      setForceUpdate(!forceUpdate)
    }
  }

  const handleClose = () => {
    setShowCreate(false)
    setShowUpdate(false)
    setTagName('')
    setTagNameError(false)
    setForceUpdate(!forceUpdate)
  }

  const handleCreate = (name) => {
    const tagBy = teamsTagsData.find((ele) => ele.teamName === name).teamName
    const teamId = teamsTagsData.find((ele) => ele.teamName === name).id
    setTagBy(tagBy)
    setTeamId(teamId)
    setShowCreate(true)
    setTitle('Add new tag')
    setTagName('')
    setForceUpdate(!forceUpdate)
  }

  const handleUpdate = (id, teamId) => {
    const team = teamsTagsData.find((ele) => ele.id === teamId).tagList
    const name = team.find((ele) => ele.Id === id).Name
    setShowUpdate(true)
    setTagName(name)
    setTitle('Update tag')
    setTagId(id)
    setForceUpdate(!forceUpdate)
  }

  const handleDelete = (id) => {
    dispatch(deleteTag(id))
  }

  const handleConfirm = () => {
    const validation = validateName['Name'].validate(tagName).error

    if (showCreate) {
      if (validation) {
        setTagNameError(true)
      } else {
        if (tagBy === 'Default') {
          dispatch(createCompanyTag(company, tagName))
        } else {
          dispatch(createTeamTag(teamId, tagName))
        }
      }
    } else {
      if (validation) {
        setTagNameError(true)
      } else {
        dispatch(updateTag(tagId, tagName))
      }
    }
  }

  return (
    <section className={styles.tags}>
      <div>
        <Modal open={showCreate || showUpdate} onClose={handleClose}>
          <ModalInput
            id={'Name'}
            title={title}
            handleOnChange={handleOnChange}
            handleOnBlur={handleOnBlur}
            placeholder={'Tag name'}
            value={tagName}
            error={tagNameError}
            handleCancel={handleClose}
            handleConfirm={handleConfirm}
            isLoadingCreate={
              isLoadingCreateCompanyTag || isLoadingCreateTeamTag
            }
            isLoadingUpdate={isLoadingUpdateTag}
          />
        </Modal>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Box>
            <Input
              disableUnderline
              className={styles.input}
              placeholder="Search"
              onChange={handleChangeSearch}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch()
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <img
                      src={`${globals.logosBasePath}search.svg`}
                      alt="search"
                      width={'12px'}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <div className={styles.wrapperButtons}>
            <Tooltip title="Please select a company to show tags">
              <img src={`${globals.logosBasePath}help.svg`} alt="help" />
            </Tooltip>
          </div>
        </div>
        <div className={styles.cardsWrapper}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {teamsTagsData.map((ele, indx) => (
              <Grid item xs={2} sm={4} md={4} key={indx}>
                <div className={styles.card}>
                  <div
                    className={styles.number}
                  >{`${ele.tagList.length} Tags`}</div>
                  <div className={styles.wrapperName}>
                    <div style={{ display: "flex", flexDirection: "row" , gap: "5px", alignItems: "center" }}>
                      <div
                        className={styles.name}
                        style={{ background: ele.teamColor }}
                      >
                        {ele.teamName}
                      </div>
                      {ele.isPrivateTeam && (
                        <img
                          src={`${globals.logosBasePath}private-icon-mua.svg`}
                          alt="private"
                          width={"20px"}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <Button
                      variant="text"
                      className={styles.newMemberButton}
                      onClick={() => {
                        handleCreate(ele.teamName)
                      }}
                    >
                      <p>+Add New Tag</p>
                    </Button>
                  </div>
                  <div className={styles.wrapperTags}>
                    {ele.tagList.map((elem) => (
                      <div className={styles.tagWrapper}>
                        <div className={styles.tagName}>{`${elem.Name}`}</div>
                        {elem.Name !== 'Personal' && elem.Name !== 'Work' && (
                          <div className={styles.buttons}>
                            <IconButton
                              onClick={() => handleUpdate(elem.Id, ele.id)}
                              sx={{
                                padding: '0px 0px',
                                width: '24px',
                                height: '24px',
                              }}
                            >
                              <img
                                src={`${globals.logosBasePath}edit.svg`}
                                alt="edit"
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(elem.Id)}
                              sx={{
                                padding: '0px 0px',
                                width: '24px',
                                height: '24px',
                              }}
                            >
                              <img
                                src={`${globals.logosBasePath}delete.svg`}
                                alt="delete"
                              />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </section>
  )
}
