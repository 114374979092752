import { Outlet } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAuth } from './core/hooks/useAuth'
import { Layout } from './shared/components/Layout/Layout'

import styles from './App.module.scss'
import { getUserInfo } from './shared/constants/utilities'

const App = () => {
  const { isAuth } = useAuth()

  const { common } = useSelector((state) => state)
  const { user } = common

  useEffect(() => {
    if (isAuth && user) {
      const { FirstName, LastName, Email } = user
      const intercom = getUserInfo()?.intercom

      window.Intercom &&
        window.Intercom('boot', {
          app_id: 'plad75cp',
          email: Email,
          name: `${FirstName} ${LastName}`,
          user_hash: intercom,
        })
    } else {
      window.Intercom && window.Intercom('shutdown')
    }
  }, [isAuth, user])

  return (
    <section className={styles.App}>
      {isAuth ? (
        <Layout>
          <main className={styles.authMain}>
            <Outlet />
          </main>
        </Layout>
      ) : (
        <main className={styles.main}>
          <Outlet />
        </main>
      )}
    </section>
  )
}

export { App }
