import { createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosClient } from '../../services/axiosInstance'

export const slice = createSlice({
  name: 'tags',
  initialState: {
    isLoadingCreateCompanyTag: false,
    isSuccessCreateCompanyTag: false,
    isFailLCreateCompanyTag: false,
    isLoadingCreateTeamTag: false,
    isSuccessCreateTeamTag: false,
    isFailLCreateTeamTag: false,
    isLoadingUpdateTag: false,
    isSuccessUpdateTag: false,
    isFailLUpdateTag: false,
    isLoadingDeleteTag: false,
    isSuccessDeleteTag: false,
    isFailLDeleteTag: false,
  },
  reducers: {
    createCompanyTagRequest: (state) => {
      return {
        ...state,
        isLoadingCreateCompanyTag: true,
        isSuccessCreateCompanyTag: false,
        isFailCreateCompanyTag: false,
      }
    },
    createCompanyTagSuccess: (state) => {
      return {
        ...state,
        isLoadingCreateCompanyTag: false,
        isSuccessCreateCompanyTag: true,
        isFailCreateCompanyTag: false,
      }
    },
    createCompanyTagFail: (state) => {
      return {
        ...state,
        isLoadingCreateCompanyTag: false,
        isSuccessCreateCompanyTag: false,
        isFailCreateCompanyTag: true,
      }
    },
    createTeamTagRequest: (state) => {
      return {
        ...state,
        isLoadingCreateTeamTag: true,
        isSuccessCreateTeamTag: false,
        isFailCreateTeamTag: false,
      }
    },
    createTeamTagSuccess: (state) => {
      return {
        ...state,
        isLoadingCreateTeamTag: false,
        isSuccessCreateTeamTag: true,
        isFailCreateTeamTag: false,
      }
    },
    createTeamTagFail: (state) => {
      return {
        ...state,
        isLoadingCreateTeamTag: false,
        isSuccessCreateTeamTag: false,
        isFailCreateTeamTag: true,
      }
    },
    updateTagRequest: (state) => {
      return {
        ...state,
        isLoadingUpdateTag: true,
        isSuccessUpdateTag: false,
        isFailUpdateTag: false,
      }
    },
    updateTagSuccess: (state) => {
      return {
        ...state,
        isLoadingUpdateTag: false,
        isSuccessUpdateTag: true,
        isFailUpdateTag: false,
      }
    },
    updatwTagFail: (state) => {
      return {
        ...state,
        isLoadingUpdateTag: false,
        isSuccessUpdateTag: false,
        isFailUpdateTag: true,
      }
    },
    deleteTagRequest: (state) => {
      return {
        ...state,
        isLoadingDeleteTag: true,
        isSuccessDeleteTag: false,
        isFailDeleteTag: false,
      }
    },
    deleteTagSuccess: (state) => {
      return {
        ...state,
        isLoadingDeleteTag: false,
        isSuccessDeleteTag: true,
        isFailDeleteTag: false,
      }
    },
    deleteTagFail: (state) => {
      return {
        ...state,
        isLoadingDeleteTag: false,
        isSuccessDeleteTag: false,
        isFailDeleteTag: true,
      }
    },
  },
})

export default slice.reducer

export const {
  createCompanyTagRequest,
  createCompanyTagSuccess,
  createCompanyTagFail,
  createTeamTagRequest,
  createTeamTagSuccess,
  createTeamTagFail,
  updateTagRequest,
  updateTagSuccess,
  updateTagFail,
  deleteTagRequest,
  deleteTagSuccess,
  deleteTagFail,
} = slice.actions

export const createCompanyTag = (companyId, tagName) => async (dispatch) => {
  const toastLoading = toast.loading('Loading..')
  dispatch(createCompanyTagRequest())
  try {
    await axiosClient.post(`/Tag/createByCompany/${companyId}`, { Name: tagName })
    dispatch(createCompanyTagSuccess())
    toast.dismiss(toastLoading)
    toast.success('Successful tag creation')
  } catch {
    dispatch(createCompanyTagFail())
    toast.dismiss(toastLoading)
    toast.error('Failed tag creation')
  }
}

export const createTeamTag = (teamId, tagName) => async (dispatch) => {
  const toastLoading = toast.loading('Loading..')
  dispatch(createTeamTagRequest())
  try {
    await axiosClient.post(`/Tag/createByTeam/${teamId}`, { Name: tagName })
    dispatch(createTeamTagSuccess())
    toast.dismiss(toastLoading)
    toast.success('Successful tag creation')
  } catch {
    dispatch(createTeamTagFail())
    toast.dismiss(toastLoading)
    toast.error('Failed tag creation')
  }
}

export const updateTag = (tagId, tagName) => async (dispatch) => {
  const toastLoading = toast.loading('Loading..')
  dispatch(updateTagRequest())
  try {
    await axiosClient.post(`/Tag/Update/${tagId}`, { Name: tagName })
    dispatch(updateTagSuccess())
    toast.dismiss(toastLoading)
    toast.success('Successful tag update')
  } catch {
    dispatch(updateTagFail())
    toast.dismiss(toastLoading)
    toast.error('Failed tag update')
  }
}

export const deleteTag = (tagId) => async (dispatch) => {
  const toastLoading = toast.loading('Loading..')
  dispatch(deleteTagRequest())
  try {
    await axiosClient.delete(`/Tag/removeTag/${tagId}`)
    dispatch(deleteTagSuccess())
    toast.dismiss(toastLoading)
    toast.success('Successful tag delete')
  } catch {
    dispatch(deleteTagFail())
    toast.dismiss(toastLoading)
    toast.error('Failed tag delete')
  }
}
