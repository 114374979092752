import decode from 'jwt-decode'
import moment from 'moment/moment'

export const getUserInfo = () => {
  const user = localStorage.getItem('user')
    ? decode(localStorage.getItem('user'))
    : {
        isAdministrator: false,
        isEmployee: false,
        isLead: false,
        isOwner: false,
      }

  return user
}

export const convertToObjTagsByID = (tags) => {
  const tagsById = {}
  if (tags.length > 0) {
    tags.map((ele) => ele.TagList.map((tg) => (tagsById[tg.Id] = tg.Name)))
  }
  return tagsById
}

export const formatHour = (value) => {
  const d = new Date(value)
  const offset = moment().utcOffset()
  const t = moment.utc(d).add(offset, 'm').local().format('hh:mm A')

  return t
}

export const formatTime = (value) => {
  const d = new Date(value)
  const offset = moment().utcOffset()
  const t = moment.utc(d).add(offset, 'm').local().format('HH:mm')

  return t
}

export const formatDateTimeZone = (value) => {
  const d = new Date(value)
  const offset = moment().utcOffset()
  const t = moment.utc(d).add(offset, 'm').local().format('YY-MM-DD hh:mm A')

  return t
}

export const formatDateTimeZoneWithoutTime = (value) => {
  const t = moment(value).format('YYYY-MM-DD')

  return t
}

export const getDataDays = () => {
  let dataWeek = []
  for (let index = 1; index < 8; index++) {
    const element = { day: moment().subtract(index, 'days').format('ddd') }
    dataWeek.push(element)
  }
  return dataWeek.reverse()
}

export const getDataMonths = () => {
  let dataMonth = []
  for (let index = 0; index < 6; index++) {
    const element = { month: moment().subtract(index, 'months').format('MMMM') }
    dataMonth.push(element)
  }
  return dataMonth.reverse()
}
